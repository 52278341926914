import ExamCursus from "../views/schooladmin/ExamCursus";
import ExamCursusAddEdit from "../views/schooladmin/ExamCursusAddEdit";

export default [{
    path: "/schooladmin/examcursus",
    components: {
      content: ExamCursus
    }
  },
  {
    path: "/schooladmin/examcursus/new",
    components: {
      content: ExamCursusAddEdit
    }
  },
  {
    path: "/schooladmin/examcursus/update/:id",
    components: {
      content: ExamCursusAddEdit
    },
    props: {
      content: true
    }
  }
];