import EmployeeAbsent from "../views/schooladmin/EmployeeAbsent";
import EmployeeAbsentAddEdit from "../views/schooladmin/EmployeeAbsentAddEdit";

export default [{
    path: "/schooladmin/employee_absent",
    components: {
      content: EmployeeAbsent
    }
  },
  {
    path: "/schooladmin/employee_absent/new",
    components: {
      content: EmployeeAbsentAddEdit
    }
  },

  {
    path: "/schooladmin/employee_absent/update/:id",
    components: {
      content: EmployeeAbsentAddEdit
    },
    props: {
      content: true
    }
  }
];