import EmployeeCohort from "../views/schooladmin/EmployeeCohort";
import EmployeeCohortAddEdit from "../views/schooladmin/EmployeeCohortAddEdit";

export default [{
    path: "/schooladmin/employee_cohort",
    components: {
      content: EmployeeCohort
    }
  },
  {
    path: "/schooladmin/employee_cohort/new",
    components: {
      content: EmployeeCohortAddEdit
    }
  },
  {
    path: "/schooladmin/employee_cohort/update/:id",
    components: {
      content: EmployeeCohortAddEdit
    },
    props: {
      content: true
    }
  }
];