import TeacherSubjectClass from "../views/schooladmin/TeacherSubjectClass";
import TeacherSubjectClassAddEdit from "../views/schooladmin/TeacherSubjectClassAddEdit";

export default [{
    path: "/schooladmin/teachersubjectclass",
    components: {
      content: TeacherSubjectClass
    }
  },
  {
    path: "/schooladmin/teachersubjectclass/new",
    components: {
      content: TeacherSubjectClassAddEdit
    }
  },
  {
    path: "/schooladmin/teachersubjectclass/update/:id",
    components: {
      content: TeacherSubjectClassAddEdit
    },
    props: {
      content: true
    }
  }
];