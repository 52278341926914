<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Student Class</span>
				<span class="pl-2" v-else>Add Student Class</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >School info</p>
						<v-select
							dense
							:items="schoolyears"
							item-text="name"
							item-value="id"
							v-model="form.schoolyear_id"
							filled
							label="Schoolyear"
						></v-select>
					</v-col>
					
						<v-col cols="12" sm="6">
							<p class="font-weight-black title" >Student</p>
						<v-select
							dense
							:items="employeess"
							item-text="display_name"
							item-value="id"
							v-model="form.student_id"
							filled
							label="Student"
						></v-select>
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >Class</p>
						<v-select
							dense
							:items="mutation_types"
							item-text="name"
							item-value="id"
							v-model="form.class_id"
							filled
							label="Class"
						></v-select>
					</v-col> 
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "mutationedit",
	props: ["id"],
	data() {
		return {
			user: localStorage.getItem("user"),
			snackbar: false,
			schoolyears:[],
			employeess:[],
			mutation_types:[],
			mutation_months: [],
			mutation_starts:[],
			users:[],
			form: {
				id: 0,
				schoolyear_id: null,
				student_id: null,
				class_id: null
			},
			form2:{
				placement: 0
			},
		};
	},
	created: function() {
		this.getUsers();
		this.getSchoolyears();
		this.getMutation_type();
		if (this.id > 0) {
			this.getMutation(this.id);
			this.getEmployeesAll();
		}else{
this.getEmployees();
		}
	},
	watch: {

	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		getSchoolyears: function() {
			var self = this;

			ax.get("/schoolyear/all").then(function(response) {
				console.log(response.data);
				self.schoolyears = response.data;
			});
		},
		getEmployees: function() {
			var self = this;

			ax.get("/student/placement").then(function(response) {
				console.log(response.data);
				self.employeess = response.data;
			});
		},
		getEmployeesAll: function() {
			var self = this;

			ax.get("/student/all").then(function(response) {
				console.log(response.data);
				self.employeess = response.data;
			});
		},
		getMutation_type: function() {
			var self = this;

			ax.get("/school_class/all").then(function(response) {
				console.log(response.data);
				self.mutation_types = response.data;
			});
		},
		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				console.log(response.data);
				self.users = response.data;
			});
		},
		getMutation: function(id) {
			var self = this;

			this.loading = true;
			ax.get("/student_class/single/" + id).then(function(response) {
				// console.log(response.data);
				self.form = response.data;
				console.log("Student Class fetched...");
				self.loading = false;
			});
		},
		submitForm() {
			var self = this;

			//this.$nextTick(function() {

			ax.post("/student/update/"+this.form.student_id, this.form2).then(function(
					response
				) {
					console.log(response.data);

					console.log("Plaatsing Update");

				});
			console.log(this.form);
			if (this.form.id > 0) {
				ax.post("/student_class/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Mutations update...");

					self.$router.push({
						path: "/schooladmin/student_class"
					});
				});
			} else {
				ax.post("/student_class/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/student_class"
					});
					console.log("Mutations created...");
				});
			}
			//});
		}
	}
};
</script>
