import TeacherSubjectClassCursus from "../views/schooladmin/TeacherSubjectClassCursus";
import TeacherSubjectClassCursusAddEdit from "../views/schooladmin/TeacherSubjectClassCursusAddEdit";

export default [{
    path: "/schooladmin/teachersubjectclasscursus",
    components: {
      content: TeacherSubjectClassCursus
    }
  },
  {
    path: "/schooladmin/teachersubjectclasscursus/new",
    components: {
      content: TeacherSubjectClassCursusAddEdit
    }
  },
  {
    path: "/schooladmin/teachersubjectclasscursus/update/:id",
    components: {
      content: TeacherSubjectClassCursusAddEdit
    },
    props: {
      content: true
    }
  }
];