import Study from "../views/schooladmin/Study";
import StudyAddEdit from "../views/schooladmin/StudyAddEdit";

export default [{
    path: "/schooladmin/study",
    components: {
      content: Study
    }
  },
  {
    path: "/schooladmin/study/new",
    components: {
      content: StudyAddEdit
    }
  },
  {
    path: "/schooladmin/study/update/:id",
    components: {
      content: StudyAddEdit
    },
    props: {
      content: true
    }
  }
];