<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Student Intake</span>
				<span class="pl-2" v-else>Inschrijfformulier OSBOD 2022-2023</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-stepper v-model="e1">
			<v-stepper-header>
				<v-stepper-step :complete="e1 > 1" step="1">
					<p class="font-weight-black title">Opleiding</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 2" step="2">
					<p class="font-weight-black title">Student Informatie</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 3" step="3">
					<p class="font-weight-black title">Vooropleiding(en):</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 4" step="4">
					<p class="font-weight-black title">Contactpersoon</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 5" step="5">
					<p class="font-weight-black title">Intake</p>
				</v-stepper-step>
			</v-stepper-header>
			<v-form lazy-validation>
				<v-stepper-items>
					<v-stepper-content step="1">
						<v-card>
							<v-row>
								<v-col cols="12" sm="6">
						<p class="font-weight-black title">Intake info</p>
						<v-select dense :items="registered_statuss" item-text="name" item-value="id"
										v-model="form.registered_status_id" filled label="Status*" 
										></v-select>
						<v-menu ref="menu" v-model="menu" :close-on-content-click="false"
							:return-value.sync="form.appointment_date" transition="scale-transition" offset-y
							min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.appointment_date" label="Intake date"
									prepend-inner-icon="mdi-calendar" readonly v-on="on" filled></v-text-field>
							</template>
							<v-date-picker v-model="form.appointment_date" no-title scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.menu.save(form.appointment_date)">OK</v-btn>
							</v-date-picker>
						</v-menu>

						<v-menu ref="menu_appointment_time" v-model="menu_appointment_time"
							:close-on-content-click="false" :nudge-right="40" :return-value.sync="form.appointment_time"
							transition="scale-transition" offset-y max-width="290px" min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.appointment_time" label="Appointment Time"
									prepend-inner-icon="mdi-clock" readonly filled v-on="on"></v-text-field>
							</template>
							<v-time-picker v-if="menu_appointment_time" v-model="form.appointment_time" full-width
								@click:minute="$refs.menu_appointment_time.save(form.appointment_time)"></v-time-picker>
						</v-menu>
						<v-textarea dense v-model="form.comments" label="Opmerkingen" filled>
									</v-textarea>

					</v-col>
							<v-col cols="12" sm="6">
								
								<p class="font-weight-bold">
									
								<v-radio-group v-model="form.old_student" label="Kies uit de volgende 2 opties:*" required :rules="[rules.required]"
										:error-messages="errorMessages" >
								<v-radio
									label="Ik ben een oude student van OSBOD"
									value="Oude student"
								></v-radio>
								<v-radio
									label="Ik schrijf mij in voor de eerste keer bij OSBOD"
									value="Nieuwe student"
								></v-radio>
								</v-radio-group>
								</p>
								<p class="font-weight-bold">Ik wens mij aan te melden voor de opleiding:</p>
									<v-select dense :items="sectors" item-text="name" item-value="id" 
										v-model="form.sector_id" filled label="Sector*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>

									<v-select dense :items="levels" item-text="tbl_sys_level.name" item-value="tbl_sys_level.id" 
										v-model="form.level_id" filled label="Niveau*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>
									
									<v-select dense :items="studys" item-text="name" item-value="id" 
										v-model="form.study_id" filled label="Opleiding*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>

									<v-text-field :items="selectedstudycost" item-text="registration_cost" item-dense v-model="form.registration_cost"
										item-value="registration_cost" label="Inschrijf kosten" filled readonly>
									</v-text-field>
									<v-text-field :items="selectedstudycost" dense v-model="form.study_cost" item-text="study_cost" item-value="study_cost"  label="Opleidingskosten" filled
										readonly>
									</v-text-field>
									<!-- <v-text-field dense v-model="form.material_cost" label="Lesmateriaal kosten" filled
										readonly>
									</v-text-field> -->
							</v-col>
						
						</v-row>
						</v-card>
						<v-btn color="success" @click="submitForm(id)" depressed>Opslaan</v-btn>
						<v-btn color="primary" @click="submitFormTransitionStudie()" depressed>
							Volgende
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Annuleren</v-btn>
					</v-stepper-content>
					<v-stepper-content step="2">
						<v-card-text>
							<v-row>
								<v-col cols="12" sm="6">
									<v-text-field dense v-model="form.maidenname" label="Gehuwde naam" filled>
									</v-text-field>
									<v-text-field dense v-model="form.surname" label="Achternaam*"  hint="Indien getrouwd, meisjesnaam invullen" filled
										:rules="[rules.required]" :error-messages="errorMessages" required
										>
									</v-text-field>
									<v-text-field dense v-model="form.firstname" label="Voornaam (voluit)*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.middlename" label="Middelnaam (voluit)" filled
										>
									</v-text-field>
									<v-select dense :items="genders" item-text="name" item-value="id"
										v-model="form.gender_id" filled label="Geslacht*" :rules="[rules.required]"
										:error-messages="errorMessages" required></v-select>
									<v-menu ref="menu_birthdate" v-model="menu_birthdate"
										:close-on-content-click="false" :return-value.sync="form.birthdate"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.birthdate" label="Geboortedatum*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.birthdate" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_birthdate = false">Annuleren</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_birthdate.save(form.birthdate)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
									<v-select dense :items="birthplaces" item-text="name" item-value="id"
										v-model="form.birthplace_id" filled label="Geboorteplaats*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-select dense :items="nationalitys" item-text="name" item-value="id"
										v-model="form.nationality_id" filled label="Nationaliteit*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>


								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field dense v-model="form.government_id" label="Geldige I.D. Nr.*" filled
										:counter=10 :rules="[rules.required, rules.governmentid, rules.number_only]"
										:error-messages="errorMessages" required>
									</v-text-field>
									<v-menu ref="menu_govid_exp_date" v-model="menu_govid_exp_date"
										:close-on-content-click="false" :return-value.sync="form.govid_exp_date"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.govid_exp_date" label="ID vervaldatum*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.govid_exp_date" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_govid_exp_date = false">Annuleren</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_govid_exp_date.save(form.govid_exp_date)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
									<v-select dense :items="maritial_statuss" item-text="name" item-value="id"
										v-model="form.maritial_status_id" filled label="Burgerlijke staat*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-select dense :items="denominations" item-text="name" item-value="id"
										v-model="form.denomination_id" filled label="Geloofsovertuiging*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-text-field dense v-model="form.address" label="Adres*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.neighboorhood" label="Wijk*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.email" label="Email*" filled
										:rules="[rules.required, rules.email]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-row>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_mobile"
												prepend-inner-icon="mdi-cellphone" label="Mobiel*" filled :counter=7
												:rules="[rules.required, rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages" required>
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_home"
												prepend-inner-icon="mdi-phone-classic" label="Thuis" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages">
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_work"
												prepend-inner-icon="mdi-deskphone" label="Werk" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages"></v-text-field>
										</v-col>
									</v-row>



								</v-col>
							</v-row>
						</v-card-text>

						<v-btn color="secondary" @click="e1=e1-1">Terug</v-btn>
						<v-btn color="primary" @click="submitFormTransition()" depressed>
							Volgende
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Annuleren</v-btn>

						
					</v-stepper-content>
					<v-stepper-content step="3">
						<v-card>
							<v-col cols="12" sm="6">
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_fo" label="FO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_fo_level" label="t/m groep:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_fo_year" label="in het jaar:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_vo" label="VO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vo_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vo_year" label="tot en met jaar:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_vsbo" label="VSBO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vsbo_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vsbo_year" label="tot en met jaar:"
											filled>
										</v-text-field>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_sbo" label="SBO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_sbo_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_sbo_year" label="tot en met jaar:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_other" label="Andere"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_other_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_other_year" label="tot en met jaar:"
											filled>
										</v-text-field>
									</v-col>
								</v-row>
							</v-col>
						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Terug</v-btn>
						<v-btn color="primary" @click="e1=e1+1" depressed>
							Volgende
						</v-btn>
						<v-btn color="error" @click="$router.go(-1);">Annuleren</v-btn>
					</v-stepper-content>

					<v-stepper-content step="4">
						<v-card>
							
							<v-col cols="12" sm="4">
								<v-text-field dense v-model="form.emergency_contact_1" label="Noodcontact 1*" filled
								:rules="[rules.required]" :error-messages="errorMessages" required>
								</v-text-field>
								<v-text-field dense v-model="form.emergency_phone_1" label="Contact nummer 1*" prepend-inner-icon="mdi-cellphone" filled :counter=7
												:rules="[rules.required, rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages" required>
								</v-text-field>
								
							
								<v-text-field dense v-model="form.emergency_contact_2" label="Noodcontact 2" filled>
								</v-text-field>
								<v-text-field dense v-model="form.emergency_phone_2" label="Contact nummer 2" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages">
								</v-text-field>
								
							
							</v-col>
							
						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Terug</v-btn>
						<v-btn color="primary" @click="submitFormTransitionEmergencyContact()" depressed>
							Volgende
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Annuleren</v-btn>
					</v-stepper-content>
					<v-stepper-content step="5">

						<!-- <v-card>
							<v-row>
								<v-col cols="12" sm="12">
									<p class="font-weight-black title">Formulario di atmishon</p>
									
								
									<p class="font-weight-bold">1. Inschrijven voor een opleiding</p>
									<p class="font-weight-regular">U verklaart met het plaatsen van uw handtekening op het inschrijfformulier akkoord te gaan met de inschrijf- en betalingsvoorwaarden. Hou er rekening mee dat u voor elk schooljaar opnieuw moet inschrijven.</p>
									<p class="font-weight-bold">2. Wijze van betaling</p>
									<p class="font-weight-regular">Het inschrijfgeld en boekengeld dient gestort te worden op de bankrekening van OSBOD met vermelding van
										<p class="font-weight-regular">- naam student; 
										<p class="font-weight-regular">- klas; 
										<p class="font-weight-regular">- inschrijfgeld of boekengeld. 
										<p class="font-weight-regular">Het schoolgeld dient gestort te worden op bankrekening van overheid met vermelding van: 
										<p class="font-weight-regular">- naam student;
										<p class="font-weight-regular">- klas; 
										<p class="font-weight-regular">- schoolgeld. 
										<p class="font-weight-bold">Contant geld wordt NIET geaccepteerd. 
										<p class="font-weight-regular">Alle stortingsbewijzen moeten gemaild worden naar de administratie van uw sector. 
										Uw origineel bewijs moet u altijd goed bewaren. Volledige betaling moet worden voldaan vóór afloop van het schooljaar. 
										Indien dit niet het geval is, kunt u helaas uw cijferlijst NIET in ontvangst nemen. 
										<p class="font-weight-regular">Indien u in het examenjaar uw financiële verplichtingen niet heeft voldaan, kunt u helaas: 
										<p class="font-weight-regular">- uw diploma en cijferlijst NIET ontvangen; 
										<p class="font-weight-regular">- Niet deelnemen aan de praktijkexamens van een opleiding waar een praktijkexamen deel van uitmaakt met als gevolg dat u de opleiding ook niet kunt afronden.
									</p>
									<p class="font-weight-bold">3. Afmelding</p>
									<p class="font-weight-regular">Na 2 maanden afwezig te zijn geweest van de schoolactiviteiten zonder een geldige berichtgeving, wordt u automatisch uitgeschreven. 
										Dientengevolge dat u zich opnieuw moet inschrijven voor het volgend schooljaar met dien verstande dat het inschrijfgeld en schoolgeld moeten worden betaald.</p>
									<p class="font-weight-bold">Alle betaalde bedragen kunnen niet meer opgeëisd worden.
									</p>
									<p class="font-weight-bold">4. Lesmateriaal</p>
									<p class="font-weight-regular">De nodige lesmaterialen zult u ontvangen bij de start
										van
										de opleiding indien de financiële verplichting is voldaan.</p>
									<p class="font-weight-bold">5. Deelname examens</p>
									<p class="font-weight-regular">Alle belangrijke data inclusief de toets- en herkansingsdata zijn terug te vinden in uw jaarplanning. 
										Alle examenregelingen zijn in overleg met Inspectie van Onderwijs samengesteld.</p>
									<p class="font-weight-bold">6. Wijziging opleiding</p>
									<p class="font-weight-regular">OSBOD behoudt het recht om te allen tijde wijzigingen aan te brengen in het rooster, docent(en), de locatie en tijd. 
										Bij onvoldoende inschrijvingen kan een opleiding afgelast worden. Studenten die ingeschreven staan zullen tijdig schriftelijk bericht ontvangen en vervallen hun financiële verplichtingen. 
										Aan hen worden geen kosten in rekening gebracht en vindt eventueel restitutie van het inschrijfgeld plaats.</p>
									<v-checkbox v-model="form.registration_complete"
										label="U verklaart alle inschrijvingsinformatie naar waarheid en volledig te hebben ingevuld*"
										required :rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
									<v-checkbox v-model="form.registration_agree" required
										label="U verklaart akkoord te gaan met de inschrijf en betalingsvoorwaarden.*"
										:rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
								</v-col>
							</v-row>
						</v-card> -->
						<v-card>
							<v-row>
								<v-col cols="12" sm="6">
						<p class="font-weight-black title">Formulario di atmishon</p>
						<p class="font-weight-bold">Salú i limitashonnan (Informashon médiko)</p>

						<v-radio-group v-model="form.health_important" label="Tin algu relashoná ku bo salú ku skol mester ta na altura di dje? /br'(limitashon físiko òf keho, malesa, remedi, alergia, etc)">
								<v-radio
									label="Si"
									value="Si,"
								></v-radio>
								<v-radio
									label="No"
									value="no"
								></v-radio>
								</v-radio-group>
								<v-textarea dense v-model="form.health_important_comment" label="Si esei ta e kaso, kua?" filled>
									</v-textarea>
									
						<v-radio-group v-model="form.health_psychology" label="Bo a yega di hasi un tèst sikológiko?">
								<v-radio
									label="Si"
									value="Si,"
								></v-radio>
								<v-radio
									label="No"
									value="no"
								></v-radio>
								</v-radio-group>
								<v-textarea dense v-model="form.health_psychology_comment" label="Si esei ta e kaso, kua?" filled>
									</v-textarea>

						<v-radio-group v-model="form.health_other" label="Tin sirkunstansha ku skol tin ku tene kuenta kuné? ?">
								<v-radio
									label="Si"
									value="Si,"
								></v-radio>
								<v-radio
									label="No"
									value="no"
								></v-radio>
								</v-radio-group>
								<v-textarea dense v-model="form.health_other_comment" label="Si esei ta e kaso, kua?" filled>
									</v-textarea>
						
					
								
								<p class="font-weight-bold">Motivashon (estudio)</p>
								<v-text-field dense v-model="form.motivation_study" label="Kua estudio bo a sigui ultimo?" filled>
									</v-text-field>
									<v-text-field dense v-model="form.motivation_sector" label="Kua sektor òf profil bo tabatin " filled>
									</v-text-field>

						<v-radio-group v-model="form.motivation_study_final" label="Bo a tèrminá e estudio ku un diploma">
								<v-radio
									label="Si"
									value="Si,"
								></v-radio>
								<v-radio
									label="No"
									value="no"
								></v-radio>
								</v-radio-group>
								<v-textarea dense v-model="form.motivation_study_final_comment" label="Si esei no ta e kaso, dikon?" filled>
									</v-textarea>
						
									<v-textarea dense v-model="form.motivation_subject" label="Kua ta e materianan ku bo tabatin difikultat kuné na skol i dikon? " filled>
									</v-textarea>
									
						<v-radio-group v-model="form.motivation_other_study" label="Bo a logra otro diploma òf sertifikado?">
								<v-radio
									label="Si"
									value="Si,"
								></v-radio>
								<v-radio
									label="No"
									value="no"
								></v-radio>
								</v-radio-group>
								<v-textarea dense v-model="form.motivation_other_study_comment" label="Si esei ta e kaso, kua?" filled>
									</v-textarea>

									<v-textarea dense v-model="form.motivation_study_choice" label="Dikon bo ke sigui e estudio ku bo a skohe?" filled>
									</v-textarea>

									<v-textarea dense v-model="form.motivation_study_like" label="Kiko ta parse bo agradabel di e estudio ku bo a skohe i dikon?" filled>
									</v-textarea>

									<v-textarea dense v-model="form.motivation_study_dislike" label="Kiko ta parse bo ménos agradabel di e estudio ku bo a skohe i dikon?" filled>
									</v-textarea>

									<v-textarea dense v-model="form.motivation_characteristic" label="Kua karakterístika (personal) bo tin ku ta kuadra ku e abilidat di trabou ku lo bo desaroyá pa ku e estudio ku bo a skohe? " filled>
									</v-textarea>

						<v-radio-group v-model="form.motivation_attention" label="Tin sirkunstansha ku skol tin ku tene kuenta kuné? ?">
								<v-radio
									label="Si"
									value="Si,"
								></v-radio>
								<v-radio
									label="No"
									value="no"
								></v-radio>
								</v-radio-group>
								<v-textarea dense v-model="form.motivation_attention_comment" label="Si esei ta e kaso, kua?" filled>
									</v-textarea>
							
								</v-col>
							<v-col cols="12" sm="6">
								<p class="font-weight-bold">Informashon adishonal</p>
							
								<v-textarea dense v-model="form.general_hobby" label="Kua ta bo hòbinan? " filled></v-textarea>
									
									<v-radio-group v-model="form.general_police" label="Bo a yega di tin kontakto ku polis/ hustisia?">
		<v-radio
			label="Si"
			value="Si,"
		></v-radio>
		<v-radio
			label="No"
			value="no"
		></v-radio>
		</v-radio-group>
		<v-textarea dense v-model="form.general_police_comment" label="Si esei ta e kaso, kua?" filled>
			</v-textarea>

			<v-radio-group v-model="form.general_schoolhours" label="Bo ta na altura ku orarionan di lès ta keda duná den oranan di anochi i tin biaha tambe den wikènt?">
		<v-radio
			label="Si"
			value="Si,"
		></v-radio>
		<v-radio
			label="No"
			value="no"
		></v-radio>
		</v-radio-group>

			<v-radio-group v-model="form.general_digital" label="Mirando ku OSBOD ta hasi uzo di medionan digital pa komuniká ku studiante (mail i Whatsapp), bo ta disponé di konekshon di Internet pa asina bo keda informá di partisipashonnan importante i informashon?">
		<v-radio
			label="Si"
			value="Si,"
		></v-radio>
		<v-radio
			label="No"
			value="no"
		></v-radio>
		</v-radio-group>
		<v-textarea dense v-model="form.general_digital_comment" label="Si esei no ta e kaso, kon lo bo bai solushoná esaki? " filled>
			</v-textarea>

			<p class="font-weight-bold">***Spesialmente pa partisipantenan ku ke sigui HW òf SPW:</p>
			<p class="font-weight-bold">Pa estudionan di e Sektor Kuido i Bienestar tin posibilidat ku den sierto entidat ta traha 24 ora pa dia pues ta traha ku warda. Esaki por nifiká ku lo bo tin ku traha orario iregular, por ehèmpel dianan di fiesta, wikènt i orarionan di anochi òf warda di anochi.</p>		
								
									
									<v-radio-group v-model="form.general_workhours" label="Bo ta na altura di esaki?">
		<v-radio
			label="Si"
			value="Si,"
		></v-radio>
		<v-radio
			label="No"
			value="no"
		></v-radio>
		</v-radio-group>
		<v-textarea dense v-model="form.general_workhours_comment" label="Kiko bo ta pensa di esaki?" filled>
			</v-textarea>

			<v-textarea dense v-model="form.general_comment" label="Remarke: " filled></v-textarea>

			<v-textarea dense v-model="form.general_observation_comment" label="Opservashon i rekomendashon di persona ku a hasi e atmishon" filled></v-textarea>
							</v-col>
						
						</v-row>
						</v-card>


						<v-card-actions>
							<v-btn color="secondary" @click="e1=e1-1">Terug</v-btn>
							<v-btn color="primary" @click="submitForm(id)" depressed>Opslaan</v-btn>
							<v-btn color="error" @click="$router.go(-1);" depressed>Annuleren</v-btn>
						</v-card-actions>
					</v-stepper-content>
				</v-stepper-items>
			</v-form>
		</v-stepper>



		<v-card-text>

			<v-row>


			</v-row>

		</v-card-text>
		<!-- <v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Opslaan</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Annuleren</v-btn>
		</v-card-actions> -->

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			U dient alle verplichte velden in te vullen! Druk op de terug knop om de verplichte velden alsnog in te
			vullen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "usersignupadd",
		props: ["id"],
		data() {
			return {
				cursector: 0,
				curlevel: 0,
				curstudy: 0,
				e1: 1,
				valid: true,
				errorMessages: '',
				formHasErrors: false,
				user: JSON.parse(localStorage.getItem("user")),
				snackbar: false,
				schoolyears: [],
				studys: [],
				users: [],
				registered_statuss:[],
				genders: [],
				birthplaces: [],
				nationalitys: [],
				maritial_statuss: [],
				denominations: [],
				sectors: [],
				levels: [],
				selectedstudycost: [],
				menu: false,
				menu_birthdate: false,
				menu_govid_exp_date: false,
				menu_appointment_time: false,
				menu_appointment_date: false,
				rules: {
					required: value => !!value || 'Dit veld is verplicht!.',
					email: value => {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						return pattern.test(value) || 'Onvolledige e-mail.'
					},
					governmentid: value => (value && value.length == 10) ||
						'Identiteitsnummer dient 10 nummers te zijn.',
					telephone_number: value => (value && value.length == 7) ||
						'Telefoonnummer dient 7 nummers te zijn.',
					number_only: value => {
						const pattern = /^[0-9]+$/
						return pattern.test(value) || 'Alleen nummers is toegestaan'
					}
				},
				form: {
					id: 0,
					role_id: 1,
					schoolyear_id: 3,
					registration_type_id:1,
					registered_status_id:null,
					comments: "",
					sector_id: null,
					old_student: null,
					level_id: null,
					study_id: null,
					firstname: "",
					surname: "",
					maidenname: "",
					government_id: "",
					govid_exp_date: "",
					birthdate: "",
					birthplace_id: null,
					gender_id: null,
					nationality_id: null,
					maritial_status_id: null,
					denomination_id: null,
					address: "",
					neighboorhood:"",
					email: "",
					tel_home: "",
					tel_mobile: "",
					tel_work: "",
					registration_agree: "",
					registration_complete: "",
					registration_cost: null,
					study_cost: null,
					material_cost: "",
					display_name: "",
					edu_fo: 0,
					edu_fo_level: null,
					edu_fo_year: null,
					edu_vo: 0,
					edu_vo_level: null,
					edu_vo_year: null,
					edu_vsbo: 0,
					edu_vsbo_level: null,
					edu_vsbo_year: null,
					edu_sbo: 0,
					edu_sbo_level: null,
					edu_sbo_year: null,
					edu_other: 0,
					edu_other_level: null,
					edu_other_year: null,
					emergency_contact_1: null,
					emergency_phone_1: null,
					emergency_contact_2: null,
					emergency_phone_2: null,
					appointment_date: "",
					appointment_time: "",
					deleted_by_id: null,
					health_important: null,
					health_important_comment: null,
					health_psychology: null,
					health_psychology_comment: null,
					health_other: null,
					health_other_comment: null,
					motivation_study: null,
					motivation_sector: null,
					motivation_study_final: null,
					motivation_study_final_comment: null,
					motivation_subject: null,
					motivation_other_study: null,
					motivation_other_study_comment: null,
					motivation_study_choice: null,
					motivation_study_like: null,
					motivation_study_dislike: null,
					motivation_characteristic:null,
					motivation_attention: null,
					motivation_attention_comment: null,
					general_hobby: null,
					general_police: null,
					general_police_comment: null,
					general_schoolhours: null,
					general_digital: null,
					general_digital_comment: null,
					general_workhours: null,
					general_workhours_comment: null,
					general_comment: null,
					general_observation_comment: null,
				}
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			//this.getStudys();
			this.getGenders();
			this.getBirthplaces();
			this.getNationalitys();
			this.getMaritialstatus();
			this.getDenomination();
			this.getRegistered_status();
			this.getSector();
			if (this.id > 0) {
				// this.e1 =  5;
				this.getUserRegistration(this.id);
			}
		},
		watch: {
			name() {
				this.errorMessages = '';
			},
			"form.sector_id": function(value){
				
				if (this.cursector != this.form.sector_id && this.cursector > 0){
					this.form.level_id = null;
					this.form.registration_cost = null;
					this.form.study_cost = null;
					this.form.study_id = null;
					
				}

				this.getLevel(value);
				this.cursector = this.form.sector_id
				// this.getClass1(value);	
			},
			"form.level_id": function(value){
				
				if (this.curlevel != this.form.level_id && this.curlevel > 0){

				this.form.registration_cost = null;
				this.form.study_cost = null;
				this.form.study_id = null;
			}console.log('first',this.curlevel);
				this.getStudy(value);
				this.curlevel = this.form.level_id
				console.log('After',this.curlevel);
				// this.getClass1(value);	
			},
			"form.study_id": function(value){
				
				if (this.curstudy != this.form.study_id && this.curstudy > 0){

				this.form.registration_cost = null;
				this.form.study_cost = null;
				this.getSelectedStudy(value);
				}
				this.curstudy = this.form.study_id
				this.getSelectedStudy(value);
				// this.getClass1(value);
				
			},
			// "form.study_id": function (value) {
			// 	console.log("Get selected cost");
			// 	//console.log(this.cost_center_id_obj);
			// 	console.log(value);

			// 	this.getSelectedStudy(value);

			// },
		},
		methods: {
			validate() {
				this.$refs.form.validate()
			},
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getBirthplaces: function () {
				var self = this;

				ax.get("/birthplace/all").then(function (response) {
					console.log(response.data);
					self.birthplaces = response.data;
				});
			},
			getNationalitys: function () {
				var self = this;

				ax.get("/nationality/all").then(function (response) {
					console.log(response.data);
					self.nationalitys = response.data;
				});
			},
			getMaritialstatus: function () {
				var self = this;

				ax.get("/maritial_status/all").then(function (response) {
					console.log(response.data);
					self.maritial_statuss = response.data;
				});
			},
			getDenomination: function () {
				var self = this;

				ax.get("/denomination/all").then(function (response) {
					console.log(response.data);
					self.denominations = response.data;
				});
			},
			getSector: function () {
				var self = this;

				ax.get("/sector/all").then(function (response) {
					console.log(response.data);
					
					
					self.sectors = response.data;
				});
			},
			getLevel: function() {
			var self = this;
			ax.get("/study/selected_sector/", {
				params: {
					sector_id: self.form.sector_id,
				}
			}).then(function(response) {
				
				self.levels = response.data;
				
				console.log("After Fill")
				
			});
			
		
		},
			getStudy: function () {
				var self = this;

				ax.get("/study/selected_sector_level_admin/", {
				params: {
					sector_id: self.form.sector_id,
					level_id: self.form.level_id,
				}
			}).then(function(response) {
				// self.form.registration_cost = null;
				// self.form.study_cost = null;
				self.studys = response.data;
				
				console.log("After Fill")
				
			});
			},
			getSelectedStudy: function () {
				var self = this;
				ax.get("/study/single/" + this.form.study_id).then(function (response) {
					console.log("getStudy Costs")
					self.selectedstudycost = response.data;
					self.form.registration_cost = response.data.registration_cost;
					self.form.study_cost = response.data.study_cost;
					console.log(response.data);
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getGenders: function () {
				var self = this;

				ax.get("/gender/all").then(function (response) {
					console.log(response.data);
					self.genders = response.data;
				});
			},
			getRegistered_status: function () {
				var self = this;

				ax.get("/registered_status/all").then(function (response) {
					console.log(response.data);
					self.registered_statuss = response.data;
				});
			},
			getUserRegistration: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/userregistration/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("UserRegistrations fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				this.formHasErrors = false;

			
				if (this.form.registration_agree == null || this.form.registration_agree == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_agree = null;
				}
				if (this.form.registration_complete == null || this.form.registration_complete == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_complete = null;
				}
				//this.$nextTick(function() {
				console.log(this.form);
				if (this.form.maidenname.length > 0) {
					this.form.display_name = this.form.firstname + " " + this.form.surname + " e/v " + this.form
						.maidenname;
				} else {
					this.form.display_name = this.form.firstname + " " + this.form.surname;
				}
				if (this.formHasErrors == false) {
					if (this.form.id > 0) {
						this.form.deleted_by_id = this.user.id;
						
						ax.post("/userregistration/update/" + this.form.id, this.form).then(function (
							response
						) {
							console.log(response.data);

							console.log("UserRegistrations update...");

							self.$router.push({
								path: "/schooladmin/studentregistrationintake"
							});
						});
					} else {
						console.log("before post");
						ax.post("/userregistration/create", this.form).then(function (response) {
							console.log("almost there");
							console.log(response.data);
							self.$router.push({
								path: "/schooladmin/studentregistrationintake"
							});
							console.log("UserRegistrations created...");
						});


					}
				} else {
					this.form
				}
				//});
			},
			submitFormTransition() {
				var self = this;
				this.formHasErrors = false;
				
				if (this.form.surname == null || this.form.surname == "") {
					this.formHasErrors = true;
					this.form.surname = null;
				}
				if (this.form.firstname == null || this.form.firstname == "") {
					this.formHasErrors = true;
					this.form.firstname = null;
				}
				if (this.form.birthdate == null || this.form.birthdate == "") {
					this.formHasErrors = true;
					this.form.birthdate = null;
				}
				if (this.form.govid_exp_date == null || this.form.govid_exp_date == "") {
					this.formHasErrors = true;
					this.form.govid_exp_date = null;
				}
				if (this.form.birthplace_id == null || this.form.birthplace_id == "") {
					this.formHasErrors = true;
					this.form.birthplace_id = "";
				}
				if (this.form.government_id == null || this.form.government_id == "" || this.form.government_id.length >10  ) {
					this.formHasErrors = true;
					this.form.government_id = null;
				}
				if (this.form.tel_mobile == null || this.form.tel_mobile == ""|| this.form.tel_mobile.length >7) {
					this.formHasErrors = true;
					this.form.tel_mobile = null;
				}
				if (this.form.email == null || this.form.email == "") {
					this.formHasErrors = true;
					this.form.email = null;
				}
				if (this.form.address == null || this.form.address == "") {
					this.formHasErrors = true;
					this.form.address = null;
				}
				if (this.form.neighboorhood == null || this.form.neighboorhood == "") {
					this.formHasErrors = true;
					this.form.neighboorhood = null;
				}
				if (this.form.gender_id == null || this.form.gender_id == "") {
					this.formHasErrors = true;
					this.form.gender_id = "";
				}
				if (this.form.denomination_id == null || this.form.denomination_id == "") {
					this.formHasErrors = true;
					this.form.denomination_id = "";
				}
				if (this.form.nationality_id == null || this.form.nationality_id == "") {
					this.formHasErrors = true;
					this.form.nationality_id = "";
				}
				if (this.form.maritial_status_id == null || this.form.maritial_status_id == "") {
					this.formHasErrors = true;
					this.form.maritial_status_id = "";
				}
				
				// if (this.form.study_id == null || this.form.study_id == "") {
				// 	this.formHasErrors = true;
				// 	this.form.study_id = "";
				// }
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			},
			submitFormTransitionStudie(){
				var self = this;
				this.formHasErrors = false;

					if (this.form.study_id == null || this.form.study_id == "") {
					this.formHasErrors = true;
					this.form.study_id = "";
				}
				if (this.form.old_student == null || this.form.old_student == "") {
					this.formHasErrors = true;
					this.form.old_student = "";
				}
					if (this.form.sector_id == null || this.form.sector_id == "") {
					this.formHasErrors = true;
					this.form.sector_id = "";
				}
					if (this.form.level_id == null || this.form.level_id == "") {
					this.formHasErrors = true;
					this.form.level_id = "";
				}
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			},
			submitFormTransitionEmergencyContact(){
				var self = this;
				this.formHasErrors = false;

					if (this.form.emergency_contact_1 == null || this.form.emergency_contact_1 == "") {
					this.formHasErrors = true;
					this.form.emergency_contact_1 = "";
				}
				if (this.form.emergency_phone_1 == null || this.form.emergency_phone_1 == "") {
					this.formHasErrors = true;
					this.form.emergency_phone_1 = "";
				}
				
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			}
		}
	};
</script>