<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Wijzig Niet gewerkte uren - Time Registratie Data - Administratie</span>
				<span class="pl-2" v-else>Nieuwe Niet gewerkte uren - Time Registratie - Administratie</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>

					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Registratie</p>
						<v-select dense :items="loa_ovt_types" item-text="name" item-value="id"
							v-model="form.loa_ovt_type_id" filled label="Registratie type"></v-select>
						<v-select dense :items="weeks" item-text="week_name" item-value="id"
							v-model="form.week_id" filled return-object label="Week"></v-select>
						<v-textarea filled name="input-7-4" label="Opmerking" v-model="form.description"></v-textarea>
						
						

					</v-col>

					<!-- <v-col cols="12" sm="6">
						
					</v-col> -->
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Datum en tijd</p>
						<v-select dense :items="calendars" item-text="fulldate" item-value="id"
							v-model="form.loa_ovt_start_date" filled label="Datum start"></v-select>
						<v-select dense :items="calendars" item-text="fulldate" item-value="id"
							v-model="form.loa_ovt_end_date" filled label="Datum eind"></v-select>
							<v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
							:return-value.sync="form.loa_ovt_start_time" transition="scale-transition" offset-y
							max-width="290px" min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.loa_ovt_start_time" label="Begin tijd" readonly v-on="on">
								</v-text-field>
							</template>
							<v-time-picker v-if="menu2" v-model="form.loa_ovt_start_time" full-width
								@click:minute="$refs.menu2.save(form.loa_ovt_start_time)"></v-time-picker>
						</v-menu>
						<v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
							:return-value.sync="form.loa_ovt_end_time" transition="scale-transition" offset-y
							max-width="290px" min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.loa_ovt_end_time" label="Eind tijd" readonly v-on="on">
								</v-text-field>
							</template>
							<v-time-picker v-if="menu3" v-model="form.loa_ovt_end_time" full-width
								@click:minute="$refs.menu3.save(form.loa_ovt_end_time)"></v-time-picker>
						</v-menu>
					</v-col>

				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "mutationedit",
		props: ["id"],
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user")),
				snackbar: false,
				weeks: [],
				admin_tasks: [],
				calendars: [],
				periodes: [],
				loa_ovt_types: [],
				tasks: [],
				employee_tasks: [],
				mutation_starts: [],
				users: [],
				menu1: false,
				menu2: false,
				menu3: false,
				hours: [],
				minutes: [],
				form: {
					id: 0,
					week_id: null,
					schoolyear_id: 4,
					loa_ovt_type_id: null,
					user_id: null,
					registration_date: Date(),
					loa_ovt_start_date: null,
					loa_ovt_end_date: null,
					loa_ovt_start_time: null,
					loa_ovt_end_time: null,
					description: null
				}
			};
		},
		created: function () {
			this.getUsers();
			this.getWeeks();
			this.getLoa_ovt();
			// this.getAdminloa_ovt_start();
			if (this.id > 0) {
				this.getAdminloa_ovt(this.id);
			}
		},
		watch: {
			
			"form.week_id": function (value) {
				
				this.getCalendar(value);
				
			}
		},
		methods: {
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getWeeks: function () {
				var self = this;

				ax.get("/week/all_loa_ovt").then(function (response) {
					console.log(response.data);
					self.weeks = response.data;
				});
			},
			getCalendar: function () {
				
				var self = this;
				if (this.id > 0) {
				console.log("My week fill")
				console.log(this.form.week_id);
				ax.get("/calendar/ngu_selected_week/", {
					params: {
						week_id: this.form.week_id
					}
				}).then(function (response) {
					console.log("Calendar Before Fill")
					console.log(self.calendars)
					self.calendars = response.data;
					console.log("Calendar After Fill")
					console.log(self.calendars)
				});
			}
			else{
				console.log(this.form.week_id.week);
				ax.get("/calendar/ngu_selected_week/", {
					params: {
						week_id: this.form.week_id.id
					}
				}).then(function (response) {
					console.log("Calendar Before Fill")
					console.log(self.calendars)
					self.calendars = response.data;
					console.log("Calendar After Fill")
					console.log(self.calendars)
				});
				}
			},
			getLoa_ovt: function () {
				var self = this;

				ax.get("/loa_ovt_type/all").then(function (response) {
					console.log(response.data);
					self.loa_ovt_types = response.data;
				});
			},			
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getAdminloa_ovt: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/employee_loa_ovt/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("admin timesheet record fetched test...");
					
					console.log(self.form);
					//this.form.week_id = self.form.week_id
					//self.getAdminTask(self.form.week_id);
					self.getCalendar(self.form.week_id);
					console.log("admin timesheet record fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				this.form.user_id = this.user.id;
				this.form.week_id = this.form.week_id.id;
				//this.$nextTick(function() {
				console.log(this.form);
				if (this.form.id > 0) {
					ax.post("/employee_loa_ovt/update/" + this.form.id, this.form).then(function (
						response
					) {
						console.log(response.data);

						console.log("Admins update...");

						self.$router.push({
							path: "/schooladmin/employee_loa_ovt"
						});
					});
				} else {
					ax.post("/employee_loa_ovt/create", this.form).then(function (response) {
						console.log(response.data);
						self.$router.push({
							path: "/schooladmin/employee_loa_ovt"
						});
						console.log("Mutations created...");
					});
				}
				//});
			}
		}
	};
</script>