import "babel-polyfill";
import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home";
// import Users from "../views/school/Users";
import UsersAdmin from "../views/school/UsersAdmin";
// import UserAdminAddEdit from "../views/school/UserAdminAddEdit";
// import UserAddEdit from "../views/school/UserAddEdit";
// import Configuration from "../views/school/Configuration";
// import ConfigurationAddEdit from "../views/school/ConfigurationAddEdit";
// import Class from "../views/school/Class";
// import ClassAddEdit from "../views/school/ClassAddEdit";
//import Sidebar from "../components/school/Sidebar";

import Main from "../views/Main";
import Login from "../views/Login";
import SignUpEmployee from "../views/schooladmin/EmployeeSignUpAddEdit";
import SignUpEmployeeThanks from "../views/schooladmin/EmployeeSignUp";
import SignUp from "../views/schooladmin/StudentSignUpAddEdit";
import SignUpThanks from "../views/schooladmin/StudentSignUp";
import SignUpCourse from "../views/schooladmin/StudentSignUpCourseAddEdit";
import SignUpCourseThanks from "../views/schooladmin/StudentSignUpCourse";
import reSignUp from "../views/schooladmin/StudentClassProjectionAddEdit";
import reSignUpThanks from "../views/schooladmin/StudentreSignUp";
//--------------- Absentie registratie
import TeacherAbsentStudentView from "../views/schooladmin/TeacherAbsentStudentView";
import reTeacherAbsent from "../views/schooladmin/TeacherAbsentAddEdit";
import reTeacherAbsentThanks from "../views/schooladmin/TeacherAbsentThanks";
import reEmployeeAbsent from "../views/schooladmin/EmployeeAbsentAddEdit";
import reEmployeeAbsentThanks from "../views/schooladmin/EmployeeAbsentThanks";
//----------------OSBOD Events
import OsbodEvent from "../views/schooladmin/OsbodEventAddEdit";
import OsbodEventTeacher from "../views/schooladmin/OsbodEventTeacherAddEdit";
import OsbodEventThanks from "../views/schooladmin/OsbodEventThanks";
import SignUpPap from "../views/schooladmin/StudentSignUpPapAddEdit";
import SignUpPapThanks from "../views/schooladmin/StudentSignUpPap";
//import Navbar from "../components/Navbar";
// import SchoolSubject from "../views/schooladmin/SchoolSubject";
// import SchoolSubjectAddEdit from "../views/schooladmin/SchoolSubjectAddEdit";
// import SubjectGroup from "../views/school/SubjectGroup";
// import SubjectGroupAddEdit from "../views/school/SubjectGroupAddEdit";
// import Coursesgrades from "../views/school/Coursesgrades";


// import TeacherGrades from "../views/school/TeacherGrades";
// import StudentAbsent from "../views/school/StudentAbsent";
// import StudentGrades from "../views/school/StudentGrades";


// import SchoolLevels from "../views/schooladmin/SchoolLevels";

// import AdminClass from "../views/school/AdminClass";
// import AdminClassAddEdit from "../views/school/AdminClassAddEdit";
//import Aanmelden from "../views/schooladmin/Aanmelden";
//import AanmeldenSchool from "../views/schooladmin/AanmeldenSchool";
// import schooltypes from "./schooltypes";
//  import schoolusers from "./schoolusers";
// import grade from "./grade";
// import level from "./level";
// import levelgrade from "./levelgrade";
// import levelstudies from "./levelstudies";
import roles from "./roles";
// import school from "./school";
// import schoolboard from "./schoolboard";
// import sectors from "./sectors";
// import study from "./study";
// import sectorstudy from "./sectorstudy";
// import studylevel from "./studylevel";
// import studylevelsubject from "./studylevelsubject";
// import schoolsubject from "./schoolsubject";
// import schoolsectors from "./schoolsectors";
// import schoolstudies from "./schoolstudies";
// import schoolgrades from "./schoolgrades";
// import schoolclasses from "./schoolclasses";
// import schoolsubjectgrade from "./schoolsubjectgrade";
// import subjecttype from "./subjecttype";
import studentintakes from "./studentintakes";
import studentregistrations from "./studentregistrations";
import studentregistrationsadmin from "./studentregistrationsadmin";
import studentregistrationsintake from "./studentregistrationsintake";
import studentregistrationscoordinator from "./studentregistrationscoordinator";
import employees from "./employees";
import students from "./students";
import mutations from "./mutations";
import employeetimesheets from "./employeetimesheets";
import studentclass from "./studentclass";
import studentclasscursus from "./studentclasscursus";
import employeecohort from "./employeecohort";
import studentcohort from "./studentcohort";
import studentclassprojection from "./studentclassprojection";
import studentclassprojectioncoordinator from "./studentclassprojectioncoordinator";
import osbodevent from "./osbodevent";
import osbodeventteacher from "./osbodeventteacher";
import studentclasscoordinator from "./studentclasscoordinator";
import studentclassaudit from "./studentclassaudit";
import studentovertakeclass from "./studentovertakeclass";
import subject from "./subjects";
import schoolclass from "./schoolclass"; 
import study from "./studies";
import teachersubjectclass from "./teachersubjectclass";
import teachersubjectclasscursus from "./teachersubjectclasscursus";
import adminemployeesignup from "./adminemployeesignups";

import teacherabsent from "./teacherabsent";
import teacherabsentstudentview from "./teacherabsentstudentview";
import employeeabsent from "./employeeabsent";
import employeeloaovt from "./employeeloaovt";

import admintask from "./admintasks";
import admintaskovertake from "./admintaskovertakes";
import admintimesheets from "./admintimesheets";
import admintimesheetovertakes from "./admintimesheetovertakes";

import schoolyearstudycost from "./schoolyearstudycost";
import financestudentplacements from "./financestudentplacements";
import financestudentviews from "./financestudentviews";
import financestudents from "./financestudents";

import exam from "./exam";
import examcursus from "./examcursus";

// (selected_system == 'osbod_salary' ? {
//   path: "/",
//   name: "login",
//   components: {
//     main: Login
//   }
// } : {
//   path: "/",
//   components: {
//     aanmelden: Aanmelden
//   }
// },{
//   path: "/login",
//   name: "login",
//   components: {
//     main: Login
//   }
// },{
//   path: '/about',
//   name: 'about',
//   route level code-splitting
//   this generates a separate chunk (about.[hash].js) for this route
//   which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
// })

//var selected_system = "osbod_salary";

Vue.use(Router);

export default new Router({
  linkActiveClass: "active",
  routes: [

    {
      path: "/",
      name: "login",
      components: {
        main: Login
      }
    },
    {
      path: "/aanmelden",
      name: "aanmelden",
      components: {
        main: SignUp
      }
    }, {
      path: "/bedankt",
      name: "bedankt",
      components: {
        main: SignUpThanks
      }
    },
    {
      path: "/aanmeldencursus",
      name: "aanmeldencursus",
      components: {
        main: SignUpCourse
      }
    }, {
      path: "/bedanktcursus",
      name: "bedanktcursus",
      components: {
        main: SignUpCourseThanks
      }
    },
    {
      path: "/aanmeldendocent",
      name: "aanmeldendocent",
      components: {
        main: SignUpEmployee
      }
    }, {
      path: "/bedanktdocent",
      name: "bedanktdocent",
      components: {
        main: SignUpEmployeeThanks
      }
    },
    {
      path: "/registrashon",
      name: "registrashon",
      components: {
        main: SignUpPap
      }
    }, {
      path: "/gradisimentu",
      name: "gradisimentu",
      components: {
        main: SignUpPapThanks
      }
    }, 
    {
      path: "/herinschrijving",
      name: "herinschrijving",
      components: {
        main: reSignUp
      }
    }, {
      path: "/bedanktherinschrijving",
      name: "bedanktherinschrijving",
      components: {
        main: reSignUpThanks
      }
    },
    {
      path: "/osbodevent",
      name: "osbodevent",
      components: {
        main: OsbodEvent
      }
    },
        {
      path: "/osbodeventteacher",
      name: "osbodeventteacher",
      components: {
        main: OsbodEventTeacher
      }
    },
    {
      path: "/bedanktosbodevent",
      name: "bedanktosbodevent",
      components: {
        main: OsbodEventThanks
      }
    },


   {
      path: "/teacherabsent",
      name: "teacherabsent",
      components: {
        main: reTeacherAbsent
      }
    }, 
    {
      path: "/teacherabsentstudentview",
      name: "teacherabsentstudentview",
      components: {
        main: TeacherAbsentStudentView
      }
    },
    {
      path: "/bedanktteacherabsent",
      name: "bedanktteacherabsent",
      components: {
        main: reTeacherAbsentThanks
      }
    },
     {
      path: "/employeeabsent",
      name: "employeeabsent",
      components: {
        main: reEmployeeAbsent
      }
    },
{
      path: "/bedanktemployeeabsent",
      name: "bedanktemployeeabsent",
      components: {
        main: reEmployeeAbsentThanks
      }
    },
    {
      path: "/main",
      name: "main",
      components: {
        main: Home,
        content: Main
      },
      children: [{
        path: "/users",
        components: {
          content: UsersAdmin
        }
      }].concat(roles, studentintakes, employees, mutations, studentregistrations, studentregistrationsadmin, students, studentclass,employeetimesheets,studentovertakeclass, subject, teachersubjectclass, studentclassaudit, studentclasscoordinator,adminemployeesignup, study, studentclassprojection, employeecohort, studentcohort, schoolclass, teacherabsent,osbodevent,osbodeventteacher, admintask, admintimesheets,employeeabsent,employeeloaovt,schoolyearstudycost, financestudentplacements, financestudentviews, financestudents, exam, admintaskovertake, admintimesheetovertakes, teacherabsentstudentview, studentregistrationscoordinator, studentregistrationsintake,studentclassprojectioncoordinator, studentclasscursus, teachersubjectclasscursus,examcursus)
    }
  ]
});