<template>
	<v-app id="inspire">
		<v-content>
			<v-container class="fill-height" fluid>
				<v-row align="center" justify="center">
					<v-col cols="12" sm="8" md="4">
						<v-card class="elevation-2">
							<v-toolbar color="primary" dark flat>
								<v-toolbar-title>Login OSBOD</v-toolbar-title>
								<div class="flex-grow-1"></div>
							</v-toolbar>
							<v-card-text>
								<v-form>
									<v-text-field
										label="Gebruikersnaam"
										name="Gebruikersnaam"
										v-model="form.username"
										prepend-icon="mdi-account"
										type="text"
										filled
									></v-text-field>

									<v-text-field
										id="password"
										label="Wachtwoord"
										name="Wachtwoord"
										v-model="form.password"
										prepend-icon="mdi-lock"
										type="password"
										filled
									></v-text-field>
								</v-form>
							</v-card-text>
							<v-card-actions>
								<div class="flex-grow-1"></div>
								<v-btn depressed color="secondary" @click="onSubmit" @keyup.Enter="onSubmit">Inloggen</v-btn>
							</v-card-actions>
						</v-card>
						<br />
						<v-alert
							:value="error"
							border="right"
							colored-border
							type="error"
							elevation="2"
							transition="scale-transition"
						>{{error_message}}</v-alert>
					</v-col>
				</v-row>
			</v-container>
		</v-content>
	</v-app>
</template>
<script>
export default {
	name: "login",
	data() {
		return {
			form: {
				username: "",
				password: ""
			},
			source: "",
			error_message: "",
			error: false
		};
	},
	computed: {
		user() {
			return this.$store.getters.getUser;
		},
		loggedin() {
			return this.$store.getters.getLoggedin;
		}
	},
	watch: {
		user: function() {
			var access = localStorage.getItem("access");
			console.log(access);

			if (access) {
				this.$router.push({
					path: "/Main"
				});
			} else {
				this.error_message = "Inloggen Mislukt!";
				this.error = true;
			}
		}
	}, 
	mounted() {

		// window.addEventListener("keyup", function(event) {
		// 	console.log(event);
		// });
	},
	methods: {
		Recover() {
			this.$router.push({
				path: "/recover"
			});
		},
		onSubmit() {
			console.log(this.form.username);
			console.log(this.form.password);
			console.log(this.form);

			this.$store.dispatch("login", {
				username: this.form.username,
				password: this.form.password
			});
		}
	}
};
</script>
 <style scoped>
</style>
