import TeacherAbsent from "../views/schooladmin/TeacherAbsent";
import TeacherAbsentAddEdit from "../views/schooladmin/TeacherAbsentAddEdit";

export default [{
    path: "/schooladmin/teacher_absent",
    components: {
      content: TeacherAbsent
    }
  },
  {
    path: "/schooladmin/teacher_absent/new",
    components: {
      content: TeacherAbsentAddEdit
    }
  },

  {
    path: "/schooladmin/teacher_absent/update/:id",
    components: {
      content: TeacherAbsentAddEdit
    },
    props: {
      content: true
    }
  }
];