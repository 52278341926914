import Mutation from "../views/schooladmin/Mutation";
import MutationAddEdit from "../views/schooladmin/MutationAddEdit";

export default [{
    path: "/schooladmin/mutation",
    components: {
      content: Mutation
    }
  },
  {
    path: "/schooladmin/mutation/new",
    components: {
      content: MutationAddEdit
    }
  },
  {
    path: "/schooladmin/mutation/update/:id",
    components: {
      content: MutationAddEdit
    },
    props: {
      content: true
    }
  }
];