<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2">OSBOD Student Plaatsing - Inschrijving</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
		</v-card-title>

		<v-data-table :headers="headers" :items="data" :search="search" :loading="loading">
			<template v-slot:top>
				<v-toolbar dark dense color="primary" flat class="mb-1">
				</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
				<v-icon @click="handleClick(item.id)">mdi-account-edit</v-icon>
			</template>
		</v-data-table>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "userregistrations",
	data() {
		return {
			//user: localStorage.getItem("user"),
			search: "",
			headers: [
				{
					text: "",
					align: "center",
					sortable: false,
					value: "action"
				},
				{
						text: "Status",
						value: "tbl_sys_registered_status.name"
					},
					{
						text: "Student Status",
						value: "old_student"
					},
					{
						text: "Schoolyear",
						value: "tbl_sys_schoolyear.name"
					},
					{
						text: "Study",
						value: "tbl_sys_study.name"
					},
					{
						text: "Government ID",
						value: "government_id"
					},
					{
						text: "Student",
						value: "display_name"
					},
					// {
					// 	text: "Mobiel",
					// 	value: "tel_mobile"
					// },{
					// 	text: "Thuis",
					// 	value: "tel_home"
					// },
					{
						text: "Intake dag",
						value: "appointment_date"
					},
					{
						text: "Intake tijdstip",
						value: "appointment_time"
					},
					// {
					// 	text: "Last Edit BY",
					// 	value: "tbl_user.display_name"
					// },

				// { text: "Active", value: "tbl_userregistration.userregistration_active" }

			],
			// semesters: [],
			// studies: [],
			// subjects: [],
			// subjecttypes: [],
			// selected_subject: null,
			// selected_kwalificatie: null,
			// selected_deelkwalificatie: null,
			// selected_semester: null,
			// schoolyear: [],
			// selected_schoolyear: null,
			data: [],
			// banktypes: [],
			user: JSON.parse(localStorage.getItem("user")),
			isEmpty: false,
			loading: false,
			isPaginated: true,
			isPaginationSimple: false,
			defaultSortDirection: "asc",
			currentPage: 1,
			perPage: 15
		};
	},
	created: function() {
		// this.getBanktypes();
		this.getStudentIntakes();
	},
	watch: {
		// selected_kwalificatie: function(value) {
		// 	this.getStudySubjecttype(value);
		// 	console.log(value);
		// }
	},
	computed: {
		// school() {
		// 	return this.$store.getters.getSchool;
		// }
	},
	methods: {
		handleClick(id) {
			this.$router.push({
				path: "/schooladmin/studentregistrationcoordinator/update/" + id
			});
		},
		setStudentIntake: function() {
			this.$router.push({
				path: "/schooladmin/studentregistration/new"
			});
		},
		getStudentIntakes: function() {
			var self = this;
			this.loading = true;
			ax.get("/userregistration/coordinator", {
					params: {
						user_id: this.user.id
					}
				}).then(function(response) {
					// console.log(response.data);
				self.data = response.data;
				console.log("StudentIntakes fetched...");
				console.log(self.data);
				self.loading = false;
			});
		},
		// getBanktypes: function() {
		// 	var self = this;
		// 	ax.get("/banktype/all").then(function(response) {
		// 		console.log(response.data);
		// 		self.banktypes = response.data;
		// 	});
		// }
	}
};
</script>
 
 <!-- Add "slot-scoped" attribute to limit CSS to this component only -->
 <style slot-scoped>
</style>