<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2">OSBOD Toetsen</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
		</v-card-title>

		<v-data-table :headers="headers" :items="data" :search="search" :loading="loading">
			<template v-slot:top>
				<v-toolbar dark dense color="primary" flat class="mb-1">
					<v-btn depressed class="ml-2" @click="setExam()" color="inverted">
						<v-icon class="pr-2">mdi-plus-circle</v-icon>Add Exam
					</v-btn>
				</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
				<v-icon @click="handleClick(item.id)">mdi-account-edit</v-icon>
			</template>
		</v-data-table>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "exams",
	data() {
		return {
			//user: localStorage.getItem("user"),
			search: "",
			headers: [
				{
					text: "",
					align: "center",
					sortable: false,
					value: "action"
				},
				{ text: "Schooljaar", value: "tbl_sys_schoolyear.name" },
				{ text: "Sector", value: "tbl_sys_sector.name" },
				
				{ text: "Opleiding", value: "tbl_sys_study.name" },
				{ text: "Klas", value: "tbl_sys_class.name" },
				{ text: "Periode", value: "tbl_sys_periode.name" },
				{ text: "Deelkwalificatie", value: "tbl_sys_subject.name" },
				{ text: "Module", value: "tbl_teacher_subject_class.module" },
				{ text: "Docent", value: "tbl_employee.teacher_name" }
				
				// { text: "Omschrijving", value: "description" },
				
				// { text: "First Check", value: "firstcheckby"},
				// { text: "Second Check",	value: "secondcheckby"},
				// { text: "Active", value: "tbl_exam.exam_active" }

			],
			// semesters: [],
			// studies: [],
			// exams: [],
			// examtypes: [],
			// selected_exam: null,
			// selected_kwalificatie: null,
			// selected_deelkwalificatie: null,
			// selected_semester: null,
			// schoolyear: [],
			// selected_schoolyear: null,
			data: [],
			// banktypes: [],
			isEmpty: false,
			loading: false,
			isPaginated: true,
			isPaginationSimple: false,
			defaultSortDirection: "asc",
			currentPage: 1,
			perPage: 15
		};
	},
	created: function() {
		// this.getBanktypes();
		this.getExam();
	},
	watch: {
		// selected_kwalificatie: function(value) {
		// 	this.getStudyExamtype(value);
		// 	console.log(value);
		// }
	},
	computed: {
		// school() {
		// 	return this.$store.getters.getSchool;
		// }
	},
	methods: {
		handleClick(id) {
			this.$router.push({
				path: "/schooladmin/examcursus/update/" + id
			});
		},
		setExam: function() {
			this.$router.push({
				path: "/schooladmin/examcursus/new"
			});
		},
		getExam: function() {
			var self = this;
			this.loading = true;
			ax.get("/exam/all_cursus").then(function(response) {
					// console.log(response.data);
				self.data = response.data;
				console.log("Exams fetched...");
				console.log(response.data);
				self.loading = false;
			});
		},
		// getBanktypes: function() {
		// 	var self = this;
		// 	ax.get("/banktype/all").then(function(response) {
		// 		console.log(response.data);
		// 		self.banktypes = response.data;
		// 	});
		// }
	}
};
</script>
 
 <!-- Add "slot-scoped" attribute to limit CSS to this component only -->
 <style slot-scoped>
</style>