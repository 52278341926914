<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Student Class</span>
				<span class="pl-2" v-else>Add Student Class</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Instructies voor het toevoegen van een student</p>
						<p class="font-weight-regular">1.Kies schooljaar.</p>
						<p class="font-weight-regular">2.Kies student.</p>
						
					</v-col>
					<v-col cols="12" sm="6"></v-col>
					<v-col cols="12" sm="6">

						<p class="font-weight-black title">Inchrijving informatie</p>
						<v-select dense :items="schoolyears" item-text="name" item-value="id"
							v-model="form.schoolyear_id" filled label="Schoolyear"></v-select>
						<v-select dense :items="registration_types" item-text="name" item-value="id"
							v-model="form.registration_type_id" filled label="Registratie"></v-select>
							<v-select dense :items="studys" item-text="name" item-value="id"
							v-model="form.study_id" filled label="Studie"></v-select>
						<v-select dense :items="students" item-text="display_name" item-value="id"
							v-model="form.student_id" filled label="Student"></v-select>
						<v-checkbox color="red" v-model="form.deleted">
								<template v-slot:label>
									<div><strong class="error--text">Delete</strong></div>
								</template>
							</v-checkbox>
					</v-col>
					
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "mutationedit",
		props: ["id"],
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user")),
				snackbar: false,
				schoolyears: [],
				studys: [],
				students: [],
				registration_types: [],
				users: [],
				form: {
					id: 0,
					schoolyear_id: null,
					student_id: null,
					study_id: null,
					registration_type_id: null,
					deleted: null,
					deleted_by_id: null,
				},

			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			this.getStudys();
			this.getRegistrationTypes();
			this.getStudentsAll();
			if (this.id > 0) {
				this.getStudentCohort(this.id);
			} 
		},
		watch: {

		},
		methods: {
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getStudys: function () {
				var self = this;

				ax.get("/study/all").then(function (response) {
					console.log(response.data);
					self.studys = response.data;
				});
			},
			getRegistrationTypes: function () {
				var self = this;

				ax.get("/registrationtype/all").then(function (response) {
					console.log(response.data);
					self.registration_types = response.data;
				});
			},

			
			getStudentsAll: function () {
				var self = this;

				ax.get("/student/all", 
			).then(function (response) {
					console.log(response.data);
					self.students = response.data;
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getStudentCohort: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/student_cohort/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("Student Class fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				if (this.form.id > 0) {

					ax.post("/student_cohort/update/" + this.form.id, this.form).then(function (
						response
					) {
						console.log(response.data);

						console.log("StudentCohorts update...");

						self.$router.push({
							path: "/schooladmin/student_cohort"
						});
					});
				} else {
					ax.post("/student_cohort/create", this.form).then(function (response) {
						console.log(response.data);
						self.$router.push({
							path: "/schooladmin/student_cohort"
						});
						console.log("StudentCohorts created...");
					});
				}
				//});
			}
		}
	};
</script>