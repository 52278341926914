<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Student Inschrijving</span>
				<span class="pl-2" v-else>Formulario registrashon OSBOD</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-stepper v-model="e1">
			<v-stepper-header>
				<v-stepper-step :complete="e1 > 1" step="1">
					<p class="font-weight-black title">Informashon di studiante</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 2" step="2">
					<p class="font-weight-black title">Estudio previo</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 4" step="3">
					<p class="font-weight-black title">Informashon trabou / Persona di kontakto</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 3" step="4">
					<p class="font-weight-black title">Registra</p>
				</v-stepper-step>
			</v-stepper-header>
			<v-form lazy-validation>
				<v-stepper-items>
					<v-stepper-content step="1">
						<v-card-text>
							<v-row>
								<v-col cols="12" sm="6">
									<v-text-field dense v-model="form.maidenname" label="Fam kasá" filled>
									</v-text-field>
									<v-text-field dense v-model="form.surname" label="Fam*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.firstname" label="Nòmbernan (kompletu)*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-select dense :items="genders" item-text="name_pap" item-value="id"
										v-model="form.gender_id" filled label="Sexo*" :rules="[rules.required]"
										:error-messages="errorMessages" required></v-select>
									<v-menu ref="menu_birthdate" v-model="menu_birthdate"
										:close-on-content-click="false" :return-value.sync="form.birthdate"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.birthdate" label="Fecha di nasementu*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.birthdate" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_birthdate = false">Cancel</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_birthdate.save(form.birthdate)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
									<v-select dense :items="birthplaces" item-text="name_pap" item-value="id"
										v-model="form.birthplace_id" filled label="Luga di nasementu*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-select dense :items="nationalitys" item-text="name_pap" item-value="id"
										v-model="form.nationality_id" filled label="Nashonalidat*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>


								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field dense v-model="form.government_id" label="Nr. di sedula balido*"
										filled :counter=10
										:rules="[rules.required, rules.governmentid, rules.number_only]"
										:error-messages="errorMessages" required>
									</v-text-field>
									<v-select dense :items="maritial_statuss" item-text="name_pap" item-value="id"
										v-model="form.maritial_status_id" filled label="Estado sivil*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-select dense :items="denominations" item-text="name_pap" item-value="id"
										v-model="form.denomination_id" filled label="Religion*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-text-field dense v-model="form.address" label="Adrès*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.email" label="Email*" filled
										:rules="[rules.required, rules.email]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-row>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_mobile"
												prepend-inner-icon="mdi-cellphone" label="Selular*" filled :counter=7
												:rules="[rules.required, rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages" required>
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_home"
												prepend-inner-icon="mdi-phone-classic" label="Kas" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages">
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_work"
												prepend-inner-icon="mdi-deskphone" label="Trabou" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages"></v-text-field>
										</v-col>
									</v-row>



								</v-col>
							</v-row>
						</v-card-text>


						<v-btn color="primary" @click="submitFormTransition()" depressed>
							Continue
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Cancel</v-btn>
					</v-stepper-content>
					<v-stepper-content step="2">
						<v-card>
							<v-col cols="12" sm="6">
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_fo" label="FO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_fo_level" label="t/k grupo:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_fo_year" label="den aña:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_vo" label="VO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vo_level" label="nivel:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vo_year" label="te ku aña:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_vsbo" label="VSBO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vsbo_level" label="nivel:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vsbo_year" label="te ku aña:" filled>
										</v-text-field>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_sbo" label="SBO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_sbo_level" label="nivel:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_sbo_year" label="te ku aña::" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_other" label="Andere"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_other_level" label="nivel:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_other_year" label="te ku aña::" filled>
										</v-text-field>
									</v-col>
								</v-row>
							</v-col>
						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Back</v-btn>
						<v-btn color="primary" @click="e1=e1+1" depressed>
							Continue
						</v-btn>
						<v-btn color="error" @click="$router.go(-1);">Cancel</v-btn>
					</v-stepper-content>

					<v-stepper-content step="3">
						<v-card>
							<v-col cols="12" sm="6">
								<v-checkbox v-model="form.payee_confirmation"
									label="Pago nan ta ser hasi dor di kompania"></v-checkbox>
								<v-text-field dense v-model="form.payee_name" label="Nomber di kompania" filled>
								</v-text-field>
								<v-text-field dense v-model="form.payee_address" label="Adres di kompania" filled>
								</v-text-field>
								<v-text-field dense v-model="form.payee_telephone" label="Nomber persona di kontakto"
									filled>
								</v-text-field>
								<v-text-field dense v-model="form.payee_contact_person"
									label="Number di telefon persona di kontakto" filled></v-text-field>
								<v-text-field dense v-model="form.payee_email" label="Email persona di kontakto" filled>
								</v-text-field>
							</v-col>
						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Back</v-btn>

						<v-btn color="primary" @click="submitFormTransition(id)" depressed>
							Continue
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Cancel</v-btn>
					</v-stepper-content>
					<v-stepper-content step="4">

						<v-card>
							<v-row>
								<v-col cols="12" sm="12">
									<p class="font-weight-black title">Inschrijf- en Betalingsvoorwaarden</p>
									<p class="font-weight-bold">Ik wens mij aan te melden voor de opleiding:</p>
									<v-select dense :items="studys" item-text="name" item-value="id"
										v-model="form.study_id" filled label="Study*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>

									<p class="font-weight-bold">1. Inskribí pa un estudio</p>
									<p class="font-weight-regular">Na momentu ku bo firma e formulario di inskripshon,
										bo ta deklará di bai di akuerdo ku e reglanan di inskripshon i pago.
										Nos ta informá bo, ku bo tin ku tene kuenta ku e regla nobo di inskripshon,
										kaminda komo studiante bo mester inskribí bo mes tur aña eskolar di nobo i paga
										e montante inskripshon.</p>
									<p class="font-weight-bold">2. Manera di pago</p>
									<p class="font-weight-regular">Pago di inskripshon mester wòrdu depositá riba kuenta
										di banko di OSBOD ku menshon di: nòmber di e studiante i kua estudio riba number
										di kuenta GIRO-Bank 91.02.806
										Pago di skol mester wòrdu depositá riba number di kuenta MCB-Bank 32.06.19.10 ku
										menshon di nòmber di e studiante, estudio i klas.

									</p>
									<p class="font-weight-bold">3. Retiro for di estudio</p>
									<p class="font-weight-regular">Despues di 2 luna di tabata ousente di e aktividatnan
										di skol sin un motibu válido i sin prueba, bo inskripshon ta wòrdu kanselá
										outomátikamente, ku konsekuensia ku bo mester inskribí di nobo pa e siguiente
										aña eskolar. Studiante mester paga kontribushon di inskripshon di nobo.</p>
									<p class="font-weight-bold">Ningun pago hasí no ta wòrdu debolbé.
									</p>
									<p class="font-weight-regular">
										Si akaso pa un òf otro motibu bo mester stòp ku e estudio, bo mester yena e
										formulario digital di retiro for di estudio.
										Despues lo bo risibí un e-mail di nos departamento di atministrashon ku bo
										komprobante di retiro for di skol.
									</p>
									<p class="font-weight-bold">4. Materiaal di les</p>
									<p class="font-weight-regular">Lo bo risibi na kuminsamentu di e estudio, e
										materialnan nesesario, si bo a kumpli ku tur e obligashonnan finansiero.</p>
									<p class="font-weight-bold">5. Partisipashon na pruebanan</p>
									<p class="font-weight-regular">Tur fecha importante inkluyendo fecha di prueba i hèr
										por ser haña bek den e roster di aña. Tur e reglanan tokante prueba ta traha den
										akuerdo ku Inspekshon di Enseñansa i pa esaki por konsultá e buki di èksamen di
										OSBOD.</p>
									<p class="font-weight-bold">6. Kambio di estudio</p>
									<p class="font-weight-regular">OSBOD ta mantené e derecho pa trese kambio den
										planifikashon / dosente / lokalidat i orarionan. Na momentu ku no tin sufisiente
										inskripshon pa un estudio esaki lo wòrdu kanselá. Studiantenan ku ta inskribí pa
										e estudio ei, lo risibí informashon al respekto na tempu i por eskrito. Den e
										situashon spesífiko aki e obligashonnan finansiero di e studiante lo kaduká. Si
										e pago di inskripshon a wòrdu hasi kaba, studiante lo risibí restitushon di e
										pago hasi.</p>

									<v-checkbox v-model="form.registration_complete"
										label="Mi ta deklará di e yena tur informashon di registrashon korektamente i segun realidat.*"
										required :rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
									<v-checkbox v-model="form.registration_agree" required
										label="Mi ta bai di akuerdo ku e Reglanan pa inskripshon i pago.*"
										:rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
								</v-col>
							</v-row>
						</v-card>
						<v-card-actions>
							<v-btn color="secondary" @click="e1=e1-1">Back</v-btn>
							<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
							<v-btn color="error" @click="$router.go(-1);" depressed>Cancel</v-btn>
						</v-card-actions>
					</v-stepper-content>
				</v-stepper-items>
			</v-form>
		</v-stepper>



		<v-card-text>

			<v-row>


			</v-row>

		</v-card-text>
		<!-- <v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions> -->

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			U dient alle verplichte velden in te vullen! Druk op de terug knop om de verplichte velden alsnog in te
			vullen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "usersignupadd",
		props: ["id"],
		data() {
			return {
				e1: 1,
				valid: true,
				errorMessages: '',
				formHasErrors: false,
				user: localStorage.getItem("user"),
				snackbar: false,
				schoolyears: [],
				studys: [],
				users: [],
				genders: [],
				birthplaces: [],
				nationalitys: [],
				maritial_statuss: [],
				denominations: [],
				menu: false,
				menu_birthdate: false,
				rules: {
					required: value => !!value || 'E informashon aki ta rekeri!.',
					email: value => {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						return pattern.test(value) || 'e-mail aki ta no ta korekto.'
					},
					governmentid: value => (value && value.length == 10) ||
						'Number di sedula ta rekeri 10 sifra.',
					telephone_number: value => (value && value.length == 7) ||
						'Number di telefon por kontene solamente 7 sifra.',
					number_only: value => {
						const pattern = /^[0-9]+$/
						return pattern.test(value) || 'Solamente sifra ta permiti.'
					}
				},
				form: {
					id: 0,
					role_id: 1,
					schoolyear_id: 1,
					registration_type_id:1,
					study_id: null,
					firstname: "",
					surname: "",
					maidenname: "",
					government_id: "",
					birthdate: "",
					birthplace_id: null,
					gender_id: null,
					nationality_id: null,
					maritial_status_id: null,
					denomination_id: null,
					address: "",
					email: "",
					tel_home: "",
					tel_mobile: "",
					tel_work: "",
					registration_agree: "",
					registration_complete: "",
					display_name: "",
					payee_confirmation: 0,
					payee_name: "",
					payee_address: "",
					payee_telephone: "",
					payee_contact_person: "",
					payee_email: "",
					edu_fo: 0,
					edu_fo_level: null,
					edu_fo_year: null,
					edu_vo: 0,
					edu_vo_level: null,
					edu_vo_year: null,
					edu_vsbo: 0,
					edu_vsbo_level: null,
					edu_vsbo_year: null,
					edu_sbo: 0,
					edu_sbo_level: null,
					edu_sbo_year: null,
					edu_other: 0,
					edu_other_level: null,
					edu_other_year: null
				}
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			this.getStudys();
			this.getGenders();
			this.getBirthplaces();
			this.getNationalitys();
			this.getMaritialstatus();
			this.getDenomination();
			if (this.id > 0) {
				this.getUserRegistration(this.id);
			}
		},
		watch: {
			name() {
				this.errorMessages = '';
			},
		},
		methods: {
			validate() {
				this.$refs.form.validate()
			},
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getBirthplaces: function () {
				var self = this;

				ax.get("/birthplace/all").then(function (response) {
					console.log(response.data);
					self.birthplaces = response.data;
				});
			},
			getNationalitys: function () {
				var self = this;

				ax.get("/nationality/all").then(function (response) {
					console.log(response.data);
					self.nationalitys = response.data;
				});
			},
			getMaritialstatus: function () {
				var self = this;

				ax.get("/maritial_status/all").then(function (response) {
					console.log(response.data);
					self.maritial_statuss = response.data;
				});
			},
			getDenomination: function () {
				var self = this;

				ax.get("/denomination/all").then(function (response) {
					console.log(response.data);
					self.denominations = response.data;
				});
			},
			getStudys: function () {
				var self = this;

				ax.get("/study/all").then(function (response) {
					console.log(response.data);
					self.studys = response.data;
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getGenders: function () {
				var self = this;

				ax.get("/gender/all").then(function (response) {
					console.log(response.data);
					self.genders = response.data;
				});
			},
			getUserRegistration: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/userregistration/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("UserRegistrations fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				this.formHasErrors = false;

				if (this.form.study_id == null || this.form.study_id == "") {
					this.formHasErrors = true;
					this.form.study_id = "";

				}
				if (this.form.registration_agree == null || this.form.registration_agree == "") {
					this.formHasErrors = true;
					this.form.registration_agree = null;
				}
				if (this.form.registration_complete == null || this.form.registration_complete == "") {
					this.formHasErrors = true;
					this.form.registration_complete = null;
				}
				//this.$nextTick(function() {
				console.log(this.form);
				if (this.form.maidenname.length > 0) {
					this.form.display_name = this.form.firstname + " " + this.form.surname + " e/v " + this.form
						.maidenname;
				} else {
					this.form.display_name = this.form.firstname + " " + this.form.surname;
				}
				if (this.formHasErrors == false) {
					if (this.form.id > 0) {
						ax.post("/userregistration/update/" + this.form.id, this.form).then(function (
							response
						) {
							console.log(response.data);

							console.log("UserRegistrations update...");

							self.$router.push({
								path: "/schooladmin/studentregistration"
							});
						});
					} else {
						console.log("before post");
						ax.post("/userregistration/create", this.form).then(function (response) {
							console.log("almost there");
							console.log(response.data);
							self.$router.push({
								path: "/bedankt"
							});
							console.log("UserRegistrations created...");
						});


					}
				} else {
					this.form
				}
				//});
			},
			submitFormTransition() {
				var self = this;
				this.formHasErrors = false;

				if (this.form.surname == null || this.form.surname == "") {
					this.formHasErrors = true;
					this.form.surname = null;
				}
				if (this.form.firstname == null || this.form.firstname == "") {
					this.formHasErrors = true;
					this.form.firstname = null;
				}
				if (this.form.birthdate == null || this.form.birthdate == "") {
					this.formHasErrors = true;
					this.form.birthdate = null;
				}
				if (this.form.birthplace_id == null || this.form.birthplace_id == "") {
					this.formHasErrors = true;
					this.form.birthplace_id = "";
				}
				if (this.form.government_id == null || this.form.government_id == "") {
					this.formHasErrors = true;
					this.form.government_id = null;
				}
				if (this.form.tel_mobile == null || this.form.tel_mobile == "") {
					this.formHasErrors = true;
					this.form.tel_mobile = null;
				}
				if (this.form.denomination_id == null || this.form.denomination_id == "") {
					this.formHasErrors = true;
					this.form.denomination_id = "";
				}
				if (this.form.nationality_id == null || this.form.nationality_id == "") {
					this.formHasErrors = true;
					this.form.nationality_id = "";
				}
				if (this.form.email == null || this.form.email == "") {
					this.formHasErrors = true;
					this.form.email = null;
				}
				if (this.form.address == null || this.form.address == "") {
					this.formHasErrors = true;
					this.form.address = null;
				}
				if (this.form.gender_id == null || this.form.gender_id == "") {
					this.formHasErrors = true;
					this.form.gender_id = "";
				}
				if (this.form.maritial_status_id == null || this.form.maritial_status_id == "") {
					this.formHasErrors = true;
					this.form.maritial_status_id = "";
				}
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			}
		}
	};
</script>