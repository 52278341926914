<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Student Inhaalles</span>
				<span class="pl-2" v-else>Add Student Inhaalles</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >School info</p>
						<v-select
							dense
							:items="schoolyears"
							item-text="name"
							item-value="id"
							v-model="form.schoolyear_id"
							filled
							label="Schoolyear"
						></v-select>
						<v-select
							dense
							:items="studys"
							item-text="name"
							item-value="id"
							v-model="form.study_id"
							filled
							label="Opleiding"
						></v-select>
						<v-select
							dense
							:items="classs"
							item-text="name"
							item-value="id"
							v-model="form.class_id"
							filled
							label="Klas"
						></v-select>
						
					</v-col>
					
						<v-col cols="12" sm="6">
							<p class="font-weight-black title" >Student</p>
						<v-select
							dense
							:items="students"
							item-text="tbl_student.display_name"
							item-value="tbl_student.id"
							v-model="form.student_id"
							filled
							label="Student"
						></v-select>
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >Inhaalles</p>
						
						<v-select
							dense
							:items="class1"
							item-text="name"
							item-value="id"
							v-model="form.class_overtake_id"
							filled
							label="Klas"
						></v-select>
						<v-select
							dense
							:items="periodes"
							item-text="name"
							item-value="id"
							v-model="form.periode_id"
							filled
							label="Periode"
						></v-select>
						<v-select
							dense
							:items="subjects"
							item-text="name"
							item-value="id"
							v-model="form.subject_id"
							filled
							label="Deelkwalificatie"
						></v-select>
						<v-select
							dense
							:items="modules"
							item-text="module"
							item-value="id"
							v-model="form.module_id"
							filled
							label="Module"
						></v-select>
						<v-textarea filled name="input-7-4" label="Motivatie" v-model="form.description"></v-textarea>
					</v-col> 
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "mutationedit",
	props: ["id"],
	data() {
		return {
			user: localStorage.getItem("user"),
			snackbar: false,
			schoolyears:[],
			classs:[],
			class1:[],
			students:[],
			studys:[],
			periodes:[],
			subjects:[],
			modules:[],
			users:[],
			form: {
				id: 0,
				schoolyear_id: null,
				student_id: null,
				class_id: null,
				subject_id: null,
				module_id: null,
				description: null,
				periode_id: null,
				class_overtake_id: null,
				study_id: 0
			}
		};
	},
	created: function() {
		this.getUsers();
		this.getSchoolyears();
		this.getClass();
		this.getPeriode();
		this.getStudy();
		
		if (this.id > 0) {
				this.getStudentOvertakeSubject(this.id);
				this.classovertake =  this.form.class_overtake_id;
				console.log(this.form.class_overtake_id);
			}
	},
	watch: {
		"form.study_id": function(value){
				this.getClass(value);
				// this.getClass1(value);
				
			},
		"form.class_id": function(value){
				this.getStudent(value);
				
			},
		"form.class_overtake_id": function(value){
				console.log(value);
				
				this.getSubject(value);
				
			},
		"form.periode_id": function(value){
				console.log(value);
				this.getSubject(value);
			},
		"form.subject_id": function(value){
				console.log(value);
				this.getModule(value);
			},
	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		getSchoolyears: function() {
			var self = this;

			ax.get("/schoolyear/all").then(function(response) {
				console.log(response.data);
				self.schoolyears = response.data;
			});
		},
		getStudent: function() {
			var self = this;
			
			ax.get("/student_class/selected_class_retake/", {
				params: {
					class_id: self.form.class_id,
					schoolyear_id: self.form.schoolyear_id,
				}
			}).then(function(response) {
				console.log("Before Fill")
				console.log(self.students)
				self.students = response.data;
				console.log("After Fill")
				console.log(self.students)
			});
			
		},
		getStudy: function() {
			var self = this;
			
			ax.get("/study/all/", {
				// params: {
				// 	id: self.form.class_id,
				// }
			}).then(function(response) {
				console.log("Before Fill")
				console.log(self.studys)
				self.studys = response.data;
				console.log("After Fill")
				console.log(self.studys)
			});
			
		},
		getSubject: function() {
			var self = this;
			
			ax.get("/subject/selected_class/", {
				params: {
					class_id: self.form.class_overtake_id,
					periode_id: self.form.periode_id
				}
			}).then(function(response) {
				console.log("Subject Before Fill")
				console.log(self.subjects)
				self.subjects = response.data;
				console.log("Subject After Fill")
				console.log(self.subjects)
			});
			
		},
		getModule: function() {
			var self = this;
			
			ax.get("/teacher_subject_class/all_module/", {
				params: {
					class_id: self.form.class_overtake_id,
					periode_id: self.form.periode_id,
					subject_id: self.form.subject_id
				}
			}).then(function(response) {
				console.log("Subject Before Fill")
				console.log(self.modules)
				self.modules = response.data;
				console.log("Subject After Fill")
				console.log(self.modules)
			});
			
		},
		getClass: function() {
			var self = this;

			ax.get("/school_class/selected_study/", {
				params: {
					study_id: self.form.study_id,
				}
			}).then(function(response) {
				console.log("Before Fill")
				console.log(self.classs)
				self.classs = response.data;
				self.class1 = response.data;
				console.log("After Fill")
				console.log(self.classs)
			});
			
		
		},
		getClass1: function() {
			var self = this;

			ax.get("/school_class/selected_study/", {
				params: {
					study_id: self.form.study_id,
				}
			}).then(function(response) {
				console.log("Before Fill")
				console.log(self.classs)
				self.class1 = response.data;
				console.log("After Fill")
				console.log(self.classs)
			});
			
		
		},
		getPeriode: function() {
			var self = this;

			ax.get("/periode/all").then(function(response) {
				console.log("Periode Fill")
				console.log(response.data);
				self.periodes = response.data;
			});
		},
		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				console.log(response.data);
				self.users = response.data;
			});
		},
		getStudentOvertakeSubject: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/student_overtake_class/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					self.loading = false;
				});
			},
		submitForm() {
			var self = this;

			//this.$nextTick(function() {
			if (this.form.id > 0) {
				ax.post("/student_overtake_class/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Mutations update...");

					self.$router.push({
						path: "/schooladmin/student_overtake_class"
					});
				});
			} else {
				ax.post("/student_overtake_class/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/student_overtake_class"
					});
					console.log("Mutations created...");
				});
			}
			//});
		}
	}
};
</script>
