<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2">OSBOD Klas</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
		</v-card-title>

		<v-data-table :headers="headers" :items="data" :search="search" :loading="loading">
			<template v-slot:top>
				<v-toolbar dark dense color="primary" flat class="mb-1">
					<v-btn depressed class="ml-2" @click="setSchoolClass()" color="inverted">
						<v-icon class="pr-2">mdi-plus-circle</v-icon>Add Klas
					</v-btn>
				</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
				<v-icon @click="handleClick(item.id)">mdi-account-edit</v-icon>
			</template>
			<template v-slot:item.visible="{ item }">
        <v-simple-checkbox
          v-model="item.visible"
          disabled
        ></v-simple-checkbox>
      </template>
		</v-data-table>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "school_classs",
	data() {
		return {
			//user: localStorage.getItem("user"),
			search: "",
			headers: [
				{
					text: "",
					align: "center",
					sortable: false,
					value: "action"
				},
				{ text: "Opleiding", value: "tbl_sys_study.name" },
				{ text: "Niveau", value: "tbl_sys_level.name" },
				{ text: "Leerjaar", value: "tbl_sys_grade.name" },
				{ text: "Klas", value: "name" },
				{ text: "Beschikbaar", value: "visible" },
				
				// { text: "First Check", value: "firstcheckby"},
				// { text: "Second Check",	value: "secondcheckby"},
				// { text: "Active", value: "tbl_school_class.school_class_active" }

			],
			// semesters: [],
			// studies: [],
			// school_classs: [],
			// school_classtypes: [],
			// selected_school_class: null,
			// selected_kwalificatie: null,
			// selected_deelkwalificatie: null,
			// selected_semester: null,
			// schoolyear: [],
			// selected_schoolyear: null,
			data: [],
			// banktypes: [],
			isEmpty: false,
			loading: false,
			isPaginated: true,
			isPaginationSimple: false,
			defaultSortDirection: "asc",
			currentPage: 1,
			perPage: 15
		};
	},
	created: function() {
		// this.getBanktypes();
		this.getSchoolClasss();
	},
	watch: {
		// selected_kwalificatie: function(value) {
		// 	this.getStudySchoolClasstype(value);
		// 	console.log(value);
		// }
	},
	computed: {
		// school() {
		// 	return this.$store.getters.getSchool;
		// }
	},
	methods: {
		handleClick(id) {
			this.$router.push({
				path: "/schooladmin/schoolclass/update/" + id
			});
		},
		setSchoolClass: function() {
			this.$router.push({
				path: "/schooladmin/schoolclass/new"
			});
		},
		getSchoolClasss: function() {
			var self = this;
			this.loading = true;
			ax.get("/school_class/all").then(function(response) {
					// console.log(response.data);
				self.data = response.data;
				console.log("SchoolClasss fetched...");
				console.log(response.data);
				self.loading = false;
			});
		},
		// getBanktypes: function() {
		// 	var self = this;
		// 	ax.get("/banktype/all").then(function(response) {
		// 		console.log(response.data);
		// 		self.banktypes = response.data;
		// 	});
		// }
	}
};
</script>
 
 <!-- Add "slot-scoped" attribute to limit CSS to this component only -->
 <style slot-scoped>
</style>