import StudentClassProjectionCoordinator from "../views/schooladmin/StudentClassProjectionCoordinator";
import StudentClassProjectionCoordinatorAddEdit from "../views/schooladmin/StudentClassProjectionCoordinatorAddEdit";

export default [{
    path: "/schooladmin/student_class_projection_coordinator",
    components: {
      content: StudentClassProjectionCoordinator
    }
  },
  {
    path: "/schooladmin/student_class_projection_coordinator/new",
    components: {
      content: StudentClassProjectionCoordinatorAddEdit
    }
  },

  {
    path: "/schooladmin/student_class_projection_coordinator/update/:id",
    components: {
      content: StudentClassProjectionCoordinatorAddEdit
    },
    props: {
      content: true
    }
  }
];