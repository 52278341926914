import SchoolClass from "../views/schooladmin/SchoolClass";
import SchoolClassAddEdit from "../views/schooladmin/SchoolClassAddEdit";

export default [{
    path: "/schooladmin/schoolclass",
    components: {
      content: SchoolClass
    }
  },
  {
    path: "/schooladmin/schoolclass/new",
    components: {
      content: SchoolClassAddEdit
    }
  },
  {
    path: "/schooladmin/schoolclass/update/:id",
    components: {
      content: SchoolClassAddEdit
    },
    props: {
      content: true
    }
  }
];