<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Student Intake</span>
				<span class="pl-2" v-else>Add new Student Intake</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>

					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Registration type</p>
						<v-select dense :items="registration_types" item-text="name" item-value="id"
							v-model="form.registration_type_id" filled label="Registration Type"></v-select>
						<p class="font-weight-black title">Schoolyear</p>
						<v-select dense :items="schoolyears" item-text="name" item-value="id"
							v-model="form.schoolyear_id" filled label="Schoolyear"></v-select>

					</v-col>
					<v-col>
						<p class="font-weight-black title">Study</p>
						<v-select dense :items="studys" item-text="name" item-value="id" v-model="form.study_id" filled
							label="Study"></v-select>
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Student Information</p>
						<v-text-field dense v-model="form.maidenname" label="Maidenname" filled></v-text-field>
						<v-text-field dense v-model="form.surname" label="Lastname" filled></v-text-field>
						<v-text-field dense v-model="form.firstname" label="Firstname" filled></v-text-field>
						<v-text-field dense v-model="form.government_id" label="Government ID" filled></v-text-field>
						<v-menu ref="menu_govid_exp_date" v-model="menu_govid_exp_date" :close-on-content-click="false"
							:return-value.sync="form.govid_exp_date" transition="scale-transition" offset-y
							min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.govid_exp_date" label="GovernmentID expiration date"
									prepend-inner-icon="mdi-calendar" readonly v-on="on" filled></v-text-field>
							</template>
							<v-date-picker v-model="form.govid_exp_date" no-title scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu_govid_exp_date = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.menu_govid_exp_date.save(form.govid_exp_date)">OK</v-btn>
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Intake info</p>
						<v-menu ref="menu" v-model="menu" :close-on-content-click="false"
							:return-value.sync="form.appointment_date" transition="scale-transition" offset-y
							min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.appointment_date" label="Intake date"
									prepend-inner-icon="mdi-calendar" readonly v-on="on" filled></v-text-field>
							</template>
							<v-date-picker v-model="form.appointment_date" no-title scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.menu.save(form.appointment_date)">OK</v-btn>
							</v-date-picker>
						</v-menu>

						<v-menu ref="menu_appointment_time" v-model="menu_appointment_time"
							:close-on-content-click="false" :nudge-right="40" :return-value.sync="form.appointment_time"
							transition="scale-transition" offset-y max-width="290px" min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.appointment_time" label="Appointment Time"
									prepend-inner-icon="mdi-clock" readonly filled v-on="on"></v-text-field>
							</template>
							<v-time-picker v-if="menu_appointment_time" v-model="form.appointment_time" full-width
								@click:minute="$refs.menu_appointment_time.save(form.appointment_time)"></v-time-picker>
						</v-menu>

					</v-col>
					<v-col cols="12" sm="6">
						<strong class="red">
							<v-checkbox color="red" v-model="form.deleted">
								<template v-slot:label>
									<div><strong class="error--text">Delete</strong></div>
								</template>
							</v-checkbox>
						</strong>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "studentedit",
		props: ["id"],
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user")),
				snackbar: false,
				schoolyears: [],
				registration_types: [],
				studys: [],
				users: [],
				menu: false,
				menu_appointment_time: false,
				menu_govid_exp_date: false,
				form: {
					id: 0,
					schoolyear_id: 0,
					registration_type_id: 0,
					study_id: 0,
					firstname: "",
					surname: "",
					maidenname: "",
					government_id: "",
					govid_exp_date:"",
					// appointment_date: "",
					// appointment_time: "",
					display_name: "",
					deleted: 0,
					deleted_by_id: 0,
					placement: 1
				},
				form2:{
					plaatsing: 1
				}
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			this.getStudys();
			this.getRegistration_types();
			if (this.id > 0) {
				this.getUserRegistration(this.id);
			}
		},
		watch: {
			name() {
				this.errorMessages = '';
			},
			"form.deleted": function () {
				console.log(this.user.id);
				this.form.deleted_by_id = this.user.id;
			},
		},
		methods: {
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all").then(function (response) {
					// console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getRegistration_types: function () {
				var self = this;

				ax.get("/registrationtype/all").then(function (response) {
					// console.log("hello");
					// console.log(response.data);
					self.registration_types = response.data;
				});
			},
			getStudys: function () {
				var self = this;

				ax.get("/study/all").then(function (response) {
					// console.log(response.data);
					self.studys = response.data;
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getUserRegistration: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/student/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					// console.log("UserRegistrations fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				//this.$nextTick(function() {
				console.log(this.form);
				// if (this.form.maidenname.length > 0) {
				// 	this.form.display_name = this.form.firstname + " " + this.form.maidenname + " e/v " + this.form
				// 	.surname;
				// } else {
				// 	this.form.display_name = this.form.firstname + " " + this.form.surname;
				// }

				if (this.form.id > 0) {
					ax.post("/student/update/" + this.form.id, this.form).then(function (
						response
					) {
						console.log(response.data);

						console.log("UserRegistrations update...");

						self.$router.push({
							path: "/schooladmin/studentintake"
						});
					});
				} else {
					ax.post("/student/create", this.form).then(function (response) {
						console.log(response.data);
						self.$router.push({
							path: "/schooladmin/studentintake"
						});
						console.log("UserRegistrations created...");
					});
				}
				//});
			}
		}
	};
</script>
