import StudentRegistration from "../views/schooladmin/StudentRegistration";
import StudentRegistrationAddEdit from "../views/schooladmin/StudentSignUpAddEdit";

export default [{
    path: "/schooladmin/studentregistration",
    components: {
      content: StudentRegistration
    }
  },
  {
    path: "/schooladmin/studentregistration/new",
    components: {
      content: StudentRegistrationAddEdit
    }
  },
  {
    path: "/schooladmin/studentregistration/update/:id",
    components: {
      content: StudentRegistrationAddEdit
    },
    props: {
      content: true
    }
  }
];