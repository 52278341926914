import StudentClassProjection from "../views/schooladmin/StudentClassProjection";
import StudentClassProjectionAddEdit from "../views/schooladmin/StudentClassProjectionAddEdit";

export default [{
    path: "/schooladmin/student_class_projection",
    components: {
      content: StudentClassProjection
    }
  },
  {
    path: "/schooladmin/student_class_projection/new",
    components: {
      content: StudentClassProjectionAddEdit
    }
  },

  {
    path: "/schooladmin/student_class_projection/update/:id",
    components: {
      content: StudentClassProjectionAddEdit
    },
    props: {
      content: true
    }
  }
];