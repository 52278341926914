import OsbodEvent from "../views/schooladmin/OsbodEvent";
import OsbodEventAddEdit from "../views/schooladmin/OsbodEventAddEdit";

export default [{
    path: "/schooladmin/osbodevent",
    components: {
      content: OsbodEvent
    }
  },
  {
    path: "/schooladmin/osbodevent/new",
    components: {
      content: OsbodEventAddEdit
    }
  },

  {
    path: "/schooladmin/osbodevent/update/:id",
    components: {
      content: OsbodEventAddEdit
    },
    props: {
      content: true
    }
  }
];