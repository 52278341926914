<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Student Class</span>
				<span class="pl-2" v-else>Add Student Class</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Instructies voor het plaatsen van student</p>
						<p class="font-weight-regular">1. Kies de her/inschrijving informatie van het te plaatsen student.</p>
						<p class="font-weight-regular">2. Kies student.</p>
						<p class="font-weight-regular">3. Kies de klas waar student ingeplaatst wordt voor het komend schooljaar.</p>
					</v-col>
					<v-col cols="12" sm="6"></v-col>
					<v-col cols="12" sm="6">

						<p class="font-weight-black title">Inchrijving informatie</p>
						<v-select dense :items="schoolyears" item-text="name" item-value="id"
							v-model="form.schoolyear_id" filled label="Schoolyear"></v-select>
						<v-select dense :items="registration_types" item-text="name" item-value="id"
							v-model="form.registration_type_id" filled label="Inschrijving/Herinschrijving"></v-select>
						<v-select dense :items="studys" item-text="name" item-value="id"
							v-model="form.study_id" filled label="Opleiding"></v-select>
					</v-col>

					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Plaatsing informatie</p>
						<v-select dense :items="employeess" item-text="tbl_student.display_name" item-value="tbl_student.id"
							v-model="form.student_id" filled label="Student"></v-select>
						
						<v-select dense :items="mutation_types" item-text="name" item-value="id" v-model="form.class_id"
							filled label="Klas"></v-select>
					</v-col>
					
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>

			<v-btn color="success" @click="editStudent()" depressed>Edit Student</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "mutationedit",
		props: ["id"],
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user")),
				snackbar: false,
				schoolyears: [],
				employeess: [],
				registration_types: [],
				mutation_types: [],
				mutation_months: [],
				mutation_starts: [],
				studys:[],
				users: [],
				form: {
					id: 0,
					schoolyear_id: null,
					student_id: null,
					class_id: null,
					registration_type_id: null,
					study_id: null,
				},
				form_student_cohort: {
					id:0,
					student_id:null,
					placement: null,
					schoolyear_id:null,
					study_id: null,
				},
				formaudit: {
					schoolyear_id: null,
					student_id: null,
					class_id: null,
					user_id: null,
					registration_date: Date.now(),
					registration_type_id: null,
					study_id: null
				},
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			
			this.getRegistration_type();
			this.getStudy();
			if (this.id > 0) {
				
				this.getMutation(this.id);
				this.getEmployeesAll();
			} 
		},
		watch: {
			"form.class_id": function () {
				console.log(this.user.id);
				this.formaudit.user_id = this.user.id;
				
			
			},
			"form.study_id": function (value) {
				
				this.getMutation_type(value);
				if (this.id > 0) {
				this.getEmployeesAll(value);
			}
			else {this.getEmployees(value);} 
			}
		},
		methods: {
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all_cursus").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getRegistration_type: function () {
				var self = this;

				ax.get("/registrationtype/all").then(function (response) {
					console.log(response.data);
					self.registration_types = response.data;
				});
			},
			getEmployees: function () {
				var self = this;

				ax.get("/student_cohort/placement", {
				params: {
					study_id: self.form.study_id,
				}
			}).then(function (response) {
					console.log(response.data);
					self.employeess = response.data;
					console.log(response.data)
					
				});
			},
			getStudent_Placement: function () {
				var self = this;

				ax.get("/student_cohort/placement_student_selected", {
				params: {
					study_id: self.form.study_id,
					schoolyear_id: self.form.schoolyear_id,
					student_id: self.form.student_id
				}
			}).then(function (response) {
					console.log("student_placement");
					console.log(self.form_student_cohort)
					self.form_student_cohort = response.data;
					self.form_student_cohort.placement = 1;
					console.log("student_placement 123");
					console.log(self.form_student_cohort.id)
					console.log(self.form_student_cohort)
					ax.post("/student_cohort/update/" + self.form_student_cohort.id, self.form_student_cohort).then(function (
					response
				) {
					console.log(response.data);

					console.log("Plaatsing Update");

				});
					
				});
			},


			getEmployeesAll: function () {
				var self = this;

				ax.get("/student_cohort/placement_student", {
				params: {
					study_id: self.form.study_id,
					student_id: self.form.student_id,
				}
			}).then(function (response) {
					console.log(response.data);
					self.employeess = response.data;
				});
			},
			getMutation_type: function () {
				var self = this;

				ax.get("/school_class/selected_study", {
				params: {
					study_id: self.form.study_id,
				}
			}).then(function (response) {
					console.log(response.data);
					self.mutation_types = response.data;
				});
			},getStudy: function () {
				var self = this;

				ax.get("/study/all").then(function (response) {
					console.log(response.data);
					self.studys = response.data;
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getMutation: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/student_class/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("Student Class fetched...");
					self.loading = false;
				});
			},
			editStudent(){
				this.$router.push({
					path: "/schooladmin/student/update/" + this.form.student_id
				});
			},

			submitForm() {
				var self = this;
				
				self.formaudit.schoolyear_id= this.form.schoolyear_id;
				self.formaudit.student_id= this.form.student_id;
				self.formaudit.class_id = this.form.class_id;
				self.formaudit.user_id = this.user.id;
				self.formaudit.registration_type_id = this.form.registration_type_id;
				self.formaudit.study_id = this.form.study_id;
				//self.form2.id = self.employeess.id;

				ax.post("/student_class_audit/create", this.formaudit).then(function (response) {
					console.log("1Audits update created...");
					console.log(self.user.id);
					console.log("2Audits update created...");
					console.log(response.data);
				});

				//this.$nextTick(function() {
''
				
				// console.log(this.form);
				if (this.form.id > 0) {

					ax.post("/student_class/update/" + this.form.id, this.form).then(function (
						response
					) {
						console.log(response.data);

						console.log("Mutations update...");

						self.$router.push({
							path: "/schooladmin/student_class_cursus"
						});
					});
				} else {
					this.getStudent_Placement();

					
					ax.post("/student_class/create", this.form).then(function (response) {
						console.log(response.data);
						//update placement
				
						self.$router.push({
							path: "/schooladmin/student_class_cursus"
						});
						console.log("Mutations created...");
					});
				}
				//});
			}
		}
	};
</script>