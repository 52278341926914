import Employee from "../views/schooladmin/Employee";
import EmployeeAddEdit from "../views/schooladmin/EmployeeAddEdit";

export default [{
    path: "/schooladmin/employee",
    components: {
      content: Employee
    }
  },
  {
    path: "/schooladmin/employee/new",
    components: {
      content: EmployeeAddEdit
    }
  },
  {
    path: "/schooladmin/employee/update/:id",
    components: {
      content: EmployeeAddEdit
    },
    props: {
      content: true
    }
  }
];