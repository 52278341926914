<template>

  <div id="levels" class="columns">
    <div class="column">
      <div class="card">
        <header class="card-header">
          <section class="card-header-title">
            <span v-if="form.id > 0">Rol bewerken</span>
            <span v-else>Rol Registreren</span>
          </section>
        </header>
        <div class="card-content">
          <div class="content">

            <section>
              <form id="form">
                <b-field label="Rol" horizontal>
                  <b-input style="width:450px" v-model="form.name"></b-input>
                </b-field>
                 <b-field horizontal>
                  <b-field grouped>
                    <b-field>
                      <a class="button is-light" @click="$router.go(-1);">
                        Terug
                      </a>
                    </b-field>
                    <b-field>
                      <a class="button is-primary" @click="submitForm(id)">
                        Opslaan
                      </a>
                    </b-field>
                  </b-field>
                </b-field> 
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
 <script>
import Axios from "axios";

import { API_ROOT } from "../../config";

console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});




export default {
  name: "subjects",
  props: ["id"],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      schooltypes: [],
      form: {
        id: 0,
        name: ""
      }
    };
  },
  created: function() {

    if (this.id > 0) {
      this.getLevel();
    }
  },
  watch: {},
  computed: {},
  methods: {
    getLevel() {
      var self = this;

      self.loading = true;
      ax.get("/role/" + this.id).then(function(response) {
        self.form = response.data;
        console.log("School fetched...");

        self.loading = false;
      });
    },

    submitForm() {
      var self = this;

      if (self.id > 0) {
        ax
          .post("/role/update/" + self.id, self.form)
          .then(function() {
            console.log(self.user);
            console.log("Updates new level!!");

            self.$router.push({
              path: "/roles"
            });
          });
      } else {
        ax.post("/role/create", this.form).then(function() {
          console.log("Create new school sector!!");

          self.$router.push({
            path: "/roles"
          });
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
