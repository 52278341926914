import EmployeeLoaOvt from "../views/schooladmin/EmployeeLoaOvt";
import EmployeeLoaOvtAddEdit from "../views/schooladmin/EmployeeLoaOvtAddEdit";

export default [{
    path: "/schooladmin/employee_loa_ovt",
    components: {
      content: EmployeeLoaOvt
    }
  },
  {
    path: "/schooladmin/employee_loa_ovt/new",
    components: {
      content: EmployeeLoaOvtAddEdit
    }
  },

  {
    path: "/schooladmin/employee_loa_ovt/update/:id",
    components: {
      content: EmployeeLoaOvtAddEdit
    },
    props: {
      content: true
    }
  }
];