<template>

	<v-app>
		<router-view name="main"></router-view>
		<router-view name="login"></router-view>
		<router-view name="aanmelden"></router-view>
		<router-view name="bedankt"></router-view>
	</v-app>
</template>

<script>


export default {
	name: "App",
	data: () => ({
		//
	})
};
</script>
