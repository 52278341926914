import FinanceStudentPlacement from "../views/schooladmin/FinanceStudentPlacement";
import FinanceStudentPlacementAddEdit from "../views/schooladmin/FinanceStudentPlacementAddEdit";

export default [{
    path: "/schooladmin/financestudentplacement",
    components: {
      content: FinanceStudentPlacement
    }
  },
  {
    path: "/schooladmin/financestudentplacement/new",
    components: {
      content: FinanceStudentPlacementAddEdit
    }
  },
  {
    path: "/schooladmin/financestudentplacement/update/:id",
    components: {
      content: FinanceStudentPlacementAddEdit
    },
    props: {
      content: true
    }
  }
];