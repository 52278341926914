import AdminTask from "../views/schooladmin/AdminTask";
import AdminTaskAddEdit from "../views/schooladmin/AdminTaskAddEdit";

export default [{
    path: "/schooladmin/admin_task",
    components: {
      content: AdminTask
    }
  },
  {
    path: "/schooladmin/admin_task/new",
    components: {
      content: AdminTaskAddEdit
    }
  },
  {
    path: "/schooladmin/admin_task/update/:id",
    components: {
      content: AdminTaskAddEdit
    },
    props: {
      content: true
    }
  }
];