<template>
  <div id="admin_user" class="columns">
    <div class="column">
      <div class="card">
        <header class="card-header">
          <section class="card-header-title">
            Gebruikers Administratie Master
          </section>
        </header>

        <div class="section" style="background-color:#eeeeee;padding: 12px;">
          <div class="columns">
            <div class="column is-12">
              <nav class="level">
                <div class="level-left">
                  <div class="level-item">
                  </div>
                  <div class="level-item">
                    <b-field>
                      <a class="button is-primary" @click="setUser()">
                        <b-icon icon="account-plus"></b-icon>
                        <span>Nieuwe gebruiker</span>
                      </a>
                    </b-field>
                  </div>
                  <div class="level-item">
                    <b-field horizontal label="">
                      <b-select placeholder="Soort gebruiker" v-model="selected_role">
                        <option :value="0" :key="0">Allemaal</option>
                        <option v-for="option in roles" :value="option.key" :key="option.key">
                          {{ option.label }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <!-- <div class="card-content"> -->
        <div class="section">
          <b-table :data="users" :bordered="false" :narrowed="true" :striped="true" :loading="loading" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :default-sort-direction="defaultSortDirection">
            <template slot-scope="props">
              <b-table-column width="75">
                <a class="button icon is-text" @click="handleClick(props.row.id)">
                  <b-icon icon="lead-pencil"></b-icon>

                </a>
              </b-table-column>
              <b-table-column sortable field="role_id" label="Soort">
                {{props.row.tbl_sys_role.name}}
              </b-table-column>
              <b-table-column sortable field="firstname " label="Voornaam">
                {{props.row.firstname}}
              </b-table-column>
              <b-table-column sortable field="surname" label="Achternaam">
                {{props.row.surname}}
              </b-table-column>
              <b-table-column sortable field="username " label="Gebruiker">
                {{ props.row.username }}
              </b-table-column>
            </template>

            <!-- <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon-pack="far" icon="question-circle" size="is-large">
                    </b-icon>
                  </p>
                  <p>Nothing here.</p>
                </div>
              </section>
            </template> -->
          </b-table>
        </div>

      </div>
    </div>
  </div>

</template>
 
 <script>
import Axios from "axios";
import _ from "lodash";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
  name: "admin_user",
  data() {
    return {
      users: [],
      roles: [],
      selected_role: null,
      // school_id: localStorage.getItem("school_id"),
      user: JSON.parse(localStorage.getItem("user")),
      isEmpty: false,
      loading: false,
      isPaginated: true,
      isPaginationSimple: false,
      defaultSortDirection: "asc",
      currentPage: 1,
      perPage: 12
    };
  },
  created: function() {
    this.getUser(0);
    this.getRoles();
  },
  watch: {
    selected_role: function(val) {
      this.getUser(val);
    }
  },
  computed: {
    // title() {
    //   return _.capitalize(this.type);
    // }
  },
  methods: {
    handleClick(id) {
      this.$router.push({
        path: "/users/update/" + id
      });
    },
    getRoles: function() {
      var self = this;
      //todo:
      ax.get("/roles").then(function(response) {
        console.log(response.data.data);

        self.roles = [];
        for (var i = 0; i < response.data.length; i++) {
          var element = response.data[i];
          self.roles.push({
            key: element.id,
            label: element.name,
            disbaled: false
          });
        }

        console.log(self.roles);
      });
    },
    getUser(filter) {
      console.log(this.user);
      var self = this;
      self.loading = true;

      ax.get("/users").then(function(response) {
        if (filter > 0) {
          self.users = _.filter(response.data, function(o) {
            return o.role_id == filter;
          });
        } else {
          self.users = response.data;
        }

        console.log("User fetched...");
        console.log("Fetch School Users");

        self.loading = false;
      });
    },
    setUser() {
      this.$router.push({
        path: "/users/new"
      });
    }
  }
};
</script>