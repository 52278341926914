export const API_ROOT = {
    osbod_salary: (process.env.NODE_ENV === 'production') ?
        // 'https://api.ministerie-owcs.cw/osbod_salary_api/prd/api' : 'http://localhost:9006/osbod_salary/prd/api'
        // 'https://api.ministerie-owcs.cw/osbod_salary/prd/api': 'http://localhost:9006/osbod_salary/prd/api'
        'https://api.ministerie-owcs.cw/osbod_salary/prd/api': 'http://localhost:9006/osbod_salary/prd/api'
        
        // 'https://190.123.21.3/osbod_salary/prd/api': 'https://190.123.21.3/osbod_salary/prd/api'
        //  'https://api.ministerie-owcs.cw/osbod_salary/prd/api': 'https://api.ministerie-owcs.cw/osbod_salary/prd/api'
    //    'http://api.osbod.org:9006/osbod_salary/prd/api': 'http://api.osbod.org:9006/osbod_salary/prd/api'

}
