import Axios from "axios";
import {
  API_ROOT
} from "../../config";

console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
  baseURL: API_ROOT.osbod_salary,
  timeout: 60000
});
export default {
  state: {
    access: false,
    user: {}
  },
  mutations: {
    LOGIN(state, obj) {

      state.access = obj.success;
      state.user = obj.data;
      state.obj = obj;

      console.log(obj);

      localStorage.setItem('user', JSON.stringify(obj.data));
      localStorage.setItem('access', obj.success);



    },
    LOGOUT(state) {
      state.access = 0;
      state.user = null;
      localStorage.clear();
    }
  },
  getters: {
    getLoggedin(state) {
      return state.access;
    },
    getUser(state) {
      return state.user;
    }
  },
  actions: {
    logout(context) {
      context.commit('LOGOUT');
    },
    login(context, obj) {

      ax.post('/public/login', {
        username: obj.username,
        password: obj.password
      }).then(function (response) {

        var obj = response.data;
        console.log(obj);

        if (obj.success) {
          context.commit('LOGIN', obj);
        } else {
          context.commit('LOGOUT');
        }
      });

    }
  }
}