<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Docent OSBOD Absentie Registratie</span>
				<span class="pl-2" v-else>OSBOD Docent Absentie Registratie</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					<v-col cols="12" sm="3">
						<p class="font-weight-black title">Instructies voor Absentie registratie</p>
						<p class="font-weight-regular">1. Vul je school email adress en configuratie code in.</p>
						<p class="font-weight-regular">2. Druk op de knop "Gegevens Ophalen" om je docent gegevens op te halen.</p>
						<p class="font-weight-regular">3. Vul absentie gegevens in.</p>
						<p class="font-weight-regular">4. Druk op de knop "Afmelden" om je absentie te bevestigen.</p>
					</v-col>

					<v-col cols="12" sm="3">
						
						<p class="font-weight-black title">Registratie gegevens</p>
						<v-radio-group v-model="form_module.my_filter" label="Ik wens mij af te melden voor:*" required :rules="[rules.required]"
										:error-messages="errorMessages" >
								<v-radio
									label="Cursus"
									value="1"
								></v-radio>
								<v-radio
									label="Regulier Opleiding"
									value="2"
								></v-radio>
								</v-radio-group>
						<v-text-field dense v-model="formsearch.osbod_email" label="OSBOD email" filled></v-text-field>
						<v-text-field dense v-model="formsearch.registration_password" label="Configuratie Code" filled type="password"></v-text-field>
						
						
						<v-btn color="primary" @click="searchForm()" depressed>Gegevens Ophalen</v-btn>
						
					</v-col>
					<v-col cols="12" sm="6">
						
					</v-col>
					<v-col cols="12" sm="3">
						<p class="font-weight-black title">Docent gegevens</p>
						<v-text-field dense v-model="formsearch.registration_date" label="Registratie datum"  filled readonly></v-text-field>
						<v-text-field dense v-model="form.osbod_email" label="Osbod-email" value="osbod_email" filled readonly ></v-text-field>
						<v-text-field dense v-model="form.teacher_name" label="Docent" value="teacher_name" filled readonly></v-text-field>
						

					</v-col>


					<v-col cols="12" sm="3">
					
						<p class="font-weight-black title">Absentie</p>
						
						
						
						<v-select dense :items="schoolyears" item-text="name" item-value="id"
							v-model="form.schoolyear_id" filled label="Schoolyear*"
							:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
							<v-select dense :items="absent_types" item-text="name" item-value="id"
							v-model="form.absent_type_id" filled label="Absentie*"
							:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
						
						<v-select dense :items="periodes" item-text="name" item-value="id"
							v-model="form.my_periode_id" filled label="Periode*"
							:rules="[rules.required]" :error-messages="errorMessages"  return-object required></v-select>
						
						<v-menu ref="menu_absent_start" v-model="menu_absent_start"
										:close-on-content-click="false" :return-value.sync="form.absent_start"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.absent_start" label="Absent van*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.absent_start" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_absent_start = false">Annuleren</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_absent_start.save(form.absent_start)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
						<v-menu ref="menu_absent_end" v-model="menu_absent_end"
										:close-on-content-click="false" :return-value.sync="form.absent_end"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.absent_end" label="Absent tot en met*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.absent_end" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_absent_end = false">Annuleren</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_absent_end.save(form.absent_end)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
						<!-- <v-select dense :items="employeess" item-text="display_name" item-value="id"
							v-model="form.student_id" filled label="Student" readonly ></v-select> -->
						<!-- <v-select dense :items="classs" item-text="name" item-value="id" v-model="form.class_id"
							filled label="Klas" readonly></v-select>
						<v-checkbox v-model="form.registration_complete"
										label="U verklaart dat alle herinschrijvingsinformatie volledig zijn ingevuld*"
										required :rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
						<v-checkbox v-model="form.registration_agree" required
										label="U verklaart akkoord te gaan met de inschrijf en betalingsvoorwaarden.*"
										:rules="[rules.required]" :error-messages="errorMessages"></v-checkbox> -->
					
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Modules*</p>
						<v-select multiple v-model="form_module.module_id"  dense :items="subjects" item-text="subject_module" item-value="id" filled label="Module*" :rules="[rules.required]" :error-messages="errorMessages" return-object required></v-select>
					</v-col>
				</v-row>
			</v-form>
			<v-row><v-col cols="12" sm="6"></v-col><v-col cols="12" sm="6"><v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Afmelden</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions></v-col></v-row>
			
		</v-card-text>
		

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "mutationedit",
		props: ["id"],
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user")),
				errorMessages: '',
				formHasErrors: false,
				snackbar: false,
				schoolyears: [],
				classs: [],
				employeess: [],
				absent_types: [],
				mutation_types: [],
				mutation_months: [],
				mutation_starts: [],
				periodes:[],
				subjects:[],
				my_absents:[],
				users: [],
				student_class_projections:[],
				menu_absent_start: false,
				menu_absent_end: false,
				form: {
					id: 0,
					registration_date: null,
					absent_start: null,
					absent_end: null,
					schoolyear_id: 4,
					absent_type_id: null,
					periode_id: null,
					osbod_email: null,
					teacher_name: null,
					employee_id: null,
					module_id:null,
					my_periode_id: null,

				},
				formsearch:{
					osbod_email:null,
					registration_password: null,
					id: 0,
					registration_date: Date(),
				},
				form_teacher_absent: {
					id: 0,
					registration_date: null,
					employee_id: null,
					absent_start: null,
					absent_end: null,
					schoolyear_id: null,
					absent_type_id: null,
					periode_id: null,
					module_id: null,
					email_coordinator: null,
					email_module: null,
					email_periode: null,
					email_docent_naam: null,
					email_recipient: null,
					email_absent_start: null,
					email_absent_end: null,
				},
				form_module: {
					id: 0,
					absent_id:null,
					module_id: null,
					my_filter: null,
				},
				

				rules: {
					required: value => !!value || 'Dit veld is verplicht!.'
				},
				
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			this.getAbsent_type();
			this.getPeriode();
			if (this.id > 0) {
				this.getMutation(this.id);
			
			} 
		},
		watch: {
			"form.my_periode_id":function(value){
				if (this.form_module.my_filter == 1){
					this.getTeacherModuleCursus(value);
					console.log('Cursus');
				}else{
					this.getTeacherModule(value);
					console.log('Regulier');
				}
				
				
			},
			"form_module.my_filter":function(){
				this.form.my_periode_id = null;
			},
			"form_module.module_id":function(){
				// console.log(this.form_module.module_id.id);
				// console.log(this.form_module.module_id.my_email_coordinator);
					for (var i = 0; i < this.form_module.module_id.length; i++) {
					// this.form_module.module_id[i].my_email_coordinator;
					console.log(i);
					}
					
			}

		},
		methods: {
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getAbsent_type: function () {
				var self = this;

				ax.get("/absent_type/all").then(function (response) {
					console.log(response.data);
					self.absent_types = response.data;
				});
			},
			getPeriode: function() {
			var self = this;

			ax.get("/periode/all").then(function(response) {
				console.log("Periode Fill")
				console.log(response.data);
				self.periodes = response.data;
			});
		},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					
					self.users = response.data;
				});
			},
			getMutation: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/teacher_absent/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("Student Class fetched...");
					self.loading = false;
				});
			},
			searchForm: function() {
				var self = this;

				ax.get("/employee/searchform/", {
					params: {
						osbod_email: self.formsearch.osbod_email,
						registration_password: self.formsearch.registration_password
					}
				}).then(function (response) {
					console.log("Subject Before Fill")
					// console.log(self.student_class_projections)
					//self.form = response.data;
					self.form = response.data;
					console.log("ID =?")
					// console.log(self.student_class_projections);
					self.form.employee_id = self.form.id;
					self.form.id = null;
					console.log(self.form.id);

					self.loading = true;
					
				});

			},
			getTeacherModule: function() {
				var self = this;

				ax.get("/teacher_subject_class/all_teacher_module/", {
					params: {
						employee_id: self.form.employee_id,
						periode_id: self.form.my_periode_id.id
					}
				}).then(function (response) {
					console.log("Subject Before Fill")

					self.subjects = response.data;
					console.log("Subject After Fill")
					console.log(self.form.my_periode_id.name);

					self.loading = true;
					
				});

			},
			getTeacherModuleCursus: function() {
				var self = this;

				ax.get("/teacher_subject_class/all_teacher_module_cursus/", {
					params: {
						employee_id: self.form.employee_id,
						periode_id: self.form.my_periode_id.id
					}
				}).then(function (response) {
					console.log("Subject Before Fill Cursus")

					self.subjects = response.data;
					console.log("Subject After Fill Cursus")
					console.log(self.form.my_periode_id.name);

					self.loading = true;
					
				});

			},
			// getTeacherAbsent: function() {
			// 	var self = this;

			// 	ax.get("/teacher_absent/my_teacher_absent/", {
			// 		params: {
			// 			employee_id: self.form.employee_id,
			// 			registration_date: self.formsearch.registration_date,
			// 		}
			// 	}).then(function (response) {
			// 		// self.my_absents = response.data;
			// 		console.log(self.form.employee_id);
			// 		console.log(self.formsearch.registration_date);					
					

			// 		self.form_teacher_absent = response.data;
			// 		console.log(response.data);
			// 		console.log('self.student_class_projections');
			// 		this.form_teacher_absent.teacher_absent_id = self.form_teacher_absent.id;
			// 		self.form_teacher_absent.id = null;
			// 		console.log(self.form_teacher_absent.id);
			// 		self.loading = true;
					
			// 	});
			
			// },

			setTeacherAbsent: async function() {
				//Insert subject absent for teacher ----
					this.form_teacher_absent.registration_date = this.form.registration_date;
					this.form_teacher_absent.employee_id = this.form.employee_id;
					this.form_teacher_absent.absent_start = this.form.absent_start;
					this.form_teacher_absent.absent_end = this.form.absent_end;
					this.form_teacher_absent.schoolyear_id = this.form.schoolyear_id;
					this.form_teacher_absent.absent_type_id = this.form.absent_type_id;
					this.form_teacher_absent.periode_id = this.form.my_periode_id.id;
					this.form_teacher_absent.email_recipient = this.formsearch.osbod_email;
					this.form_teacher_absent.email_coordinator = '123';
					
					this.form_teacher_absent.email_periode = this.form.my_periode_id.name;
					this.form_teacher_absent.email_docent_naam = this.form.teacher_name;

					this.form_teacher_absent.email_absent_start =  moment(this.form.absent_start).format('DD-MM-YYYY');
					this.form_teacher_absent.email_absent_end = moment(this.form.absent_end).format('DD-MM-YYYY');
					
					console.log(this.form_module.module_id.length);
					
					
					for (let i = 0; i < this.form_module.module_id.length; i++) {
						
						console.log(i);
						console.log(this.form_module.module_id[i].id);
						this.form_teacher_absent.teacher_module_id = this.form_module.module_id[i].id;
						this.form_teacher_absent.email_module = this.form_module.module_id[i].subject_module;
						this.form_teacher_absent.email_coordinator = this.form_module.module_id[i].my_email_coordinator;
						console.log(this.form_teacher_absent.email_coordinator);
					// this.getTeacherAbsent();

					// this.form_teacher_absent.teacher_module_id = this.form_module.module_id[i].id;
					// this.form_teacher_absent.email_module = i;
					await ax.post("/teacher_absent_overtake/create", this.form_teacher_absent).then(function () {
						console.log("Saved");
						// console.log(self.form_module.module_id[i].id);
						
						// console.log(self.form_teacher_absent.teacher_module_id);
						
						
						
						
					});
					
					
				}
						
			
			},
			submitForm() {
				var self = this;
				this.formHasErrors = false;
				
				if (this.form.absent_start == null || this.form.absent_start == "") {
					this.formHasErrors = true;
					this.form.absent_start = null;
				}
				if (this.form.absent_end == null || this.form.absent_end == "") {
					this.formHasErrors = true;
					this.form.absent_end = null;
				}
				if (this.form_module.module_id == null || this.form_module.module_id == "") {
					this.formHasErrors = true;
					this.form_module.module_id = "";
				}
				if (this.form.absent_type_id == null || this.form.absent_type_id == "") {
					this.formHasErrors = true;
					this.form.absent_type_id = "";
				}
				if (this.form.my_periode_id == null || this.form.my_periode_id == "") {
					this.formHasErrors = true;
					this.form.my_periode_id = "";
				}
				if (this.form.schoolyear_id == null || this.form.schoolyear_id == "") {
					this.formHasErrors = true;
					this.form.schoolyear_id = "";
				}
				if (this.form_module.my_filter == null || this.form_module.my_filter == "") {
					this.formHasErrors = true;
					this.form_module.my_filter = "";
				}
				console.log(this.form);
				if (this.formHasErrors == false) {
				if (this.form.id > 0) {
				// console.log(this.form_teacher_absent.module_id.length);
				
				// console.log("Student Chort...");
				// console.log(this.form_cohort.student_id);

				ax.post("/teacher_absent/update/" + this.form.id, this.form).then(function (
						response
					) {
						console.log(response.data);

						console.log("Mutations update...");

						self.$router.push({
							path: "/bedanktherinschrijving"
						});
					});
				} else {
					this.form.registration_date = self.formsearch.registration_date;
					this.form.periode_id = this.form.my_periode_id.id;
					ax.post("/teacher_absent/create", this.form).then(function (response) {
						console.log(response.data);
						console.log("Mutations created...");
					});

				this.setTeacherAbsent();
				// //Insert subject absent for teacher ----
				// 	this.form_teacher_absent.registration_date = this.form.registration_date;
				// 	this.form_teacher_absent.employee_id = this.form.employee_id;
				// 	this.form_teacher_absent.absent_start = this.form.absent_start;
				// 	this.form_teacher_absent.absent_end = this.form.absent_end;
				// 	this.form_teacher_absent.schoolyear_id = this.form.schoolyear_id;
				// 	this.form_teacher_absent.absent_type_id = this.form.absent_type_id;
				// 	this.form_teacher_absent.periode_id = this.form.my_periode_id.id;
				// 	this.form_teacher_absent.email_coordinator = '123';
					
				// 	this.form_teacher_absent.email_periode = this.form.my_periode_id.name;
				// 	this.form_teacher_absent.email_docent_naam = this.form.teacher_name;
					
					
				// 	console.log(this.form_module.module_id.length);
				// 	for (let i = 0; i < this.form_module.module_id.length; i++) {
						
				// 		console.log(i);
				// 		console.log(this.form_module.module_id[i].id);
				// 		this.form_teacher_absent.teacher_module_id = this.form_module.module_id[i].id;
				// 		console.log(this.form_teacher_absent.teacher_module_id);
				// 	// this.getTeacherAbsent();

				// 	// this.form_teacher_absent.teacher_module_id = this.form_module.module_id[i].id;
				// 	// this.form_teacher_absent.email_module = i;
				// 		ax.post("/teacher_absent_overtake/create", this.form_teacher_absent).then(function () {
				// 		console.log("Saved");
				// 		// console.log(self.form_module.module_id[i].id);
						
				// 		// console.log(self.form_teacher_absent.teacher_module_id);
						
						
						
					self.$router.push({
						path: "/bedanktteacherabsent"
						});
						console.log("Teacher absentie created...");
					
					
						
				// }
					//------
				}
				}
				//});
			}
		}
	};
</script>