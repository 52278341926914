<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">OSBOD Student Plaatsing - Inschrijving</span>
				<span class="pl-2" v-else>Inschrijfformulier OSBOD 2022-2023</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-stepper v-model="e1">
			<v-stepper-header>
				<v-stepper-step :complete="e1 > 1" step="1">
					<p class="font-weight-black title">Aanmelding</p>
				</v-stepper-step>
			</v-stepper-header>
			<v-form lazy-validation>
				<v-stepper-items>
					<v-stepper-content step="1">
						<v-card>
							<v-row>
								<v-col cols="12" sm="6">
						<p class="font-weight-black title">Student Info</p>
						

									<v-text-field dense v-model="form.maidenname" label="Gehuwde naam" filled readonly>
									</v-text-field>
									<v-text-field dense v-model="form.surname" label="Achternaam*"  hint="Indien getrouwd, meisjesnaam invullen" filled
										:rules="[rules.required]" :error-messages="errorMessages" required readonly
										>
									</v-text-field>
									<v-text-field dense v-model="form.firstname" label="Voornaam (voluit)*" filled readonly
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.middlename" label="Middelnaam (voluit)" filled readonly
										>
									</v-text-field>
									<v-select dense :items="genders" item-text="name" item-value="id" readonly
										v-model="form.gender_id" filled label="Geslacht*" :rules="[rules.required]"
										:error-messages="errorMessages" required></v-select>
										<v-text-field dense v-model="form.government_id" label="Geldige I.D. Nr.*" filled readonly
										:counter=10 :rules="[rules.required, rules.governmentid, rules.number_only]"
										:error-messages="errorMessages" required>
									</v-text-field>

					</v-col>
							<v-col cols="12" sm="6">
								<p class="font-weight-black title">Plaatsing</p>
								
									<v-select dense :items="sectors" item-text="name" item-value="id" 
										v-model="form.sector_id" filled label="Sector*" required :rules="[rules.required]"
										:error-messages="errorMessages" readonly></v-select>

									<v-select dense :items="levels" item-text="tbl_sys_level.name" item-value="tbl_sys_level.id" 
										v-model="form.level_id" filled label="Niveau*" required :rules="[rules.required]"
										:error-messages="errorMessages" readonly></v-select>
									
									<v-select dense :items="studys" item-text="name" item-value="id"  readonly
										v-model="form.study_id" filled label="Opleiding*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>
										<v-select dense :items="studyclasses" item-text="name" item-value="id" 
										v-model="form.cordinator_class_id" filled label="Klas*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>
									
									<v-textarea dense v-model="form.cordinator_comments" label="Opmerking(en)" filled>
									</v-textarea>
									
							</v-col>
						
						</v-row>
						</v-card>
						<v-btn color="success" @click="submitForm(id)" depressed>Opslaan</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Annuleren</v-btn>
					</v-stepper-content>
									</v-stepper-items>
			</v-form>
		</v-stepper>



		<v-card-text>

			<v-row>


			</v-row>

		</v-card-text>
		<!-- <v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Opslaan</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Annuleren</v-btn>
		</v-card-actions> -->

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			U dient alle verplichte velden in te vullen! Druk op de terug knop om de verplichte velden alsnog in te
			vullen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "usersignupadd",
		props: ["id"],
		data() {
			return {
				cursector: 0,
				curlevel: 0,
				curstudy: 0,
				e1: 1,
				valid: true,
				errorMessages: '',
				formHasErrors: false,
				user: JSON.parse(localStorage.getItem("user")),
				snackbar: false,
				schoolyears: [],
				studys: [],
				users: [],
				registered_statuss:[],
				genders: [],
				birthplaces: [],
				nationalitys: [],
				maritial_statuss: [],
				denominations: [],
				sectors: [],
				levels: [],
				selectedstudycost: [],
				studyclasses: [],
				menu: false,
				menu_birthdate: false,
				menu_govid_exp_date: false,
				menu_appointment_time: false,
				menu_appointment_date: false,
				rules: {
					required: value => !!value || 'Dit veld is verplicht!.',
					email: value => {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						return pattern.test(value) || 'Onvolledige e-mail.'
					},
					governmentid: value => (value && value.length == 10) ||
						'Identiteitsnummer dient 10 nummers te zijn.',
					telephone_number: value => (value && value.length == 7) ||
						'Telefoonnummer dient 7 nummers te zijn.',
					number_only: value => {
						const pattern = /^[0-9]+$/
						return pattern.test(value) || 'Alleen nummers is toegestaan'
					}
				},
				form: {
					id: 0,
					role_id: 1,
					schoolyear_id: 3,
					registration_type_id:1,
					registered_status_id:null,
					comments: "",
					sector_id: null,
					old_student: null,
					level_id: null,
					study_id: null,
					firstname: "",
					surname: "",
					maidenname: "",
					government_id: "",
					govid_exp_date: "",
					birthdate: "",
					birthplace_id: null,
					gender_id: null,
					nationality_id: null,
					maritial_status_id: null,
					denomination_id: null,
					address: "",
					neighboorhood:"",
					email: "",
					tel_home: "",
					tel_mobile: "",
					tel_work: "",
					registration_agree: "",
					registration_complete: "",
					registration_cost: null,
					study_cost: null,
					material_cost: "",
					display_name: "",
					edu_fo: 0,
					edu_fo_level: null,
					edu_fo_year: null,
					edu_vo: 0,
					edu_vo_level: null,
					edu_vo_year: null,
					edu_vsbo: 0,
					edu_vsbo_level: null,
					edu_vsbo_year: null,
					edu_sbo: 0,
					edu_sbo_level: null,
					edu_sbo_year: null,
					edu_other: 0,
					edu_other_level: null,
					edu_other_year: null,
					emergency_contact_1: null,
					emergency_phone_1: null,
					emergency_contact_2: null,
					emergency_phone_2: null,
					appointment_date: "",
					appointment_time: "",
					deleted_by_id: null,
					cordinator_class_id:null,
					cordinator_comments:"",
				}
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			//this.getStudys();
			this.getGenders();
			this.getBirthplaces();
			this.getNationalitys();
			this.getMaritialstatus();
			this.getDenomination();
			this.getRegistered_status();
			this.getSector();
			if (this.id > 0) {
				this.getUserRegistration(this.id);
			}
		},
		watch: {
			name() {
				this.errorMessages = '';
			},
			"form.sector_id": function(value){
				
				if (this.cursector != this.form.sector_id && this.cursector > 0){
					this.form.level_id = null;
					this.form.registration_cost = null;
					this.form.study_cost = null;
					this.form.study_id = null;
					
				}

				this.getLevel(value);
				this.cursector = this.form.sector_id
				// this.getClass1(value);	
			},
			"form.level_id": function(value){
				
				if (this.curlevel != this.form.level_id && this.curlevel > 0){

				this.form.registration_cost = null;
				this.form.study_cost = null;
				this.form.study_id = null;
			}console.log('first',this.curlevel);
				this.getStudy(value);
				this.curlevel = this.form.level_id
				console.log('After',this.curlevel);
				// this.getClass1(value);	
			},
			"form.study_id": function(value){
				
				if (this.curstudy != this.form.study_id && this.curstudy > 0){

				this.form.registration_cost = null;
				this.form.study_cost = null;
				this.getSelectedStudy(value);
				this.getClass(value);
				}
				this.curstudy = this.form.study_id
				this.getSelectedStudy(value);
				this.getClass(value);
				// this.getClass1(value);
				
			},
			// "form.study_id": function (value) {
			// 	console.log("Get selected cost");
			// 	//console.log(this.cost_center_id_obj);
			// 	console.log(value);

			// 	this.getSelectedStudy(value);

			// },
		},
		methods: {
			validate() {
				this.$refs.form.validate()
			},
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getBirthplaces: function () {
				var self = this;

				ax.get("/birthplace/all").then(function (response) {
					console.log(response.data);
					self.birthplaces = response.data;
				});
			},
			getNationalitys: function () {
				var self = this;

				ax.get("/nationality/all").then(function (response) {
					console.log(response.data);
					self.nationalitys = response.data;
				});
			},
			getMaritialstatus: function () {
				var self = this;

				ax.get("/maritial_status/all").then(function (response) {
					console.log(response.data);
					self.maritial_statuss = response.data;
				});
			},
			getDenomination: function () {
				var self = this;

				ax.get("/denomination/all").then(function (response) {
					console.log(response.data);
					self.denominations = response.data;
				});
			},
			getSector: function () {
				var self = this;

				ax.get("/sector/all").then(function (response) {
					console.log(response.data);
					
					
					self.sectors = response.data;
				});
			},
			getLevel: function() {
			var self = this;
			ax.get("/study/selected_sector/", {
				params: {
					sector_id: self.form.sector_id,
				}
			}).then(function(response) {
				
				self.levels = response.data;
				
				console.log("After Fill")
				
			});
			
		
		},
			getStudy: function () {
				var self = this;

				ax.get("/study/selected_sector_level_admin/", {
				params: {
					sector_id: self.form.sector_id,
					level_id: self.form.level_id,
				}
			}).then(function(response) {
				// self.form.registration_cost = null;
				// self.form.study_cost = null;
				self.studys = response.data;
				
				console.log("After Fill")
				
			});
			},
			getSelectedStudy: function () {
				var self = this;
				ax.get("/study/single/" + this.form.study_id).then(function (response) {
					console.log("getStudy Costs")
					self.selectedstudycost = response.data;
					self.form.registration_cost = response.data.registration_cost;
					self.form.study_cost = response.data.study_cost;
					console.log(response.data);
				});
			},
			getClass: function () {
				var self = this;

				ax.get("/school_class/selected_study", {
				params: {
					study_id: self.form.study_id,
				}
			}).then(function (response) {
					console.log(response.data);
					self.studyclasses = response.data;
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getGenders: function () {
				var self = this;

				ax.get("/gender/all").then(function (response) {
					console.log(response.data);
					self.genders = response.data;
				});
			},
			getRegistered_status: function () {
				var self = this;

				ax.get("/registered_status/all").then(function (response) {
					console.log(response.data);
					self.registered_statuss = response.data;
				});
			},
			getUserRegistration: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/userregistration/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("UserRegistrations fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				this.formHasErrors = false;

				if (this.form.cordinator_class_id == null || this.form.cordinator_class_id == "") {
					this.formHasErrors = true;
					this.form.cordinator_class_id = "";
				}
				
				//this.$nextTick(function() {
				console.log(this.form);
				if (this.form.maidenname.length > 0) {
					this.form.display_name = this.form.firstname + " " + this.form.surname + " e/v " + this.form
						.maidenname;
				} else {
					this.form.display_name = this.form.firstname + " " + this.form.surname;
				}
				if (this.formHasErrors == false) {
					if (this.form.id > 0) {
						this.form.deleted_by_id = this.user.id;
						this.form.registered_status_id = 3;
						ax.post("/userregistration/update/" + this.form.id, this.form).then(function (
							response
						) {
							console.log(response.data);

							console.log("UserRegistrations update...");

							self.$router.push({
								path: "/schooladmin/studentregistrationcoordinator"
							});
						});
					} else {
						console.log("before post");
						ax.post("/userregistration/create", this.form).then(function (response) {
							console.log("almost there");
							console.log(response.data);
							self.$router.push({
								path: "/schooladmin/studentregistrationcoordinator"
							});
							console.log("UserRegistrations created...");
						});


					}
				} else {
					this.form
				}
				//});
			},
			submitFormTransition() {
				var self = this;
				this.formHasErrors = false;
				
				if (this.form.surname == null || this.form.surname == "") {
					this.formHasErrors = true;
					this.form.surname = null;
				}
				if (this.form.firstname == null || this.form.firstname == "") {
					this.formHasErrors = true;
					this.form.firstname = null;
				}
				if (this.form.birthdate == null || this.form.birthdate == "") {
					this.formHasErrors = true;
					this.form.birthdate = null;
				}
				if (this.form.govid_exp_date == null || this.form.govid_exp_date == "") {
					this.formHasErrors = true;
					this.form.govid_exp_date = null;
				}
				if (this.form.birthplace_id == null || this.form.birthplace_id == "") {
					this.formHasErrors = true;
					this.form.birthplace_id = "";
				}
				if (this.form.government_id == null || this.form.government_id == "" || this.form.government_id.length >10  ) {
					this.formHasErrors = true;
					this.form.government_id = null;
				}
				if (this.form.tel_mobile == null || this.form.tel_mobile == ""|| this.form.tel_mobile.length >7) {
					this.formHasErrors = true;
					this.form.tel_mobile = null;
				}
				if (this.form.email == null || this.form.email == "") {
					this.formHasErrors = true;
					this.form.email = null;
				}
				if (this.form.address == null || this.form.address == "") {
					this.formHasErrors = true;
					this.form.address = null;
				}
				if (this.form.neighboorhood == null || this.form.neighboorhood == "") {
					this.formHasErrors = true;
					this.form.neighboorhood = null;
				}
				if (this.form.gender_id == null || this.form.gender_id == "") {
					this.formHasErrors = true;
					this.form.gender_id = "";
				}
				if (this.form.denomination_id == null || this.form.denomination_id == "") {
					this.formHasErrors = true;
					this.form.denomination_id = "";
				}
				if (this.form.nationality_id == null || this.form.nationality_id == "") {
					this.formHasErrors = true;
					this.form.nationality_id = "";
				}
				if (this.form.cordinator_class_id == null || this.form.cordinator_class_id == "") {
					this.formHasErrors = true;
					this.form.cordinator_class_id = "";
				}
				if (this.form.maritial_status_id == null || this.form.maritial_status_id == "") {
					this.formHasErrors = true;
					this.form.maritial_status_id = "";
				}
				
				// if (this.form.study_id == null || this.form.study_id == "") {
				// 	this.formHasErrors = true;
				// 	this.form.study_id = "";
				// }
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			},
			submitFormTransitionStudie(){
				var self = this;
				this.formHasErrors = false;

					if (this.form.study_id == null || this.form.study_id == "") {
					this.formHasErrors = true;
					this.form.study_id = "";
				}
				if (this.form.old_student == null || this.form.old_student == "") {
					this.formHasErrors = true;
					this.form.old_student = "";
				}
					if (this.form.sector_id == null || this.form.sector_id == "") {
					this.formHasErrors = true;
					this.form.sector_id = "";
				}
					if (this.form.level_id == null || this.form.level_id == "") {
					this.formHasErrors = true;
					this.form.level_id = "";
				}
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			},
			submitFormTransitionEmergencyContact(){
				var self = this;
				this.formHasErrors = false;

					if (this.form.emergency_contact_1 == null || this.form.emergency_contact_1 == "") {
					this.formHasErrors = true;
					this.form.emergency_contact_1 = "";
				}
				if (this.form.emergency_phone_1 == null || this.form.emergency_phone_1 == "") {
					this.formHasErrors = true;
					this.form.emergency_phone_1 = "";
				}
				
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			}
		}
	};
</script>