import StudentOvertakeClass from "../views/schooladmin/StudentOvertakeClass";
import StudentOvertakeClassAddEdit from "../views/schooladmin/StudentOvertakeClassAddEdit";

export default [{
    path: "/schooladmin/student_overtake_class",
    components: {
      content: StudentOvertakeClass
    }
  },
  {
    path: "/schooladmin/student_overtake_class/new",
    components: {
      content: StudentOvertakeClassAddEdit
    }
  },
  {
    path: "/schooladmin/student_overtake_class/update/:id",
    components: {
      content: StudentOvertakeClassAddEdit
    },
    props: {
      content: true
    }
  }
];