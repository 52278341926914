import FinanceStudent from "../views/schooladmin/FinanceStudent";
import FinanceStudentAddEdit from "../views/schooladmin/FinanceStudentAddEdit";

export default [{
    path: "/schooladmin/financestudent",
    components: {
      content: FinanceStudent
    }
  },
  {
    path: "/schooladmin/financestudent/new",
    components: {
      content: FinanceStudentAddEdit
    }
  },
  {
    path: "/schooladmin/financestudent/update/:id",
    components: {
      content: FinanceStudentAddEdit
    },
    props: {
      content: true
    }
  }
];