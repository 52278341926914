import Student from "../views/schooladmin/Student";
import StudentAddEdit from "../views/schooladmin/StudentAddEdit";

export default [{
    path: "/schooladmin/student",
    components: {
      content: Student
    }
  },
  {
    path: "/schooladmin/student/new",
    components: {
      content: StudentAddEdit
    }
  },
  {
    path: "/schooladmin/student/update/:id",
    components: {
      content: StudentAddEdit
    },
    props: {
      content: true
    }
  }
];