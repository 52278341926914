<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Mutation Data</span>
				<span class="pl-2" v-else>Add new Mutation</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >School info</p>
						<v-select
							dense
							:items="schoolyears"
							item-text="name"
							item-value="id"
							v-model="form.schoolyear_id"
							filled
							label="Schoolyear"
						></v-select>
					</v-col>
					
						<v-col cols="12" sm="6">
							<p class="font-weight-black title" >Employee</p>
						<v-select
							dense
							:items="employeess"
							item-text="display_name"
							item-value="id"
							v-model="form.employee_id"
							filled
							label="Employee"
						></v-select>
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >Mutation Information</p>
						<v-select
							dense
							:items="mutation_types"
							item-text="name"
							item-value="id"
							v-model="form.mutation_type_id"
							filled
							label="Type"
						></v-select>
						<v-select
							dense
							:items="mutation_months"
							item-text="name"
							item-value="id"
							v-model="form.mutation_month_id"
							filled
							label="Month"
						></v-select>
						<v-checkbox v-model="form.correction" label="Correction"></v-checkbox>
						<v-text-field dense v-model="form.description" label="Description" filled></v-text-field>
						</v-col>
						<v-col cols="12" sm="6">
						<p class="font-weight-black title" >Transaction</p>
						<v-select
							dense
							:items="mutation_starts"
							item-text="name"
							item-value="id"
							v-model="form.mutation_start_id"
							filled
							label="Start"
						></v-select>
						<v-text-field dense v-model="form.lecture_hour" label="Hours" filled></v-text-field>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >Data Check</p>
						<v-select
							dense
							:items="users"
							item-text="username"
							item-value="username"
							v-model="form.firstcheckby"
							filled
							label="First check by"
						></v-select>
						<v-select
							dense
							:items="users"
							item-text="username"
							item-value="username"
							v-model="form.secondcheckby"
							filled
							label="Second check by"
						></v-select>
						</v-col>
					</v-col> 
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "mutationedit",
	props: ["id"],
	data() {
		return {
			user: localStorage.getItem("user"),
			snackbar: false,
			schoolyears:[],
			employeess:[],
			mutation_types:[],
			mutation_months: [],
			mutation_starts:[],
			users:[],
			form: {
				id: 0,
				schoolyear_id: null,
				employee_id: null,
				mutation_type_id:null,
				mutation_month_id: null,
				mutation_start_id: null,
				lecture_hour:"",
				firstcheckby: "",
				secondcheckby: "",
				correction:null,
				description:"",
			}
		};
	},
	created: function() {
		this.getUsers();
		this.getSchoolyears();
		this.getEmployees();
		this.getMutation_type();
		this.getMutation_month();
		this.getMutation_start();
		if (this.id > 0) {
			this.getMutation(this.id);
		}
	},
	watch: {

	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		getSchoolyears: function() {
			var self = this;

			ax.get("/schoolyear/projection_all").then(function(response) {
				console.log(response.data);
				self.schoolyears = response.data;
			});
		},
		getEmployees: function() {
			var self = this;

			ax.get("/employee/all").then(function(response) {
				console.log(response.data);
				self.employeess = response.data;
			});
		},
		getMutation_type: function() {
			var self = this;

			ax.get("/mutationtype/all").then(function(response) {
				console.log(response.data);
				self.mutation_types = response.data;
			});
		},
		getMutation_month: function() {
			var self = this;

			ax.get("/mutationmonth/all").then(function(response) {
				console.log(response.data);
				self.mutation_months = response.data;
			});
		},
		getMutation_start: function() {
			var self = this;

			ax.get("/mutationstart/all").then(function(response) {
				console.log(response.data);
				self.mutation_starts = response.data;
			});
		},
		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				console.log(response.data);
				self.users = response.data;
			});
		},
		getMutation: function(id) {
			var self = this;

			this.loading = true;
			ax.get("/mutation/single/" + id).then(function(response) {
				// console.log(response.data);
				self.form = response.data;
				console.log("Mutations fetched...");
				self.loading = false;
			});
		},
		submitForm() {
			var self = this;

			//this.$nextTick(function() {
			console.log(this.form);
			if (this.form.id > 0) {
				ax.post("/mutation/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Mutations update...");

					self.$router.push({
						path: "/schooladmin/mutation"
					});
				});
			} else {
				ax.post("/mutation/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/mutation"
					});
					console.log("Mutations created...");
				});
			}
			//});
		}
	}
};
</script>
