<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Student</span>
				<span class="pl-2" v-else>Student OSBOD</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-stepper v-model="e1">
			<v-stepper-header>
				<v-stepper-step :complete="e1 > 1" step="1">
					<p class="font-weight-black title">Student Informatie</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 2" step="2">
					<p class="font-weight-black title">Vooropleiding(en):</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 3" step="3">
					<p class="font-weight-black title">Emergency Contact</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 4" step="4">
					<p class="font-weight-black title">Gegevens Werkgever / Contactpersoon</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 5" step="5">
					<p class="font-weight-black title">Aanmelden</p>
				</v-stepper-step>
			</v-stepper-header>
			<v-form lazy-validation>
				<v-stepper-items>
					<v-stepper-content step="1">
						<v-card-text>
							<v-row>
								<v-col cols="12" sm="6">
								<v-menu ref="menu_aanmelding" v-model="menu_aanmelding" :close-on-content-click="false"
							:return-value.sync="form.appointment_date" transition="scale-transition" offset-y
							min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.appointment_date" label="Aanmeldings datum"
									prepend-inner-icon="mdi-calendar" readonly v-on="on" filled></v-text-field>
							</template>
							<v-date-picker v-model="form.appointment_date" no-title scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu_aanmelding = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.menu_aanmelding.save(form.appointment_date)">OK</v-btn>
							</v-date-picker>
						</v-menu>
									<v-text-field dense v-model="form.maidenname" label="Gehuwde naam" filled>
									</v-text-field>
									<v-text-field dense v-model="form.surname" label="Achternaam*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.firstname" label="Voornaam*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.middlename" label="Middelnaam" filled>
									</v-text-field>
									<v-select dense :items="genders" item-text="name" item-value="id"
										v-model="form.gender_id" filled label="Geslacht*" :rules="[rules.required]"
										:error-messages="errorMessages" required></v-select>
									<v-menu ref="menu_birthdate" v-model="menu_birthdate"
										:close-on-content-click="false" :return-value.sync="form.birthdate"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.birthdate" label="Geboortedatum*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.birthdate" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_birthdate = false">Cancel</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_birthdate.save(form.birthdate)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
									<v-select dense :items="birthplaces" item-text="name" item-value="id"
										v-model="form.birthplace_id" filled label="Geboorteplaats*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-select dense :items="nationalitys" item-text="name" item-value="id"
										v-model="form.nationality_id" filled label="Nationaliteit*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>


								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field dense v-model="form.government_id" label="Geldige I.D. Nr.*" filled
										:counter=10 :rules="[rules.required, rules.governmentid, rules.number_only]"
										:error-messages="errorMessages" required>
									</v-text-field>
									<v-menu ref="menu_govid_exp_date" v-model="menu_govid_exp_date"
										:close-on-content-click="false" :return-value.sync="form.govid_exp_date"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.govid_exp_date" label="ID vervaldatum*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.govid_exp_date" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_govid_exp_date = false">Cancel</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_govid_exp_date.save(form.govid_exp_date)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
									<v-select dense :items="maritial_statuss" item-text="name" item-value="id"
										v-model="form.maritial_status_id" filled label="Burgerlijke staat*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-select dense :items="denominations" item-text="name" item-value="id"
										v-model="form.denomination_id" filled label="Geloofsovertuiging*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-text-field dense v-model="form.address" label="Adres*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.email" label="Email*" filled
										:rules="[rules.required, rules.email]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-row>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_mobile"
												prepend-inner-icon="mdi-cellphone" label="Mobiel*" filled :counter=7
												:rules="[rules.required, rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages" required>
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_home"
												prepend-inner-icon="mdi-phone-classic" label="Thuis" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages">
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_work"
												prepend-inner-icon="mdi-deskphone" label="Werk" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages"></v-text-field>
										</v-col>
									</v-row>



								</v-col>
							</v-row>
						</v-card-text>

						<v-btn color="success" @click="submitForm(id)" depressed>Opslaan</v-btn>
						<v-btn color="primary" @click="submitFormTransition()" depressed>
							Continue
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Cancel</v-btn>
					</v-stepper-content>
					<v-stepper-content step="2">
						<v-card>
							<v-col cols="12" sm="6">
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_fo" label="FO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_fo_level" label="t/m groep:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_fo_year" label="in het jaar:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_vo" label="VO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vo_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vo_year" label="tot en met jaar:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_vsbo" label="VSBO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vsbo_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_vsbo_year" label="tot en met jaar:"
											filled>
										</v-text-field>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_sbo" label="SBO"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_sbo_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_sbo_year" label="tot en met jaar:" filled>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="2">
										<v-checkbox v-model="form.edu_other" label="Andere"></v-checkbox>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_other_level" label="niveau:" filled>
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="5">
										<v-text-field dense v-model="form.edu_other_year" label="tot en met jaar:"
											filled>
										</v-text-field>
									</v-col>
								</v-row>
							</v-col>
						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Back</v-btn>
						<v-btn color="primary" @click="e1=e1+1" depressed>
							Continue
						</v-btn>
						<v-btn color="error" @click="$router.go(-1);">Cancel</v-btn>
					</v-stepper-content>

					<v-stepper-content step="3">
						<v-card>
							<v-col cols="12" sm="6">
								<v-text-field dense v-model="form.emergency_contact" label="Emergency Contact" filled>
								</v-text-field>
								<v-text-field dense v-model="form.emergency_phone" label="Contact nummer" filled>
								</v-text-field>
								
							</v-col>



						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Back</v-btn>

						<v-btn color="primary" @click="submitFormTransition(id)" depressed>
							Continue
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Cancel</v-btn>
					</v-stepper-content>

					<v-stepper-content step="4">
						<v-card>
							<v-col cols="12" sm="6">
								<v-checkbox v-model="form.payee_confirmation"
									label="Betaling geschied door mijn werkgever."></v-checkbox>
								<v-text-field dense v-model="form.payee_name" label="Naam Bedrijf" filled>
								</v-text-field>
								<v-text-field dense v-model="form.payee_address" label="Adres Bedrijf" filled>
								</v-text-field>
								<v-text-field dense v-model="form.payee_telephone" label="Naam contactpersoon" filled>
								</v-text-field>
								<v-text-field dense v-model="form.payee_contact_person" label="Tel. nr. contactpersoon"
									filled></v-text-field>
								<v-text-field dense v-model="form.payee_email" label="Email contactpersoon" filled>
								</v-text-field>
								
							</v-col>



						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Back</v-btn>

						<v-btn color="primary" @click="submitFormTransition(id)" depressed>
							Continue
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Cancel</v-btn>
					</v-stepper-content>
					<v-stepper-content step="5">

						<v-card>
							<v-row>
								<v-col cols="12" sm="12">
									<p class="font-weight-black title">Inschrijf- en Betalingsvoorwaarden</p>
									<p class="font-weight-bold">Ik wens mij aan te melden voor de opleiding:</p>
									<v-select dense :items="studys" item-text="name" item-value="id" 
										v-model="form.study_id" filled label="Study*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>
									<!-- <v-text-field dense v-model="form.registration_cost"
										value=tbl_sys_study.registration_cost label="Inschrijf kosten" filled readonly>
									</v-text-field>
									<v-text-field dense v-model="form.study_cost" label="Opleiding kosten" filled
										readonly>
									</v-text-field>
									<v-text-field dense v-model="form.material_cost" label="Lesmateriaal kosten" filled
										readonly>
									</v-text-field> -->
									<p class="font-weight-bold">1. Inschrijven voor een opleiding</p>
									<p class="font-weight-regular">U verklaart met uw handtekening op de inschrijving
										akkoord te gaan met de inschrijf en betalingsvoorwaarden.
										Hou er rekening mee dat u voor elk schooljaar opnieuw moet inschrijven en
										inschrijfgeld betalen.</p>
									<p class="font-weight-bold">2. Wijze van betaling</p>
									<p class="font-weight-regular">Het inschrijfgeld dient gestort te zijn op
										bankrekening
										van OSBOD met vermelding van naam student, opleiding op rekening nummer
										GIRO-Bank 91.02.806
										Schoolgeld moet gestort worden op Bankrekening nummer MCB– Bank 32.06.19.10 met
										vermelding van naam student, opleiding en klas.
										Contactgeld wordt NIET geaccepteerd.
										Alle stortingsbewijzen moeten gemaild worden naar de administratief medewerker
										van
										uw sector. Uw originele altijd goed bewaren.
										Volledige betaling moet voldaan zijn voor afloop van de schooljaar, indien dit
										niet
										het geval is kunt u helaas uw cijferlijst NIET ontvangen.
										Indien u in de examenjaar zit en de financiele verplichtingen niet zijn voldaan
										kunt
										u helaas uw diploma en cijferlijst niet ontvangen.
									</p>
									<p class="font-weight-bold">3. Afmelding</p>
									<p class="font-weight-regular">Na 2 maanden afwezig te zijn geweest van de
										schoolactiviteiten zonder een geldige berichtgeving met bewijsstukken, wordt u
										automatisch uitgeschreven.
										met als gevolg dat u zich opnieuw kunt inschrijven voor het volgende schooljaar
										en
										inschrijfgeld weer betalen.</p>
									<p class="font-weight-bold">Alle betaalde bedragen kunnen niet meer opgeisd worden.
									</p>
									<p class="font-weight-regular">
										Mocht u voor één of andere reden met de opleiding stoppen moet u de digitale
										uitschrijvingsformulier invullen, daarna krijgt u een uitschrijvingsbewijs van
										de
										administratie via mail.
									</p>
									<p class="font-weight-bold">4. Lesmateriaal</p>
									<p class="font-weight-regular">De nodige lesmaterialen zult u ontvangen bij de start
										van
										de opleiding indien de financiële verplichting is voldaan.</p>
									<p class="font-weight-bold">5. Deelname examens</p>
									<p class="font-weight-regular">Alle belangrijke data inclusief de toets- en
										herkansingsdata zijn terug te vinden op uw jaarplanning. Alle examenregelingen
										zijn
										in overleg met Inspectie van Onderwijs samengesteld en is te vinden in
										Examenhandboek van OSBOD.</p>
									<p class="font-weight-bold">6. Wijziging opleiding</p>
									<p class="font-weight-regular">OSBOD behoudt het recht om wijzigingen te brengen in
										rooster / docent / locatie en tijd te allen tijde. Bij onvoldoende
										inschrijvingen
										kan een opleiding afgelast worden. Studenten die ingeschreven staan zullen
										tijdig
										schriftelijk bericht ontvangen en vervallen hun financiele verplichtingen. Aan
										hen
										worden geen kosten in rekening gebracht en vindt restitutie van hun
										inschrijfgeld
										plaats.</p>
									<v-checkbox v-model="form.registration_complete"
										label="U verklaart alle inschrijvingsinformatie naar waarheid en volledig te hebben ingevuld*"
										required :rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
									<v-checkbox v-model="form.registration_agree" required
										label="U verklaart akkoord te gaan met de inschrijf en betalingsvoorwaarden.*"
										:rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
									<strong class="red">
							<v-checkbox color="red" v-model="form.deleted">
								<template v-slot:label>
									<div><strong class="error--text">Delete</strong></div>
								</template>
							</v-checkbox>
						</strong>
								</v-col>
							</v-row>
						</v-card>
						<v-card-actions>
							<v-btn color="secondary" @click="e1=e1-1">Back</v-btn>
							<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
							<v-btn color="error" @click="$router.go(-1);" depressed>Cancel</v-btn>
						</v-card-actions>
					</v-stepper-content>
				</v-stepper-items>
			</v-form>
		</v-stepper>



		<v-card-text>

			<v-row>


			</v-row>

		</v-card-text>
		<!-- <v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions> -->

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			U dient alle verplichte velden in te vullen! Druk op de terug knop om de verplichte velden alsnog in te
			vullen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "usersignupadd",
		props: ["id"],
		data() {
			return {
				e1: 1,
				valid: true,
				errorMessages: '',
				formHasErrors: false,
				user: JSON.parse(localStorage.getItem("user")),
				snackbar: false,
			//	schoolyears: [],
				studys: [],
				users: [],
				genders: [],
				birthplaces: [],
				nationalitys: [],
				maritial_statuss: [],
				denominations: [],
				selectedstudycost: [],
				menu: false,
				menu_aanmelding: false,
				menu_birthdate: false,
				menu_govid_exp_date: false,
				rules: {
					required: value => !!value || 'Dit veld is verplicht!.',
					email: value => {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						return pattern.test(value) || 'Onvolledige e-mail.'
					},
					governmentid: value => (value && value.length == 10) ||
						'Identiteitsnummer dient 10 nummers te zijn.',
					telephone_number: value => (value && value.length == 7) ||
						'Telefoonnummer dient 7 nummers te zijn.',
					number_only: value => {
						const pattern = /^[0-9]+$/
						return pattern.test(value) || 'Alleen nummers is toegestaan'
					}
				},
				form: {
					id: 0,
					role_id: 1,
					schoolyear_id: 2,
					registration_type_id:1,
					study_id: null,
					firstname: "",
					middlename: "",
					surname: "",
					maidenname: "",
					government_id: "",
					govid_exp_date:"",
					birthdate: "",
					birthplace_id: null,
					gender_id: null,
					nationality_id: null,
					maritial_status_id: null,
					denomination_id: null,
					address: "",
					email: "",
					tel_home: "",
					tel_mobile: "",
					tel_work: "",
					registration_agree: "",
					registration_complete: "",
					registration_cost: "",
					study_cost: "",
					material_cost: "",
					display_name: "",
					payee_confirmation: 0,
					payee_name: "",
					payee_address: "",
					payee_telephone: "",
					payee_contact_person: "",
					payee_email: "",
					edu_fo: 0,
					edu_fo_level: null,
					edu_fo_year: null,
					edu_vo: 0,
					edu_vo_level: null,
					edu_vo_year: null,
					edu_vsbo: 0,
					edu_vsbo_level: null,
					edu_vsbo_year: null,
					edu_sbo: 0,
					edu_sbo_level: null,
					edu_sbo_year: null,
					edu_other: 0,
					edu_other_level: null,
					edu_other_year: null,
					deleted: null,
					deleted_by_id: null,
					appointment_date: "",
					emergency_contact: "",
					emergency_phone: "",
				}
			};
		},
		created: function () {
			this.getUsers();
		//	this.getSchoolyears();
			this.getStudys();
			this.getGenders();
			this.getBirthplaces();
			this.getNationalitys();
			this.getMaritialstatus();
			this.getDenomination();
			if (this.id > 0) {
				this.getStudent(this.id);
			}
		},
		watch: {
			name() {
				this.errorMessages = '';
			}
			,
			"form.deleted": function () {
				console.log(this.user.id);
				this.form.deleted_by_id = this.user.id;
			},
			// "form.study_id": function (value) {
			// 	console.log("Get selected cost");
			// 	//console.log(this.cost_center_id_obj);
			// 	console.log(value);

			// 	this.getSelectedStudy(value);

			// },
		},
		methods: {
			validate() {
				this.$refs.form.validate()
			},
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			// getSchoolyears: function () {
			// 	var self = this;

			// 	ax.get("/schoolyear/all").then(function (response) {
			// 		console.log(response.data);
			// 		self.schoolyears = response.data;
			// 	});
			// },
			getBirthplaces: function () {
				var self = this;

				ax.get("/birthplace/all").then(function (response) {
					console.log(response.data);
					self.birthplaces = response.data;
				});
			},
			getNationalitys: function () {
				var self = this;

				ax.get("/nationality/all").then(function (response) {
					console.log(response.data);
					self.nationalitys = response.data;
				});
			},
			getMaritialstatus: function () {
				var self = this;

				ax.get("/maritial_status/all").then(function (response) {
					console.log(response.data);
					self.maritial_statuss = response.data;
				});
			},
			getDenomination: function () {
				var self = this;

				ax.get("/denomination/all").then(function (response) {
					console.log(response.data);
					self.denominations = response.data;
				});
			},
			getStudys: function () {
				var self = this;

				ax.get("/study/all").then(function (response) {
					console.log(response.data);
					self.studys = response.data;
				});
			},
			getSelectedStudy: function () {
				var self = this;
				ax.get("/study/single/" + this.form.study_id).then(function (response) {
					console.log("getStudy Costs")
					self.selectedstudycost = response.data;
					console.log(response.data);
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getGenders: function () {
				var self = this;

				ax.get("/gender/all").then(function (response) {
					console.log(response.data);
					self.genders = response.data;
				});
			},
			getStudent: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/student/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("UserRegistrations fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				this.formHasErrors = false;

				if (this.form.study_id == null || this.form.study_id == "") {
					this.formHasErrors = true;
					this.form.study_id = "";

				}
				if (this.form.registration_agree == null || this.form.registration_agree == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_agree = null;
				}
				if (this.form.registration_complete == null || this.form.registration_complete == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_complete = null;
				}
				//this.$nextTick(function() {
				console.log(this.form);
				if (this.form.maritial_status_id == 1) { if(this.form.gender_id == 2){
					this.form.display_name = this.form.maidenname + " e/v " + this.form.surname + ", "+  this.form.firstname + " " + this.form.middlename;}
					else {
						this.form.display_name = this.form.surname + ", " + this.form.firstname + " " + this.form.middlename;
					}
				} else {
					this.form.display_name = this.form.surname + " " + this.form.firstname + " " + this.form.middlename;
				}
				if (this.formHasErrors == false) {
					if (this.form.id > 0) {
						ax.post("/student/update/" + this.form.id, this.form).then(function (
							response
						) {
							console.log(response.data);

							console.log("UserRegistrations update...");

							self.$router.push({
								path: "/schooladmin/student_class"
							});
						});
					} else {
						console.log("before post");
						ax.post("/student/create", this.form).then(function (response) {
							console.log("almost there");
							console.log(response.data);
							self.$router.push({
								path: "/schooladmin/student"
							});
							console.log("UserRegistrations created...");
						});


					}
				} else {
					this.form
				}
				//});
			},
			submitFormTransition() {
				var self = this;
				this.formHasErrors = false;

				if (this.form.surname == null || this.form.surname == "") {
					this.formHasErrors = true;
					this.form.surname = null;
				}
				if (this.form.firstname == null || this.form.firstname == "") {
					this.formHasErrors = true;
					this.form.firstname = null;
				}
				if (this.form.birthdate == null || this.form.birthdate == "") {
					this.formHasErrors = true;
					this.form.birthdate = null;
				}
				if (this.form.govid_exp_date == null || this.form.govid_exp_date == "") {
					this.formHasErrors = true;
					this.form.govid_exp_date = null;
				}
				if (this.form.birthplace_id == null || this.form.birthplace_id == "") {
					this.formHasErrors = true;
					this.form.birthplace_id = "";
				}
				if (this.form.government_id == null || this.form.government_id == "") {
					this.formHasErrors = true;
					this.form.government_id = null;
				}
				if (this.form.tel_mobile == null || this.form.tel_mobile == "") {
					this.formHasErrors = true;
					this.form.tel_mobile = null;
				}
				if (this.form.email == null || this.form.email == "") {
					this.formHasErrors = true;
					this.form.email = null;
				}
				if (this.form.address == null || this.form.address == "") {
					this.formHasErrors = true;
					this.form.address = null;
				}
				if (this.form.gender_id == null || this.form.gender_id == "") {
					this.formHasErrors = true;
					this.form.gender_id = "";
				}
				if (this.form.denomination_id == null || this.form.denomination_id == "") {
					this.formHasErrors = true;
					this.form.denomination_id = "";
				}
				if (this.form.nationality_id == null || this.form.nationality_id == "") {
					this.formHasErrors = true;
					this.form.nationality_id = "";
				}
				if (this.form.maritial_status_id == null || this.form.maritial_status_id == "") {
					this.formHasErrors = true;
					this.form.maritial_status_id = "";
				}
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			}
		}
	};
</script>