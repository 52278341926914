<template>
	<div>
		<v-app-bar app clipped-left color="primary">
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<span class="title ml-3 mr-5">
				OSBOD Employee Management System&nbsp;
				<span class="font-weight-light"></span>
			</span>
			<div class="flex-grow-1"></div>
			{{ user.firstname }} {{ user.surname }}&nbsp;&nbsp;
			<v-menu left bottom>
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on">
						<v-icon>mdi-account-circle</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-list-item to="/" link>
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<!-- <v-text-field
        solo-inverted
        flat
        hide-details
        label="Search"
        prepend-inner-icon="mdi-magnify"
			></v-text-field>-->
		</v-app-bar>
		<v-navigation-drawer
			width="300"
			v-model="drawer"
			:mini-variant.sync="mini"
			:clipped="$vuetify.breakpoint.lgAndUp"
			app
			flat
		>
			<v-list-item>
				<v-list-item-title v-if="!mini">
					<v-img :aspect-ratio="16/9" src="../assets/school.jpeg">
				</v-img>
					<p class="font-weight-black"><strong class="light-blue--text">{{user.tbl_sys_role.name}}</strong></p></v-list-item-title>
				<v-btn v-if="!mini" icon @click.stop="mini = !mini">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<v-btn v-if="mini" icon @click.stop="mini = !mini">
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</v-list-item>

			<v-divider></v-divider>

			<v-list dense>
				<v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<!-- Test Navigation -->
					<!-- <v-list>
						<v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.action"
							>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title >{{ item.title }}</v-list-item-title>
								</v-list-item-content>
							</template>

							<v-list-item v-for="child in item.items" :key="child.title" :to="child.to" link>
								<v-list-item-icon>
									<v-icon>{{ child.icon }}</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title >{{ child.title }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-group>
					</v-list> -->
		<!-- End test Navigation -->

			<!-- <a href="http://reports.pepms.com:8082/jasperserver/login.html" target="new"><v-list-item>
				<v-list-item-icon><v-icon>mdi-cloud-print-outline</v-icon></v-list-item-icon>
				<v-list-item-title>Printer portal</v-list-item-title>
			</v-list-item></a> -->
			<a href="http://rapportage.ministerie-owcs.cw:8082/jasperserver/login.html" target="new"><v-list-item>
				<v-list-item-icon><v-icon>mdi-cloud-print-outline</v-icon></v-list-item-icon>
				<v-list-item-title>Printer portal</v-list-item-title>
			</v-list-item></a>
		</v-navigation-drawer>
		
		<v-content>
			<!-- Provides the application the proper gutter -->
			<v-container fluid>
				<!-- If using vue-router -->

				<router-view name="content"></router-view>
			</v-container>
		</v-content>

		<v-footer app>
			<div class="flex-grow-1"></div>&copy; ICTC B.V. Version 1.2.0
		</v-footer>
	</div>
</template>

<script>
// import _ from "lodash";

export default {
	name: "navbar",
	data: function() {
		return {
			// school_id: localStorage.getItem("school_id"),
			// schooltype_id: localStorage.getItem("schooltype_id"),
			user: JSON.parse(localStorage.getItem("user")),
			access: localStorage.getItem("access"),
			// schools: [],
			// selected_school: {},
			// items: [],
			// Test navigation new
			systemadmin3: [
        {
          action: '',
          items: [{
					title: "Uren verantwoording Coordinatoren",
					icon: "mdi-timetable",
					to: "/schooladmin/employeetimesheet"
				},
					{
					title: "Admin - To-do list",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_task"
				},
					{
					title: "Admin - Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_timesheet"
				},
          ],
          title: 'Personal Admin',
        },
		{
          action: '',
          items: [{
					title: "Opleidingen",
					icon: "mdi-school",
					to: "/schooladmin/study"
				},
				{
					title: "Klassen",
					icon: "mdi-table-large",
					to: "/schooladmin/schoolclass"
				},
				{
					title: "Deelkwalificaties",
					icon: "mdi-table-large",
					to: "/schooladmin/subject"
				},
				{
					title: "Docent Modules",
					icon: "mdi-chair-school",
					to: "/schooladmin/teachersubjectclass"
				},
				],
          title: 'Configuratie',
        },
		{
          action: '',
          items: [{
					title: "Werknemers Bankgegevens",
					icon: "mdi-account-group-outline",
					to: "/schooladmin/employee"
				},
				{
					title: "Actieve Werknemers",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_cohort"
				},
				{
					title: "Administratie Werknemers",
					icon: "mdi-account-convert",
					to: "/schooladmin/adminemployeesignup"
				},
				{
					title: "Mutatie Uren",
					icon: "mdi-format-list-checks",
					to: "/schooladmin/mutation"
				},
				{
					title: "Studykosten",
					icon: "mdi-chair-school",
					to: "/schooladmin/schoolyearstudycost"
				},
				
          ],
          title: 'Financiele Admin',
        },
		{
          action: '',
          items: [
				// {
				// 	title: "Inschrijvingen",
				// 	icon: "mdi-file-check",
				// 	to: "/schooladmin/studentregistrationadmin"
				// },
				{
					title: "Her-Inschrijvingen",
					icon: "mdi-file-account",
					to: "/schooladmin/student_class_projection"
				},
				{
					title: "Intake Planning",
					icon: "mdi-calendar",
					to: "/schooladmin/studentintake"
				},
				{
					title: "Studenten OSBOD",
					icon: "mdi-account-multiple",
					to: "/schooladmin/student"
				},
				{
					title: "Actieve Student",
					icon: "mdi-file-document",
					to: "/schooladmin/student_cohort"
				},
				{
					title: "Studenten Plaatsing",
					icon: "mdi-file-document",
					to: "/schooladmin/student_class"
				},
				{
					title: "Studenten Inhaallessen",
					icon: "mdi-chair-school",
					to: "/schooladmin/student_overtake_class"
				},
				{
					title: "Audit Studenten Plaatsing",
					icon: "mdi-account-multiple-outline",
					to: "/schooladmin/student_class_audit"
				},
          ],
          title: 'School Admin',
        },
		{
          action: '',
          items: [{
					title: "Verzuim docenten",
					icon: "mdi-file-document",
					to: "/schooladmin/teacher_absent"
				},
				{
					title: "Verzuim N-O-P",
					icon: "mdi-file-document",
					to: ""
				},
          ],
          title: 'Verzuim Personeel',
        },
        
        {
          action: 'mdi-school',
          items: [{ title: 'List Item' }],
          title: 'Examenbureau',
        },
        {
          action: 'mdi-human-male-female-child',
          items: [{ title: 'List Item' }],
          title: 'Family',
        },
        {
          action: 'mdi-bottle-tonic-plus',
          items: [{ title: 'List Item' }],
          title: 'Health',
        },
        {
          action: 'mdi-briefcase',
          items: [{ title: 'List Item' }],
          title: 'Office',
        },
        {
          action: 'mdi-tag',
          items: [{ title: 'List Item' }],
          title: 'Promotions',
        },
      ],
			// End Test Navigation new
			
			systemadmin: [
				{
					title: "Opleidingen",
					icon: "mdi-school",
					to: "/schooladmin/study"
				},
				{
					title: "Klassen",
					icon: "mdi-table-large",
					to: "/schooladmin/schoolclass"
				},
				{
					title: "Inschrijvingen",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationadmin"
				},
				
				{
					title: "Her-Inschrijvingen",
					icon: "mdi-file-account",
					to: "/schooladmin/student_class_projection"
				},
				{
					title: "Her-Inschrijvingen coordinator",
					icon: "mdi-file-account",
					to: "/schooladmin/student_class_projection_coordinator"
				},
				{
					title: "Deelkwalificaties",
					icon: "mdi-table-large",
					to: "/schooladmin/subject"
				},
				{
					title: "Docent Modules",
					icon: "mdi-chair-school",
					to: "/schooladmin/teachersubjectclass"
				},
				{
					title: "Docent Modules Cursus",
					icon: "mdi-chair-school",
					to: "/schooladmin/teachersubjectclasscursus"
				},
				{
					title: "Toetsen",
					icon: "mdi-chair-school",
					to: "/schooladmin/exam"
				},
				{
					title: "Toetsen Cursus",
					icon: "mdi-chair-school",
					to: "/schooladmin/examcursus"
				},
				{
					title: "Intake Planning",
					icon: "mdi-calendar",
					to: "/schooladmin/studentintake"
				},
				{
					title: "Intake",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationintake"
				},
				{
					title: "Studenten OSBOD",
					icon: "mdi-account-multiple",
					to: "/schooladmin/student"
				},
				{
					title: "Actieve Student",
					icon: "mdi-file-document",
					to: "/schooladmin/student_cohort"
				},
				{
					title: "Studenten Plaatsing",
					icon: "mdi-file-document",
					to: "/schooladmin/student_class"
				},
				{
					title: "Studenten Plaatsing Cursus",
					icon: "mdi-file-document",
					to: "/schooladmin/student_class_cursus"
				},
				{
					title: "Plaatsing Coordinator",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationcoordinator"
				},
				{
					title: "Studenten Inhaallessen",
					icon: "mdi-chair-school",
					to: "/schooladmin/student_overtake_class"
				},
				{
					title: "Audit Studenten Plaatsing",
					icon: "mdi-account-multiple-outline",
					to: "/schooladmin/student_class_audit"
				},
				
				{
					title: "Werknemers Bankgegevens",
					icon: "mdi-account-group-outline",
					to: "/schooladmin/employee"
				},
				{
					title: "Actieve Werknemers",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_cohort"
				},
				{
					title: "Administratie Werknemers",
					icon: "mdi-account-convert",
					to: "/schooladmin/adminemployeesignup"
				},
				{
					title: "Mutatie Uren",
					icon: "mdi-format-list-checks",
					to: "/schooladmin/mutation"
				},
				{
					title: "Verzuim docenten",
					icon: "mdi-file-document",
					to: "/schooladmin/teacher_absent"
				},
				{
					title: "Verzuim N-O-P",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_absent"
				},
					{
					title: "Uren verantwoording Coordinatoren",
					icon: "mdi-timetable",
					to: "/schooladmin/employeetimesheet"
				},
					{
					title: "Admin - To-do list",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_task"
				},
					{
					title: "Admin - Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_timesheet"
				},
				{
					title: "To-do list - Inhaal",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_task_overtake"
				},
				{
					title: "Uren verantwoording - Inhaal",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_timesheet_overtake"
				},
				{
					title: "Admin NGU - timeback",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_loa_ovt"
				},{
					title: "Studykosten",
					icon: "mdi-currency-usd",
					to: "/schooladmin/schoolyearstudycost"
				},
				{
					title: "Finance Plaatsing",
					icon: "mdi-chair-school",
					to: "/schooladmin/financestudentplacement"
				},
				{
					title: "Finance Student",
					icon: "mdi-bank",
					to: "/schooladmin/financestudentview"
				},
			],
			schooladmin: [
				{
					title: "Opleidingen",
					icon: "mdi-school",
					to: "/schooladmin/study"
				},
				{
					title: "Klassen",
					icon: "mdi-table-large",
					to: "/schooladmin/schoolclass"
				},
				
				{
					title: "Inschrijvingen",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationadmin"
				},
				{
					title: "Her-Inschrijvingen",
					icon: "mdi-file-account",
					to: "/schooladmin/student_class_projection"
				},
				{
					title: "Deelkwalificaties",
					icon: "mdi-table-large",
					to: "/schooladmin/subject"
				},
				{
					title: "Docent Modules",
					icon: "mdi-chair-school",
					to: "/schooladmin/teachersubjectclass"
				},
				// {
				// 	title: "Intake Planning",
				// 	icon: "mdi-calendar",
				// 	to: "/schooladmin/studentintake"
				// },
				// {
				// 	title: "OSBOD Event",
				// 	icon: "mdi-account-multiple",
				// 	to: "/schooladmin/osbodevent"
				// },
				{
					title: "Studenten Plaatsing",
					icon: "mdi-file-document",
					to: "/schooladmin/student_class"
				},
				{
					title: "Plaatsing Coordinator",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationcoordinator"
				},
				{
					title: "Studenten Inhaallessen",
					icon: "mdi-chair-school",
					to: "/schooladmin/student_overtake_class"
				},
				{
					title: "Audit Studenten Plaatsing",
					icon: "mdi-account-multiple-outline",
					to: "/schooladmin/student_class_audit"
				},
				{
					title: "Intake",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationintake"
				},
				// {
				// 	title: "Werknemers Bankgegevens",
				// 	icon: "mdi-account-group-outline",
				// 	to: "/schooladmin/employee"
				// },
				{
					title: "Actieve Werknemers",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_cohort"
				},
				{
					title: "Administratie Werknemers OSBOD",
					icon: "mdi-account-convert",
					to: "/schooladmin/adminemployeesignup"
				},
				// {
				// 	title: "Mutatie Uren",
				// 	icon: "mdi-format-list-checks",
				// 	to: "/schooladmin/mutation"
				// },
					{
					title: "To-do list",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_task"
				},
					{
					title: "Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_timesheet"
				},
				// {
				// 	title: "Uren verantwoording",
				// 	icon: "mdi-timetable",
				// 	to: "/schooladmin/admin_timesheet_overtake"
				// },
				{
					title: "Admin NGU - timeback",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_loa_ovt"
				},
				{
					title: "Finance Plaatsing",
					icon: "mdi-chair-school",
					to: "/schooladmin/financestudentplacement"
				},
				{
					title: "Finance Student",
					icon: "mdi-bank",
					to: "/schooladmin/financestudentview"
				},
				
			],
			coordinator: [
				{
					title: "Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/employeetimesheet"
				},{
					title: "Plaatsing Coordinator",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationcoordinator"
				},
				{
					title: "Her-Inschrijvingen coordinator",
					icon: "mdi-file-account",
					to: "/schooladmin/student_class_projection_coordinator"
				},
				
			],
			
			examenbureau_coordinator: [
				{
					title: "Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/employeetimesheet"
				},
				{
					title: "Toetsen",
					icon: "mdi-chair-school",
					to: "/schooladmin/exam"
				},
			],
			beleidsmedewerker: [
				{
					title: "Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/employeetimesheet"
				}
			],
			examenbureau: [
			{
					title: "To-do list",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_task"
				},
					{
					title: "Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_timesheet"
				},
				{
					title: "Admin NGU - timeback",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_loa_ovt"
				},
				{
					title: "Toetsen",
					icon: "mdi-chair-school",
					to: "/schooladmin/exam"
				},
			],
			administratie: [
				{
					title: "Opleidingen",
					icon: "mdi-school",
					to: "/schooladmin/study"
				},
				{
					title: "Klassen",
					icon: "mdi-table-large",
					to: "/schooladmin/schoolclass"
				},
				{
					title: "Inschrijvingen",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationadmin"
				},
				{
					title: "Her-Inschrijvingen",
					icon: "mdi-file-account",
					to: "/schooladmin/student_class_projection"
				},
				{
					title: "Deelkwalificaties",
					icon: "mdi-table-large",
					to: "/schooladmin/subject"
				},
				{
					title: "Docent Modules",
					icon: "mdi-chair-school",
					to: "/schooladmin/teachersubjectclass"
				},
				// {
				// 	title: "Intake Planning",
				// 	icon: "mdi-calendar",
				// 	to: "/schooladmin/studentintake"
				// },
				// {
				// 	title: "OSBOD Event",
				// 	icon: "mdi-account-multiple",
				// 	to: "/schooladmin/osbodevent"
				// },
				{
					title: "Studenten Plaatsing",
					icon: "mdi-file-document",
					to: "/schooladmin/student_class"
				},
				{
					title: "Plaatsing Coordinator",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationcoordinator"
				},
				{
					title: "Studenten Inhaallessen",
					icon: "mdi-chair-school",
					to: "/schooladmin/student_overtake_class"
				},
				{
					title: "Audit Studenten Plaatsing",
					icon: "mdi-account-multiple-outline",
					to: "/schooladmin/student_class_audit"
				},
				{
					title: "Intake",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationintake"
				},
				{
					title: "Werknemers Bankgegevens",
					icon: "mdi-account-group-outline",
					to: "/schooladmin/employee"
				},
				{
					title: "Actieve Werknemers",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_cohort"
				},
				{
					title: "Administratie Werknemers OSBOD",
					icon: "mdi-account-convert",
					to: "/schooladmin/adminemployeesignup"
				},
				{
					title: "Finance Plaatsing",
					icon: "mdi-chair-school",
					to: "/schooladmin/financestudentplacement"
				},
				{
					title: "Finance Student",
					icon: "mdi-bank",
					to: "/schooladmin/financestudentview"
				},
				{
					title: "Mutatie Uren",
					icon: "mdi-format-list-checks",
					to: "/schooladmin/mutation"
				},
				// 	{
				// 	title: "To-do list",
				// 	icon: "mdi-timetable",
				// 	to: "/schooladmin/admin_task"
				// },
				// 	{
				// 	title: "Uren verantwoording",
				// 	icon: "mdi-timetable",
				// 	to: "/schooladmin/admin_timesheet"
				// },
				{
					title: "To-do list",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_task_overtake"
				},
				{
					title: "Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_timesheet_overtake"
				},
				{
					title: "Admin NGU - timeback",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_loa_ovt"
				},
			],
			admin_finance: [
				
				{
					title: "Opleidingen",
					icon: "mdi-school",
					to: "/schooladmin/study"
				},
				{
					title: "Klassen",
					icon: "mdi-table-large",
					to: "/schooladmin/schoolclass"
				},
				
				{
					title: "Inschrijvingen",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationadmin"
				},
				{
					title: "Her-Inschrijvingen",
					icon: "mdi-file-account",
					to: "/schooladmin/student_class_projection"
				},
				{
					title: "Deelkwalificaties",
					icon: "mdi-table-large",
					to: "/schooladmin/subject"
				},
				{
					title: "Docent Modules",
					icon: "mdi-chair-school",
					to: "/schooladmin/teachersubjectclass"
				},
				// {
				// 	title: "Intake Planning",
				// 	icon: "mdi-calendar",
				// 	to: "/schooladmin/studentintake"
				// },
				// {
				// 	title: "Studenten OSBOD",
				// 	icon: "mdi-account-multiple",
				// 	to: "/schooladmin/student"
				// },
				{
					title: "Studenten Plaatsing",
					icon: "mdi-file-document",
					to: "/schooladmin/student_class"
				},
				{
					title: "Plaatsing Coordinator",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationcoordinator"
				},
				{
					title: "Studenten Inhaallessen",
					icon: "mdi-chair-school",
					to: "/schooladmin/student_overtake_class"
				},
				{
					title: "Audit Studenten Plaatsing",
					icon: "mdi-account-multiple-outline",
					to: "/schooladmin/student_class_audit"
				},
				{
					title: "Intake",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationintake"
				},
				{
					title: "Werknemers Bankgegevens",
					icon: "mdi-account-group-outline",
					to: "/schooladmin/employee"
				},
				{
					title: "Actieve Werknemers",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_cohort"
				},
				{
					title: "Administratie Werknemers OSBOD",
					icon: "mdi-account-convert",
					to: "/schooladmin/adminemployeesignup"
				},
				{
					title: "Mutatie Uren",
					icon: "mdi-format-list-checks",
					to: "/schooladmin/mutation"
				},
				// 	{
				// 	title: "To-do list",
				// 	icon: "mdi-timetable",
				// 	to: "/schooladmin/admin_task"
				// },
				// 	{
				// 	title: "Uren verantwoording",
				// 	icon: "mdi-timetable",
				// 	to: "/schooladmin/admin_timesheet"
				// },
				{
					title: "To-do list",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_task_overtake"
				},
				{
					title: "Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_timesheet_overtake"
				},
				{
					title: "Admin NGU - timeback",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_loa_ovt"
				},
			],
			administratie_ondersteuning: [
				
			
					{
					title: "To-do list",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_task"
				},
					{
					title: "Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_timesheet"
				},
				{
					title: "Admin NGU - timeback",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_loa_ovt"
				},
				
			],
			administratie_ondersteuning_weigle: [
				
			
					{
					title: "To-do list",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_task"
				},
					{
					title: "Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_timesheet"
				},
				{
					title: "Admin NGU - timeback",
					icon: "mdi-file-document",
					to: "/schooladmin/employee_loa_ovt"
				},
				{
					title: "Inschrijvingen",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationadmin"
				},
				{
					title: "Plaatsing Coordinator",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationcoordinator"
				},
				{
					title: "Intake",
					icon: "mdi-file-check",
					to: "/schooladmin/studentregistrationintake"
				},
				{
					title: "Studenten Plaatsing",
					icon: "mdi-file-document",
					to: "/schooladmin/student_class"
				},
				// {
				// 	title: "Studenten Inhaallessen",
				// 	icon: "mdi-chair-school",
				// 	to: "/schooladmin/student_overtake_class"
				// },
				{
					title: "Audit Studenten Plaatsing",
					icon: "mdi-account-multiple-outline",
					to: "/schooladmin/student_class_audit"
				},
				{
					title: "Finance Plaatsing",
					icon: "mdi-chair-school",
					to: "/schooladmin/financestudentplacement"
				},
				{
					title: "Finance Student",
					icon: "mdi-bank",
					to: "/schooladmin/financestudentview"
				},
				
			],
			inhalenurenverantwoording: [
				
			
					{
					title: "To-do list",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_task_overtake"
				},
					{
					title: "Uren verantwoording",
					icon: "mdi-timetable",
					to: "/schooladmin/admin_timesheet_overtake"
				},
				
			],

			mini: false,
			drawer: true
		};
	},

	created() {
		console.log("Navigation bar...");

		console.log(this.user);
		// this.getSchool();

		// this.schools = this.user.tbl_control_user_schools;
		// console.log(this.schools);

		// if (this.schools.length > 0) {
		// 	this.selected_school = this.schools[0];
		// }

		switch (this.user.role_id) {
			case 1:
				this.items = this.systemadmin;
				break;
			case 3:
				this.items = this.admin_finance;
				break;
			case 2:
				this.items = this.schooladmin;
				break;
			case 4:
				this.items = this.coordinator;
				break;
			case 5:
				this.items = this.beleidsmedewerker;
				break;
			case 6:
				this.items = this.examenbureau;
				break;
			case 7:
				this.items = this.administratie;
				break;
			case 8:
				this.items = this.administratie_ondersteuning;
				break;
			case 9:
				this.items = this.inhalenurenverantwoording;
				break;
			default:
				break;
			case 10:
				this.items = this.administratie_ondersteuning_weigle;
				break;
			case 11:
				this.items = this.examenbureau_coordinator;
				break;
		}

		if (this.access) {
			console.log("Has Access : " + this.access);
		} else {
			this.$router.push({
				path: "/"
			});
		}
	},
	// watch: {
	// 	school_id: function(value) {
	// 		console.log(value);
	// 		this.$store.dispatch("setSchool", value);
	// 		//Search for school type user schools array
	// 		var index = _.findIndex(this.schools, { school_id: value });
	// 		console.log("School index: " + index);
	// 		this.$store.dispatch(
	// 			"setSchoolType",
	// 			this.schools[index].tbl_school.schooltype_id
	// 		);
	// 	},
	// 	selected_school: function(value) {
	// 		console.log(value);
	// 		this.$store.dispatch("setSchool", value.school_id);
	// 		this.$store.dispatch("setSchoolType", value.tbl_school.schooltype_id);
	// 		this.school_id = value.tbl_school.id;
	// 	}
	//  },
	computed: {
		loggedin() {
			return this.$store.getters.getLoggedin;
		}
		// ,
		// school() {
		// 	return this.$store.getters.getSchool;
		// },
		// // user() {
		// //   return this.$store.getters.getUser;
		// // },
		// schooltype() {
		// 	return this.$store.getters.getSchoolType;
		// }
	},
	methods: {
		handleEdit(index, row) {
			console.log(index, row);
		},
		// getSchool() {
		// 	var self = this;

		// 	self.schools = self.user.tbl_control_user_schools;
		// 	console.log("School fetched...");
		// 	console.log(self.schools);
		// },
		clickLogout() {
			this.$store.dispatch("logout");

			this.$router.push({
				path: "/"
			});
		}
	}
};
</script>