import StudentRegistrationCoordinator from "../views/schooladmin/StudentRegistrationCoordinator";
import StudentRegistrationCoordinatorAddEdit from "../views/schooladmin/StudentRegistrationCoordinatorAddEdit";

export default [{
    path: "/schooladmin/studentregistrationcoordinator",
    components: {
      content: StudentRegistrationCoordinator
    }
  },
  {
    path: "/schooladmin/studentregistrationcoordinator/new",
    components: {
      content: StudentRegistrationCoordinatorAddEdit
    }
  },
  {
    path: "/schooladmin/studentregistrationcoordinator/update/:id",
    components: {
      content: StudentRegistrationCoordinatorAddEdit
    },
    props: {
      content: true
    }
  }
];