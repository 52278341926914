<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Opleiding kostenplaat</span>
				<span class="pl-2" v-else>Add Opleiding kostenplaat</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<v-col cols="12" sm="6">
						<!-- <p class="font-weight-black title" >School info</p> -->
						<v-select
							dense
							:items="finance_tasks"
							item-text="name"
							item-value="id"
							v-model="form.finance_task_id"
							filled
							label="Kostenplaat"
						></v-select>
						<v-select
							dense
							:items="schoolyears"
							item-text="name"
							item-value="id"
							v-model="form.schoolyear_id"
							filled
							label="Schoolyear"
						></v-select>
						<v-select
							dense
							:items="sectors"
							item-text="name"
							item-value="id"
							v-model="form.sector_id"
							filled
							label="Sector"
						></v-select>
						<v-select
							dense
							:items="studys"
							item-text="name"
							item-value="id"
							v-model="form.study_id"
							filled
							label="Opleiding"
						></v-select>
						
						<v-text-field dense v-model="form.amount" label="Bedrag" filled>
									</v-text-field>
					</v-col> 
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "mutationedit",
	props: ["id"],
	data() {
		return {
			user: localStorage.getItem("user"),
			snackbar: false,
			sectors:[],
			studys:[],
			finance_tasks:[],
			schoolyears: [],
			users:[],
			form: {
				id: 0,
				schoolyear_id: null,
				sector_id: null,
				study_id: null,
				finance_task_id: null,
				amount:"",
			}
		};
	},
	created: function() {
		this.getUsers();
		this.getSectors();
		this.getFinanceTask();
		this.getSchoolyears();
		if (this.id > 0) {
				this.getSchoolyearStudyCost(this.id);
				
			}
	},
	watch: {
		"form.sector_id": function(value){
				this.getStudy(value);
				// this.getClass1(value);
				
			},

	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		getSectors: function() {
			var self = this;

			ax.get("/sector/all").then(function(response) {
				console.log(response.data);
				self.sectors = response.data;
			});
		},
		getStudy: function() {
			var self = this;

			ax.get("/study/selected_sector/", {
				params: {
					sector_id: self.form.sector_id,
				}
			}).then(function(response) {
				self.studys = response.data;
				console.log("After Fill");
	
			});
			
			
		},getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/finance_all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
		getSchoolyearStudyCost: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/schoolyear_study_cost/single/" + id).then(function (response) {
					console.log("I do what i am told");
					self.form = response.data;
					self.loading = false;
					console.log(response.data);
				});
			},
		getFinanceTask: function() {
			var self = this;

			ax.get("/finance_task/all").then(function(response) {
				console.log("FinanceTask Fill")
				console.log(response.data);
				self.finance_tasks = response.data;
			});
		},
		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				// console.log(response.data);
				self.users = response.data;
			});
		},
		submitForm() {
			var self = this;
			

			//this.$nextTick(function() {
			if (this.form.id > 0) {
				ax.post("/schoolyear_study_cost/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Mutations update...");

					self.$router.push({
						path: "/schooladmin/schoolyearstudycost"
					});
				});
			} else {
				ax.post("/schoolyear_study_cost/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/schoolyearstudycost"
					});
					console.log("Mutations created...");
				});
			}
			//});
		}
	}
};
</script>
