<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Schoolclass</span>
				<span class="pl-2" v-else>Add Schoolclass</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<v-col cols="12" sm="6">
						<!-- <p class="font-weight-black title" >School info</p> -->
						<v-select
							dense
							:items="sectors"
							item-text="name"
							item-value="id"
							v-model="form.sector_id"
							filled
							label="Sector"
						></v-select>
						<v-select
							dense
							:items="studys"
							item-text="name"
							item-value="id"
							v-model="form.study_id"
							filled
							label="Opleiding"
						></v-select>
						<v-select
							dense
							:items="levels"
							item-text="name"
							item-value="id"
							v-model="form.level_id"
							filled
							label="Niveau"
						></v-select>
						<v-select
							dense
							:items="grades"
							item-text="name"
							item-value="id"
							v-model="form.grade_id"
							filled
							label="Grade"
						></v-select>
						<v-text-field dense v-model="form.name" label="Klas"
											filled>
										</v-text-field>
						<v-checkbox      v-model="form.visible"  label="Beschikbaar"
    ></v-checkbox>
					</v-col> 
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "mutationedit",
	props: ["id"],
	data() {
		return {
			user: localStorage.getItem("user"),
			snackbar: false,
			sectors:[],
			levels:[],
			studys:[],
			grades:[],
			schoolclasss:[],
			users:[],
			form: {
				id: 0,
				sector_id: null,
				level_id: null,
				study_id: null,
				visible: "",
				grade_id: null,
				name: null
			}
		};
	},
	created: function() {
		this.getUsers();
		this.getsectors();
		this.getLevel();
		this.getGrade();
		//this.getStudy();
		
		if (this.id > 0) {
				this.getSchoolClass(this.id);
				
			}
	},
	watch: {
		"form.sector_id": function(value){
				this.getStudy(value);
				// this.getLevel1(value);
				
			},
		// "form.study_id": function(value){
		// 		this.getLevel(value);
		// 		// this.getLevel1(value);
				
		// 	}
	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		getsectors: function() {
			var self = this;

			ax.get("/sector/all").then(function(response) {
				// console.log(response.data);
				self.sectors = response.data;
			});
		},
		getStudy: function() {
			var self = this;

			ax.get("/study/selected_sector/", {
				params: {
					sector_id: self.form.sector_id,
				}
			}).then(function(response) {
				self.studys = response.data;
				console.log("After Fill");
	
			});
			
			
		},
		getSchoolClass: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/school_class/single/" + id).then(function (response) {
					console.log("school_class_single");
					console.log(response.data);
					self.form = response.data;
					self.loading = false;
				});
			},
		getLevel: function() {
			var self = this;

			ax.get("/level/all").then(function(response) {
				// console.log(response.data);
				self.levels = response.data;
			});
		
		},
		getGrade: function() {
			var self = this;

			ax.get("/grade/all").then(function(response) {
				console.log("Grade Fill")
				// console.log(response.data);
				self.grades = response.data;
			});
		},
		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				// console.log(response.data);
				self.users = response.data;
			});
		},
		submitForm() {
			var self = this;

			//this.$nextTick(function() {
			if (this.form.id > 0) {
				ax.post("/school_class/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Mutations update...");

					self.$router.push({
						path: "/schooladmin/schoolclass"
					});
				});
			} else {
				ax.post("/school_class/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/schoolclass"
					});
					console.log("Mutations created...");
				});
			}
			//});
		}
	}
};
</script>
