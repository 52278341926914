<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Teacher Module</span>
				<span class="pl-2" v-else>Add Teacher Module</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<v-col cols="12" sm="6">
						<!-- <p class="font-weight-black title" >School info</p> -->
						<v-select
							dense
							:items="schoolyears"
							item-text="name"
							item-value="id"
							v-model="form.schoolyear_id"
							filled
							label="Schoolyear"
						></v-select>
						<v-select
							dense
							:items="sectors"
							item-text="name"
							item-value="id"
							v-model="form.sector_id"
							filled
							label="Sector"
						></v-select>
						<v-select
							dense
							:items="studys"
							item-text="name"
							item-value="id"
							v-model="form.study_id"
							filled
							label="Opleiding"
						></v-select>
						<v-select
							dense
							:items="classs"
							item-text="name"
							item-value="id"
							v-model="form.class_id"
							filled
							label="Klas"
						></v-select>
						<v-select
							dense
							:items="periodes"
							item-text="name"
							item-value="id"
							v-model="form.periode_id"
							filled
							label="Periode"
						></v-select>
						<v-select
							dense
							:items="subjects"
							item-text="name"
							item-value="id"
							v-model="form.subject_id"
							filled
							label="Deelkwalificatie"
						></v-select>
						<v-text-field dense v-model="form.module" label="Module" filled>
									</v-text-field>
							<v-select
							dense
							:items="employees"
							item-text="teacher_name"
							item-value="id"
							v-model="form.employee_id"
							filled
							label="Docent"
						></v-select>
					</v-col> 
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "mutationedit",
	props: ["id"],
	data() {
		return {
			user: localStorage.getItem("user"),
			snackbar: false,
			sectors:[],
			classs:[],
			studys:[],
			subjects:[],
			periodes:[],
			employees:[],
			schoolyears: [],
			users:[],
			form: {
				id: 0,
				schoolyear_id: null,
				sector_id: null,
				class_id: null,
				study_id: null,
				periode_id: null,
				employee_id:null,
				subject_id:null,
				module:"",
				subject_module:""
			}
		};
	},
	created: function() {
		this.getUsers();
		this.getsectors();
		this.getClass();
		this.getPeriode();
		this.getEmployee();
		this.getSchoolyears();
		if (this.id > 0) {
				this.getTeacherSubjectClass(this.id);
				
			}
	},
	watch: {
		"form.sector_id": function(value){
				this.getStudy(value);
				// this.getClass1(value);
				
			},
		"form.study_id": function(value){
				this.getClass(value);
				// this.getClass1(value);
				
			},
		"form.periode_id": function(value){
				this.getSubject(value);
				// this.getClass1(value);
				
			}
	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		getsectors: function() {
			var self = this;

			ax.get("/sector/all").then(function(response) {
				console.log(response.data);
				self.sectors = response.data;
			});
		},
		getStudy: function() {
			var self = this;

			ax.get("/study/selected_sector/", {
				params: {
					sector_id: self.form.sector_id,
				}
			}).then(function(response) {
				self.studys = response.data;
				console.log("After Fill");
	
			});
			
			
		},getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all_cursus").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
		getSubject: function() {
			var self = this;
			
			ax.get("/subject/selected_class/", {
				params: {
					class_id: self.form.class_id,
					periode_id: self.form.periode_id
				}
			}).then(function(response) {
				console.log("Subject Before Fill")
				console.log(self.subjects)
				self.subjects = response.data;
				console.log("Subject After Fill")
				console.log(self.subjects)
			});
			
		},
		getTeacherSubjectClass: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/teacher_subject_class/single/" + id).then(function (response) {
					console.log("I do what i am told");
					self.form = response.data;
					self.loading = false;
					console.log(response.data);
				});
			},
		getClass: function() {
			var self = this;

			ax.get("/school_class/selected_study/", {
				params: {
					study_id: self.form.study_id,
				}
			}).then(function(response) {
				console.log("Before Fill")
				console.log(self.classs)
				self.classs = response.data;
				console.log("After Fill")
				console.log(self.classs)
			});
			
		
		},
		getPeriode: function() {
			var self = this;

			ax.get("/periode/all").then(function(response) {
				console.log("Periode Fill")
				console.log(response.data);
				self.periodes = response.data;
			});
		},
		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				// console.log(response.data);
				self.users = response.data;
			});
		},
		getEmployee: function() {
			var self = this;

			ax.get("/employee/teacher_all").then(function(response) {
				// console.log(response.data);
				self.employees = response.data;
				

			});
		},
		submitForm() {
			var self = this;
			self.form.subject_module = self.subjects.name;

			//this.$nextTick(function() {
			if (this.form.id > 0) {
				ax.post("/teacher_subject_class/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Mutations update...");

					self.$router.push({
						path: "/schooladmin/teachersubjectclasscursus"
					});
				});
			} else {
				ax.post("/teacher_subject_class/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/teachersubjectclasscursus"
					});
					console.log("Mutations created...");
				});
			}
			//});
		}
	}
};
</script>
