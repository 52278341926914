<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2">OSBOD Herinschrijving Coord</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
		</v-card-title>

		<v-data-table :headers="headers" :items="data" :search="search" :loading="loading">
			<template v-slot:top>
				<v-toolbar dark dense color="primary" flat class="mb-1">
					<!-- <v-btn depressed class="ml-2" @click="setStudentClass()" color="inverted">
						<v-icon class="pr-2">mdi-plus-circle</v-icon>Add StudentClass
					</v-btn> -->
				</v-toolbar>
				
			</template>

			<template v-slot:item.action="{ item }">
				<v-icon @click="handleClick(item.id)">mdi-account-edit</v-icon>
				
			</template>
			<template v-slot:item.projection_coordinator="{ item }">

<v-simple-checkbox
  v-model="item.projection_coordinator"
  disabled
></v-simple-checkbox>
</template>			
			<template v-slot:item.registration_complete="{ item }">
        <v-simple-checkbox
          v-model="item.registration_complete"
          disabled
        ></v-simple-checkbox>
      </template>
			
		</v-data-table>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "student_classs",
	data() {
		return {
			user: JSON.parse(localStorage.getItem("user")),
			search: "",
			headers: [
				{
					text: "",
					align: "center",
					sortable: false,
					value: "action"
				},
				// { text: "Mutatie", value: "tbl_sys_registration_type.name" },
				{ text: "Schoolyear", value: "tbl_sys_schoolyear.name" },
				{ text: "Government ID", value: "tbl_student.government_id" },
				{ text: "Firstname", value: "tbl_student.firstname" },
				{ text: "Lastname", value: "tbl_student.surname" },
				{ text: "Class", value: "tbl_sys_class.name" },
				{ text: "Mobiel", value: "tbl_student.tel_mobile" },
				// { text: "E-mail", value: "tbl_student.email" },
				{ text: "E-mail OSBOD", value: "tbl_student.osbod_email" },
				{ text: "Prognose voldaan", value:"projection_coordinator" },
				{ text: "Herinschrijving voldaan", value:"registration_complete" },

				{ text: "Reg. Code", value: "registration_code"},
				{ text: "Reg. PSW",	value: "registration_password"},
				// { text: "Active", value: "tbl_student_class.student_class_active" }

			],
			
			// semesters: [],
			// studies: [],
			// subjects: [],
			// subjecttypes: [],
			// selected_subject: null,
			// selected_kwalificatie: null,
			// selected_deelkwalificatie: null,
			// selected_semester: null,
			// schoolyear: [],
			// selected_schoolyear: null,
			data: [],
			// banktypes: [],
			isEmpty: false,
			loading: false,
			isPaginated: true,
			isPaginationSimple: false,
			defaultSortDirection: "asc",
			currentPage: 1,
			perPage: 15
		};
	},
	created: function() {
		// this.getBanktypes();
		this.getStudentClasss();
	},
	watch: {
		// selected_kwalificatie: function(value) {
		// 	this.getStudySubjecttype(value);
		// 	console.log(value);
		// }
	},
	computed: {
		// school() {
		// 	return this.$store.getters.getSchool;
		// }
	},
	methods: {
		handleClick(id) {
			this.$router.push({
				path: "/schooladmin/student_class_projection_coordinator/update/" + id
			});
		},
		setStudentClass: function() {
			this.$router.push({
				path: "/schooladmin/student_class_projection_coordinator/new"
			});
		},
		getStudentClasss: function() {
			var self = this;
			this.loading = true;
			ax.get("/student_class_projection/coordinator_all",
			{
				params: {
						user_id: this.user.id
					}}).then(function(response) {

					// console.log(response.data);
				self.data = response.data;
				console.log("123458888...");
				console.log(response.data);
				self.loading = false;
			});
		},
		// getBanktypes: function() {
		// 	var self = this;
		// 	ax.get("/banktype/all").then(function(response) {
		// 		console.log(response.data);
		// 		self.banktypes = response.data;
		// 	});
		// }
	}
};
</script>
 
 <!-- Add "slot-scoped" attribute to limit CSS to this component only -->
 <style slot-scoped>
</style>