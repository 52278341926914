<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2">OSBOD Docenten Absentie</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
		</v-card-title>

		<v-data-table :headers="headers" :items="data" :search="search" :loading="loading">
			<template v-slot:top>
				<v-toolbar dark dense color="primary" flat class="mb-1">
					<!-- <v-btn depressed class="ml-2" @click="setStudentClass()" color="inverted">
						<v-icon class="pr-2">mdi-plus-circle</v-icon>Add StudentClass
					</v-btn> -->
				</v-toolbar>
				
			</template>

			<template v-slot:item.action="{ item }">
				<v-icon @click="handleClick(item.id)">mdi-account-edit</v-icon>
				
			</template>
						<template v-slot:item.registration_complete="{ item }">
        <v-simple-checkbox
          v-model="item.registration_complete"
          disabled
        ></v-simple-checkbox>
      </template>
			
		</v-data-table>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "student_classs",
	data() {
		return {
			//user: localStorage.getItem("user"),
			search: "",
			headers: [
				{
					text: "",
					align: "center",
					sortable: false,
					value: "action"
				},
				{ text: "Absentie start", value: "absent_start" },
				{ text: "Absentie eind", value: "absent_end" },
				{ text: "Absentie type", value: "tbl_sys_absent_type.name" },
				{ text: "Government ID", value: "tbl_employee.government_id" },
				{ text: "Docent", value: "tbl_employee.teacher_name" },
				{ text: "Mobiel", value: "tbl_employee.tel_mobile" },
				{ text: "E-mail OSBOD", value: "tbl_employee.osbod_email" },
				{ text: "Verzuim document", value:"registration_complete" },
				{ text: "Verzuim afgehandeld", value:"registration_complete" },

				// { text: "First Check", value: "firstcheckby"},
				// { text: "Second Check",	value: "secondcheckby"},
				// { text: "Active", value: "tbl_student_class.student_class_active" }

			],
			
			// semesters: [],
			// studies: [],
			// subjects: [],
			// subjecttypes: [],
			// selected_subject: null,
			// selected_kwalificatie: null,
			// selected_deelkwalificatie: null,
			// selected_semester: null,
			// schoolyear: [],
			// selected_schoolyear: null,
			data: [],
			// banktypes: [],
			isEmpty: false,
			loading: false,
			isPaginated: true,
			isPaginationSimple: false,
			defaultSortDirection: "asc",
			currentPage: 1,
			perPage: 15
		};
	},
	created: function() {
		// this.getBanktypes();
		this.getStudentClasss();
	},
	watch: {
		// selected_kwalificatie: function(value) {
		// 	this.getStudySubjecttype(value);
		// 	console.log(value);
		// }
	},
	computed: {
		// school() {
		// 	return this.$store.getters.getSchool;
		// }
	},
	methods: {
		handleClick(id) {
			this.$router.push({
				path: "/schooladmin/teacher_absent/update/" + id
			});
		},
		setStudentClass: function() {
			this.$router.push({
				path: "/schooladmin/teacher_absent/new"
			});
		},
		getStudentClasss: function() {
			var self = this;
			this.loading = true;
			ax.get("/teacher_absent/all").then(function(response) {
					// console.log(response.data);
				self.data = response.data;
				console.log("123458888...");
				console.log(response.data);
				self.loading = false;
			});
		},
		// getBanktypes: function() {
		// 	var self = this;
		// 	ax.get("/banktype/all").then(function(response) {
		// 		console.log(response.data);
		// 		self.banktypes = response.data;
		// 	});
		// }
	}
};
</script>
 
 <!-- Add "slot-scoped" attribute to limit CSS to this component only -->
 <style slot-scoped>
</style>