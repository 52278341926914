<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Wijzig Urenverantwoording Data</span>
				<span class="pl-2" v-else>Nieuwe Urenverantwoording</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>

					<v-col cols="12" sm="6">
						<p class="font-weight-black title">School info</p>
						<v-select dense :items="schoolyears" item-text="name" item-value="id"
							v-model="form.schoolyear_id" filled label="Schoolyear"></v-select>
					</v-col>

					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Periode</p>
						<v-select dense :items="periodes" item-text="name" item-value="id" v-model="form.periode_id"
							filled label="Periode"></v-select>
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Werklocatie</p>
						<v-select dense :items="worklocations" item-text="name" item-value="id"
							v-model="form.worklocation_id" filled label="Werklocatie"></v-select>
					</v-col>
					<v-col cols="12" sm="6">
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Uren verantwoording</p>
						<v-select dense :items="tasks" item-text="name" item-value="id" v-model="form.task_id" filled
							label="Uitgevoerde taak"></v-select>
						<v-select dense :items="employee_tasks" item-text="name" item-value="id"
							v-model="form.employee_task_id" filled label=""></v-select>
						<v-menu ref="menu1" v-model="menu1" :close-on-content-click="false"
							:return-value.sync="form.dedicated_date" transition="scale-transition" offset-y
							min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.dedicated_date" label="Datum"
									prepend-inner-icon="mdi-calendar" readonly v-on="on" filled></v-text-field>
							</template>
							<v-date-picker v-model="form.dedicated_date" no-title scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.menu1.save(form.dedicated_date)">OK
								</v-btn>
							</v-date-picker>
						</v-menu>

						<!-- Columns are always 50% wide, on mobile and desktop -->
						<v-row>
						<v-col cols="12" sm="3"><p class="font-weight-black title">Besteden tijd:</p>
						</v-col>
						
							<v-col cols="6" sm="3">
								<v-select dense :items="hours" item-text="name" item-value="id"
							v-model="form.hour_id" filled label="Uren"></v-select>
					</v-col>
					<v-col cols="6" sm="3">
								<v-select dense :items="minutes" item-text="name" item-value="id"
							v-model="form.minute_id" filled label="Minuten"></v-select>
					</v-col>
						</v-row>
						<!-- <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
							:return-value.sync="form.dedicated_start" transition="scale-transition" offset-y
							max-width="290px" min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.dedicated_start" label="Begin tijd" readonly v-on="on">
								</v-text-field>
							</template>
							<v-time-picker v-if="menu2" v-model="form.dedicated_start" full-width
								@click:minute="$refs.menu2.save(form.dedicated_start)"></v-time-picker>
						</v-menu>
						<v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
							:return-value.sync="form.dedicated_finish" transition="scale-transition" offset-y
							max-width="290px" min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.dedicated_finish" label="Eind tijd" readonly v-on="on">
								</v-text-field>
							</template>
							<v-time-picker v-if="menu3" v-model="form.dedicated_finish" full-width
								@click:minute="$refs.menu3.save(form.dedicated_finish)"></v-time-picker>
						</v-menu> -->
						<!-- <v-text-field dense v-model="form.lecture_hour" label="Hours" filled></v-text-field>
						<v-select
							dense
							:items="users"
							item-text="username"
							item-value="username"
							v-model="form.firstcheckby"
							filled
							label="First check by"
						></v-select>
						<v-select
							dense
							:items="users"
							item-text="username"
							item-value="username"
							v-model="form.secondcheckby"
							filled
							label="Second check by"
						></v-select> -->

					</v-col>

					<v-col cols="12" sm="6">
						<v-textarea filled name="input-7-4" label="Betrokkene(n)" v-model="form.involved"></v-textarea>
						<v-textarea filled name="input-7-4" label="Opmerking" v-model="form.description"></v-textarea>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "mutationedit",
		props: ["id"],
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user")),
				snackbar: false,
				schoolyears: [],
				periodes: [],
				worklocations: [],
				tasks: [],
				employee_tasks: [],
				mutation_starts: [],
				users: [],
				menu1: false,
				menu2: false,
				menu3: false,
				hours: [],
				minutes: [],
				form: {
					id: 0,
					schoolyear_id: 1,
					user_id: null,
					periode_id: null,
					worklocation_id: null,
					task_id: null,
					hour_id: null,
					minutes_id: null,
					mutation_start_id: null,
					employee_task_id: null,
					usermae: null,
					dedicated_date: null,
					dedicated_start: null,
					dedicated_finish: null,
					involved: null,
					description: null
				}
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			this.getHours();
			this.getMinutes();
			this.getPeriodes();
			this.getWorklocation();
			this.getTask();
			// this.getEmployeetimesheet_start();
			if (this.id > 0) {
				this.getEmployeetimesheet(this.id);
			}
		},
		watch: {
			"form.task_id": function (value) {
				if (value > 1) {
					this.form.employee_task_id = null;
				}
				this.getEmployeeTask(value);
			}
		},
		methods: {
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getHours: function () {
				var self = this;

				ax.get("/hour/all").then(function (response) {
					console.log(response.data);
					self.hours = response.data;
				});
			},
			getMinutes: function () {
				var self = this;

				ax.get("/minute/all").then(function (response) {
					console.log(response.data);
					self.minutes = response.data;
				});
			},
			getPeriodes: function () {
				var self = this;

				ax.get("/periode/all").then(function (response) {
					console.log(response.data);
					self.periodes = response.data;
				});
			},
			getWorklocation: function () {
				var self = this;

				ax.get("/worklocation/all").then(function (response) {
					console.log(response.data);
					self.worklocations = response.data;
				});
			},
			getTask: function () {
				var self = this;

				ax.get("/task/all").then(function (response) {
					console.log("this is tasks")
					console.log(response.data);
					self.tasks = response.data;
				});
			},
			getEmployeeTask: function () {
				this.form.user_id = this.user.id;
				var self = this;

				ax.get("/employee_task/user_task/", {
					params: {
						user_id: this.form.user_id,
						task_id: self.form.task_id
					}
				}).then(function (response) {
					console.log("Subject Before Fill")
					console.log(self.employee_tasks)
					self.employee_tasks = response.data;
					console.log("Subject After Fill")
					console.log(self.employee_tasks)
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getEmployeetimesheet: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/employee_timesheet/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("Mutations fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				this.form.user_id = this.user.id;
				this.form.username = this.user.username;
				//this.$nextTick(function() {
				console.log(this.form);
				if (this.form.id > 0) {
					ax.post("/employee_timesheet/update/" + this.form.id, this.form).then(function (
						response
					) {
						console.log(response.data);

						console.log("Employees update...");

						self.$router.push({
							path: "/schooladmin/employeetimesheet"
						});
					});
				} else {
					ax.post("/employee_timesheet/create", this.form).then(function (response) {
						console.log(response.data);
						self.$router.push({
							path: "/schooladmin/employeetimesheet"
						});
						console.log("Mutations created...");
					});
				}
				//});
			}
		}
	};
</script>