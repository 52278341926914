<template>
    <div id="main_page">
      
    <v-app-bar app>
      <v-toolbar-title class="headline text-uppercase">
        <span>Vuetify</span>
        <span class="font-weight-light">MATERIAL DESIGN</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        text
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
      >
        <span class="mr-2">Latest Release</span>
      </v-btn>
    </v-app-bar>

    <v-content>
      <HelloWorld/>
    </v-content>
    </div>
</template> 
 <script>


export default {
    name: 'main_page',
    data() {
        return {
            msg: 'Main Navigation'
        }
    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        //his.fetchData();

    },
    methods: {
        navigate(destination) {
            this.$router.push({
                path: destination
            });
        }
    }
}
</script>
 
 <!-- Add "scoped" attribute to limit CSS to this component only -->
 <style scoped>

</style>