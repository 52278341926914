import AdminTimesheet from "../views/schooladmin/AdminTimesheet";
import AdminTimesheetAddEdit from "../views/schooladmin/AdminTimesheetAddEdit";

export default [{
    path: "/schooladmin/admin_timesheet",
    components: {
      content: AdminTimesheet
    }
  },
  {
    path: "/schooladmin/admin_timesheet/new",
    components: {
      content: AdminTimesheetAddEdit
    }
  },
  {
    path: "/schooladmin/admin_timesheet/update/:id",
    components: {
      content: AdminTimesheetAddEdit
    },
    props: {
      content: true
    }
  }
];