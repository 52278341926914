<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">OSBOD Event Registratie</span>
				<span class="pl-2" v-else>OSBOD Event Registratie</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Instrukshon kon pa registra bo informashon</p>
						<p class="font-weight-regular">1. Yena e informashonnan rekeri den e formulario
						<p class="font-weight-regular">2. Si bo informashon ta kompleto i korekto selekta e 2 hoki nan pa akorda.</p>
						<p class="font-weight-regular">3. Primi riba e boton "Registra" pa finalisa e registrashon.</p>
					</v-col>

					<v-col cols="12" sm="6">
<!-- 
						<p class="font-weight-black title">Herinschrijving Code</p>
						<v-text-field dense v-model="formsearch.registration_code" label="Herinschrijving Code" filled></v-text-field>
						<v-text-field dense v-model="formsearch.registration_password" label="Wachtwoord" filled type="password"></v-text-field>
						<v-btn color="primary" @click="searchForm()" depressed>Zoek</v-btn> -->
						<v-btn color="secondary" @click="SubmitTeacher()" >Klik pa bai Registrashon Dosente</v-btn> 
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Informashon Studiante</p>

						

						<!-- <v-text-field dense v-model="form.osbod_email" label="Osbod-email" value="tbl_student.osbod_email" filled readonly ></v-text-field> -->
						<v-text-field dense v-model="form.firstname" label="Nòmber*" filled :rules="[rules.required]"
										:error-messages="errorMessages" required></v-text-field>
						<v-text-field dense v-model="form.surname" label="Fam*" filled :rules="[rules.required]"
										:error-messages="errorMessages" required></v-text-field>
						<v-text-field dense v-model="form.government_id" label="I.D.*" filled
										:counter=10 :rules="[rules.required, rules.governmentid, rules.number_only]"
										:error-messages="errorMessages" required>
						</v-text-field>
						<v-menu ref="menu_birthdate" v-model="menu_birthdate"
										:close-on-content-click="false" :return-value.sync="form.birthdate"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.birthdate" label="Fecha di nasementu*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.birthdate" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_birthdate = false">Annuleren</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_birthdate.save(form.birthdate)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
						<v-select dense :items="birthplaces" item-text="name" item-value="id"
										v-model="form.birthplace_id" filled label="Lugá di nasementu*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>

						<v-text-field dense v-model="form.email" label="Email*" filled
										:rules="[rules.required, rules.email]" :error-messages="errorMessages" required>
									</v-text-field>
						
											<v-text-field dense v-model="form.tel_mobile"
												prepend-inner-icon="mdi-cellphone" label="Telefòn*" filled :counter=7
												:rules="[rules.required, rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages" required>
											</v-text-field>
						<p class="font-weight-bold">Mi ta interesa den e sigiente estudio(nan) aki:</p>
						<v-select dense :items="studys" item-text="name" item-value="id" 
										v-model="form.student_study_id_1" filled label="Eskoho 1*" required :rules="[rules.required]"
										:error-messages="errorMessages"></v-select>

						<v-select dense :items="studys" item-text="name" item-value="id" 
										v-model="form.student_study_id_2" filled label="Eskoho 2" ></v-select>
						
						<v-select dense :items="studys" item-text="name" item-value="id" 
										v-model="form.student_study_id_3" filled label="Eskoho 3" ></v-select>
										


						<!-- <v-select dense :items="registration_types" item-text="name" item-value="id"
							v-model="form.registration_type_id" filled label="Inschrijving/Herinschrijving" readonly></v-select>
						<v-select dense :items="schoolyears" item-text="name" item-value="id"
							v-model="form.schoolyear_id" filled label="Schoolyear" readonly></v-select>
						
						<v-select dense :items="studys" item-text="name" item-value="id"
							v-model="form.study_id" filled label="Opleiding" readonly></v-select>
						
						<v-select dense :items="classs" item-text="name" item-value="id" v-model="form.class_id"
							filled label="Klas" readonly></v-select> -->
						<v-checkbox v-model="form.registration_complete"
										label="Tur informashon ta kompletu yena*"
										required :rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
						<v-checkbox v-model="form.registration_agree" required
										label="Bo ta bai di akuerdo pa OSBOD por uza bo informashon pa por tuma kontakto ku bo persona.*"
										:rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
					</v-col>
					
				</v-row>
			</v-form>
			<v-row><v-col cols="12" sm="6"></v-col><v-col cols="12" sm="6"><v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Registra</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Kansela</v-btn>
		</v-card-actions></v-col></v-row>
			
		</v-card-text>
		

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "mutationedit",
		props: ["id"],
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user")),
				errorMessages: '',
				formHasErrors: false,
				snackbar: false,
				schoolyears: [],
				birthplaces:[],
				studys:[],
				users: [],
				menu_birthdate: false,
				rules: {
					required: value => !!value || 'E informashon aki ta rekeri!.',
					email: value => {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						return pattern.test(value) || 'e-mail invalido.'
					},
					governmentid: value => (value && value.length == 10) ||
						'ID mester kontene maximo 10 number.',
					telephone_number: value => (value && value.length == 7) ||
						'Number di telefòn mester ta 7 sifra.',
					number_only: value => {
						const pattern = /^[0-9]+$/
						return pattern.test(value) || 'Solamente number ta permiti'
					}
				},
				form: {
					id: 0,
					schoolyear_id:3,
					registration_type_id:1,
					firstname: null,
					surname:null,
					government_id: null,
					birthdate: null,
					birthplace_id: null,
					tel_mobile: null,
					email: null,
					student_study_id_1:null,
					student_study_id_2:null,
					student_study_id_3:null,
					registration_agree: "",
					registration_complete: "",
					
					

				},
				
				
				
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			this.getBirthplaces();
			this.getStudy();
			if (this.id > 0) {
				this.getMutation(this.id);
			
			} 
		},
		watch: {name() {
				this.errorMessages = '';
			}
	},
		methods: {
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getBirthplaces: function () {
				var self = this;

				ax.get("/birthplace/all").then(function (response) {
					console.log(response.data);
					self.birthplaces = response.data;
				});
			},
			getRegistration_type: function () {
				var self = this;

				ax.get("/registrationtype/all").then(function (response) {
					console.log(response.data);
					self.registration_types = response.data;
				});
			},

			getStudy: function () {
				var self = this;

				ax.get("/study/all_event").then(function (response) {
					console.log(response.data);
					self.studys = response.data;
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					
					self.users = response.data;
				});
			},
			getMutation: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/osbodevent/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("Student Class fetched...");
					self.loading = false;
				});
			}
			,
			SubmitTeacher: function() {
				var self = this;
				self.$router.push({
							path: "/osbodeventteacher"
						});
			},
			submitForm() {
				var self = this;
				this.formHasErrors = false;
				
				
				if (this.form.firstname == null || this.form.firstname == "") {
					this.formHasErrors = true;
					this.form.firstname = null;
				}
				if (this.form.surname == null || this.form.surname == "") {
					this.formHasErrors = true;
					this.form.surname = null;
				}
				if (this.form.birthdate == null || this.form.birthdate == "") {
					this.formHasErrors = true;
					this.form.birthdate = null;
				}
				
				if (this.form.government_id == null || this.form.government_id == "" || this.form.government_id.length >10  ) {
					this.formHasErrors = true;
					this.form.government_id = null;
				}
				if (this.form.tel_mobile == null || this.form.tel_mobile == ""|| this.form.tel_mobile.length >7) {
					this.formHasErrors = true;
					this.form.tel_mobile = null;
				}
				if (this.form.email == null || this.form.email == "") {
					this.formHasErrors = true;
					this.form.email = null;
				}

				if (this.form.registration_agree == null || this.form.registration_agree == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_agree = null;
				}
				if (this.form.registration_complete == null || this.form.registration_complete == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_complete = null;
					}
				console.log(this.form);
				if (this.formHasErrors == false) {
				if (this.form.id > 0) {

				ax.post("/osbodevent/create", this.form_cohort).then(function (response) {
					console.log("Student Chort...");
					//console.log(self.user.id);
					//console.log("2Audits update created...");
					console.log(response.data);
				});


					ax.post("/osbodevent/update/" + this.form.id, this.form).then(function (
						response
					) {
						console.log(response.data);

						console.log("Mutations update...");

						self.$router.push({
							path: "/bedanktosbodevent"
						});
					});
				} else {
					ax.post("/osbodevent/create", this.form).then(function (response) {
						console.log(response.data);
						self.$router.push({
							path: "/bedanktosbodevent"
						});
						console.log("Mutations created...");
					});
					
				}
				}
				//});
			}
		}
	};
</script>