import SchoolyearStudyCost from "../views/schooladmin/SchoolyearStudyCost";
import SchoolyearStudyCostAddEdit from "../views/schooladmin/SchoolyearStudyCostAddEdit";

export default [{
    path: "/schooladmin/schoolyearstudycost",
    components: {
      content: SchoolyearStudyCost
    }
  },
  {
    path: "/schooladmin/schoolyearstudycost/new",
    components: {
      content: SchoolyearStudyCostAddEdit
    }
  },
  {
    path: "/schooladmin/schoolyearstudycost/update/:id",
    components: {
      content: SchoolyearStudyCostAddEdit
    },
    props: {
      content: true
    }
  }
];