import StudentIntake from "../views/schooladmin/StudentIntake";
import StudentIntakeAddEdit from "../views/schooladmin/StudentIntakeAddEdit";

export default [{
    path: "/schooladmin/studentintake",
    components: {
      content: StudentIntake
    }
  },
  {
    path: "/schooladmin/studentintake/new",
    components: {
      content: StudentIntakeAddEdit
    }
  },
  {
    path: "/schooladmin/studentintake/update/:id",
    components: {
      content: StudentIntakeAddEdit
    },
    props: {
      content: true
    }
  }
];