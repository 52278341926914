<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit OSBOD Herinschrijving 2024-2025</span>
				<span class="pl-2" v-else>OSBOD Herinschrijving 2024-2025</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Instructies voor het herinschrijven voor het schooljaar 2024-2025</p>
						<p class="font-weight-regular">1. Vul je herinschrijvingscode in.</p>
						<p class="font-weight-regular">2. Vul je wachtwoord in.</p>
						<p class="font-weight-regular">3. Druk op de knop "Zoek" om je herinschrijving op te halen.</p>
						<p class="font-weight-regular">4. Indien je akkoord bent met je herinschrijving vink de 2 verplichte velden voor akkoord.</p>
						<p class="font-weight-regular">5. Druk op de knop "Herinschrijven" om je herinschrijving te bevestigen.</p>
					</v-col>

					<v-col cols="12" sm="6">

						<p class="font-weight-black title">Herinschrijving Code</p>
						<v-text-field dense v-model="formsearch.registration_code" label="Herinschrijving Code" filled></v-text-field>
						<v-text-field dense v-model="formsearch.registration_password" label="Wachtwoord" filled type="password"></v-text-field>
						<v-btn color="primary" @click="searchForm()" depressed>Zoek</v-btn>
						
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Leerling gegevens</p>
						<v-text-field dense v-model="form.osbod_email" label="Osbod-email" value="tbl_student.osbod_email" filled readonly ></v-text-field>
						<v-text-field dense v-model="form.firstname" label="Voornaam" filled readonly></v-text-field>
						<v-text-field dense v-model="form.surname" label="Achternaam" filled readonly></v-text-field>

					</v-col>


					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Herinschrijving</p>
						<v-select dense :items="registration_types" item-text="name" item-value="id"
							v-model="form.registration_type_id" filled label="Inschrijving/Herinschrijving" readonly></v-select>
						<v-select dense :items="schoolyears" item-text="name" item-value="id"
							v-model="form.schoolyear_id" filled label="Schoolyear" readonly></v-select>
						
						<v-select dense :items="studys" item-text="name" item-value="id"
							v-model="form.study_id" filled label="Opleiding" readonly></v-select>
						<!-- <v-select dense :items="employeess" item-text="display_name" item-value="id"
							v-model="form.student_id" filled label="Student" readonly ></v-select> -->
						<v-select dense :items="classs" item-text="name" item-value="id" v-model="form.class_id"
							filled label="Klas" readonly></v-select>
						<v-checkbox v-model="form.registration_complete"
										label="U verklaart dat alle herinschrijvingsinformatie volledig zijn ingevuld*"
										required :rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
						<v-checkbox v-model="form.registration_agree" required
										label="U verklaart akkoord te gaan met de inschrijf en betalingsvoorwaarden.*"
										:rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
					</v-col>
					
				</v-row>
			</v-form>
			<v-row><v-col cols="12" sm="6"></v-col><v-col cols="12" sm="6"><v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Herinschrijven</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions></v-col></v-row>
			
		</v-card-text>
		

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "mutationedit",
		props: ["id"],
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user")),
				errorMessages: '',
				formHasErrors: false,
				snackbar: false,
				schoolyears: [],
				classs: [],
				employeess: [],
				registration_types: [],
				mutation_types: [],
				mutation_months: [],
				mutation_starts: [],
				studys:[],
				users: [],
				student_class_projections:[],
				form: {
					id: 0,
					
					schoolyear_id: null,
					student_id: null,
					class_id: null,
					registration_type_id: null,
					study_id: null,
					registration_agree: "",
					registration_complete: "",
					osbod_email:null,
					first_name: null,
					surname:null,
					coordinator_projection:null,

				},
				formsearch:{
					registration_code:null,
					registration_password: null,
					id: 0,
				},
				form_cohort: {
					id: 0,
					schoolyear_id: null,
					student_id: null,
					study_id: null,
					registration_type_id: null,
				},
				rules: {
					required: value => !!value || 'Dit veld is verplicht!.'
				},
				
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			this.getClasses();
			this.getRegistration_type();
			this.getStudy();
			this.getEmployeesAll();
			if (this.id > 0) {
				this.getMutation(this.id);
			
			} 
		},
		watch: {
			"form.id":function(value){
				this.getMutation(value);
			}

		},
		methods: {
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/projection_all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},

			getClasses: function () {
				var self = this;

				ax.get("/school_class/all").then(function (response) {
					console.log(response.data);
					self.classs = response.data;
				});
			},
			getRegistration_type: function () {
				var self = this;

				ax.get("/registrationtype/all").then(function (response) {
					console.log(response.data);
					self.registration_types = response.data;
				});
			},
			getEmployees: function () {
				var self = this;

				ax.get("/student/allprojection", 
				).then(function (response) {
					console.log(response.data);
					self.employeess = response.data;
				});
			},
			getEmployeesAll: function () {
				var self = this;

				ax.get("/student/all", 
			).then(function (response) {
					console.log(response.data);
					self.employeess = response.data;
				});
			},
			getMutation_type: function () {
				var self = this;

				ax.get("/school_class/selected_study", {
				params: {
					study_id: self.form.study_id,
				}
			}).then(function (response) {
					console.log(response.data);
					self.mutation_types = response.data;
				});
			},getStudy: function () {
				var self = this;

				ax.get("/study/all").then(function (response) {
					console.log(response.data);
					self.studys = response.data;
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					
					self.users = response.data;
				});
			},
			getMutation: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/student_class_projection/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("Student Class fetched...");
					self.loading = false;
				});
			},
			searchForm: function() {
				var self = this;

				ax.get("/student_class_projection/searchform/", {
					params: {
						registration_code: self.formsearch.registration_code,
						registration_password: self.formsearch.registration_password
					}
				}).then(function (response) {
					console.log("Subject Before Fill")
					console.log(self.student_class_projections)
					//self.form = response.data;
					self.form = response.data;
					console.log("Subject After Fill")
					// console.log(self.student_class_projections);
					self.loading = true;
					
				});

			}
			,
			submitForm() {
				var self = this;
				this.formHasErrors = false;
				

				//this.$nextTick(function() {
''
				// ax.post("/student/update/" + this.form.student_id, this.form2).then(function (
				// 	response
				// ) {
				// 	console.log(response.data);

				// 	console.log("Plaatsing Update");

				// });
				if (this.form.registration_agree == null || this.form.registration_agree == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_agree = null;
				}
				if (this.form.registration_complete == null || this.form.registration_complete == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_complete = null;
					}
				console.log(this.form);
				if (this.formHasErrors == false) {
				if (this.form.id > 0) {

				this.form_cohort.schoolyear_id= this.form.schoolyear_id;
				this.form_cohort.student_id= this.form.student_id;
				// self.formaudit.class_id = this.form.class_id;
				// self.formaudit.user_id = this.user.id;
				// self.formaudit.registration_type_id = this.form.registration_type_id;
				this.form_cohort.study_id = this.form.study_id;
				this.form_cohort.registration_type_id =  2;
				console.log("Student Chort...");
				console.log(this.form_cohort.student_id);

				ax.post("/student_cohort/create", this.form_cohort).then(function (response) {
					console.log("Student Chort...");
					//console.log(self.user.id);
					//console.log("2Audits update created...");
					console.log(response.data);
				});


					ax.post("/student_class_projection/update/" + this.form.id, this.form).then(function (
						response
					) {
						console.log(response.data);

						console.log("Mutations update...");

						self.$router.push({
							path: "/bedanktherinschrijving"
						});
					});
				} else {
					ax.post("/student_class_projection/create", this.form).then(function (response) {
						console.log(response.data);
						self.$router.push({
							path: "/bedanktherinschrijving"
						});
						console.log("Mutations created...");
					});
					
				}
				}
				//});
			}
		}
	};
</script>