<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Employee Data</span>
				<span class="pl-2" v-else>Add new Employee</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<!-- <v-col cols="12" sm="6">
						<p class="font-weight-black title" >Schoolyear</p>
						<v-select
							dense
							:items="schoolyears"
							item-text="name"
							item-value="id"
							v-model="form.schoolyear_id"
							filled
							label="Schoolyear"
						></v-select>
					</v-col><v-col></v-col> -->
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >Employee Information</p>
						<v-text-field dense v-model="form.firstname" label="Firstname" filled></v-text-field>
						<v-text-field dense v-model="form.surname" label="Lastname" filled></v-text-field>
						<v-text-field dense v-model="form.maidenname" label="Maidenname" filled></v-text-field>
						<v-menu
							ref="menu"
							v-model="menu"
							:close-on-content-click="false"
							:return-value.sync="form.birthdate"
							transition="scale-transition"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="form.birthdate"
									label="Birthdate"
									prepend-inner-icon="mdi-calendar"
									readonly
									v-on="on"
									filled
								></v-text-field>
							</template>
							<v-date-picker v-model="form.birthdate" no-title scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.menu.save(form.birthdate)">OK</v-btn>
							</v-date-picker>
						</v-menu>
						<v-text-field dense v-model="form.government_id" label="Government ID" filled></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >.</p>
						<v-select
							dense
							:items="genders"
							item-text="name"
							item-value="id"
							v-model="form.gender_id"
							filled
							label="Geslacht"
						></v-select>
						<v-select
							dense
							:items="birthplaces"
							item-text="name"
							item-value="id"
							v-model="form.birthplace_id"
							filled
							label="Birthplace"
						></v-select>
						<v-select
							dense
							:items="maritial_statuss"
							item-text="name"
							item-value="id"
							v-model="form.maritial_status_id"
							filled
							label="Maritial status"
						></v-select>
						<v-text-field dense v-model="form.osbod_email" label="Osbod e-mail" filled></v-text-field>
						<v-text-field dense v-model="form.teacher_name" label="Teacher display name" filled></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >Bankdetails</p>
						<v-select
							dense
							:items="banktypes"
							item-text="name"
							item-value="id"
							v-model="form.bank_type_id"
							filled
							label="Bank"
						></v-select>
						<v-text-field dense v-model="form.acctbnk" label="Account number" filled></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >Employment Information</p>
						<v-select
							dense
							:items="functions"
							item-text="name"
							item-value="id"
							v-model="form.function_id"
							filled
							label="Function"
						></v-select>
						<v-text-field dense v-model="form.my_email_coordinator" label="N-O-P absentie e-mail" filled></v-text-field>
						<v-checkbox v-model="form.employee_active" label="Employee Visible"></v-checkbox>
					</v-col>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title" >Data Check</p>
						<v-select
							dense
							:items="users"
							item-text="username"
							item-value="username"
							v-model="form.firstcheckby"
							filled
							label="First check by"
						></v-select>
						<v-select
							dense
							:items="users"
							item-text="username"
							item-value="username"
							v-model="form.secondcheckby"
							filled
							label="Second check by"
						></v-select>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "employeeedit",
	props: ["id"],
	data() {
		return {
			user: localStorage.getItem("user"),
			snackbar: false,
			schoolyears:[],
			birthplaces:[],
			genders:[],
			maritial_statuss:[],
			banktypes: [],
			users:[],
			functions:[],
			menu: false,
			form: {
				id: 0,
				schoolyear_id: null,
				firstname:"",
				surname: "",
				maidenname: "",
				osbod_email:"",
				birthdate:"",
				government_id:"",
				gender_id:null,
				birthplace_id: null,
				maritial_status_id: null,
				bank_type_id: null,
				acctbnk:"",
				function_id:null,
				firstcheckby: "",
				secondcheckby: "",
				employee_active: "",
				display_name:"",
				teacher_name:"",
				my_email_coordinator:"",
			}
		};
	},
	created: function() {
		this.getUsers();
		this.getSchoolyears();
		this.getBirthplaces();
		this.getGenders();
		this.getMaritialstatus();
		this.getFunctions();
		this.getBanktypes();
		if (this.id > 0) {
			this.getEmployee(this.id);
		}
	},
	watch: {

	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		getSchoolyears: function() {
			var self = this;

			ax.get("/schoolyear/all").then(function(response) {
				console.log(response.data);
				self.schoolyears = response.data;
			});
		},
		getBirthplaces: function() {
			var self = this;

			ax.get("/birthplace/all").then(function(response) {
				console.log(response.data);
				self.birthplaces = response.data;
			});
		},
		getGenders: function() {
			var self = this;

			ax.get("/gender/all").then(function(response) {
				console.log(response.data);
				self.genders = response.data;
			});
		},
		getMaritialstatus: function() {
			var self = this;

			ax.get("/maritial_status/all").then(function(response) {
				console.log(response.data);
				self.maritial_statuss = response.data;
			});
		},getFunctions: function() {
			var self = this;

			ax.get("/functions/all").then(function(response) {
				console.log(response.data);
				self.functions = response.data;
			});
		},
		getBanktypes: function() {
			var self = this;

			ax.get("/banktype/all").then(function(response) {
				console.log(response.data);
				self.banktypes = response.data;
			});
		},
		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				console.log(response.data);
				self.users = response.data;
			});
		},
		getEmployee: function(id) {
			var self = this;

			this.loading = true;
			ax.get("/employee/single/" + id).then(function(response) {
				// console.log(response.data);
				self.form = response.data;
				console.log("Employees fetched...");
				self.loading = false;
			});
		},
		submitForm() {
			var self = this;
			//this.$nextTick(function() {
			console.log(this.form);
			if (this.form.maritial_status_id == 1) { if(this.form.gender_id == 2){
					this.form.display_name = this.form.surname + " e/v " +  this.form.maidenname+ ", "+  this.form.firstname ;}
					else {
						this.form.display_name = this.form.surname + ", " + this.form.firstname;
					}
				} else {
					this.form.display_name = this.form.surname + ", " + this.form.firstname;
				}
			if (this.form.id > 0) {
				ax.post("/employee/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Employees update...");

					self.$router.push({
						path: "/schooladmin/employee"
					});
				});
			} else {
				ax.post("/employee/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/employee"
					});
					console.log("Employees created...");
				});
			}
			//});
		}
	}
};
</script>
