import OsbodEventTeacher from "../views/schooladmin/OsbodEvent";
import OsbodEventTeacherAddEdit from "../views/schooladmin/OsbodEventTeacherAddEdit";

export default [{
    path: "/schooladmin/osbodevent",
    components: {
      content: OsbodEventTeacher
    }
  },
  {
    path: "/schooladmin/osbodevent/new",
    components: {
      content: OsbodEventTeacherAddEdit
    }
  },

  {
    path: "/schooladmin/osbodevent/update/:id",
    components: {
      content: OsbodEventTeacherAddEdit
    },
    props: {
      content: true
    }
  }
];