import StudentClassCursus from "../views/schooladmin/StudentClassCursus";
import StudentClassCursusAddEdit from "../views/schooladmin/StudentClassCursusAddEdit";

export default [{
    path: "/schooladmin/student_class_cursus",
    components: {
      content: StudentClassCursus
    }
  },
  {
    path: "/schooladmin/student_class_cursus/new",
    components: {
      content: StudentClassCursusAddEdit
    }
  },
  {
    path: "/schooladmin/student_class_cursus/update/:id",
    components: {
      content: StudentClassCursusAddEdit
    },
    props: {
      content: true
    }
  }
];