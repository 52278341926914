import Subject from "../views/schooladmin/Subject";
import SubjectAddEdit from "../views/schooladmin/SubjectAddEdit";

export default [{
    path: "/schooladmin/subject",
    components: {
      content: Subject
    }
  },
  {
    path: "/schooladmin/subject/new",
    components: {
      content: SubjectAddEdit
    }
  },
  {
    path: "/schooladmin/subject/update/:id",
    components: {
      content: SubjectAddEdit
    },
    props: {
      content: true
    }
  }
];