<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Opleiding</span>
				<span class="pl-2" v-else>Add Opleiding</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<v-col cols="12" sm="6">
						<!-- <p class="font-weight-black title" >School info</p> -->
						<v-select
							dense
							:items="sectors"
							item-text="name"
							item-value="id"
							v-model="form.sector_id"
							filled
							label="Sector"
						></v-select>
						<v-select
							dense
							:items="levels"
							item-text="name"
							item-value="id"
							v-model="form.level_id"
							filled
							label="Niveau"
						></v-select>
						<v-text-field dense v-model="form.name" label="Opleiding"
											filled>
										</v-text-field>
						<v-text-field dense v-model="form.name_pap" label="Estudio "
											filled>
										</v-text-field>
						<v-text-field dense v-model="form.registration_cost" label="Inschrijfgeld"
											filled>
										</v-text-field>
						<v-text-field dense v-model="form.study_cost" label="Schoolgeld"
											filled>
										</v-text-field>
						<v-text-field dense v-model="form.material_cost" label="Materiaalkosten"
											filled>
										</v-text-field>
						<v-text-field dense v-model="form.email_coordinator" label="Email coordinator"
											filled>
										</v-text-field>
						<v-checkbox      v-model="form.publish"  value="Ja"  label="Tonen op inschrijfformulier"
    ></v-checkbox>
						<v-checkbox      v-model="form.publish_event"  value="Ja"  label="Tonen op Event"
    ></v-checkbox>
						<v-checkbox      v-model="form.publish_course"  value="Ja"  label="Tonen op cursus"
    ></v-checkbox>
						<v-select
							dense
							:items="users"
							item-text="display_name"
							item-value="id"
							v-model="form.user_id"
							filled
							label="Coordinator"
						></v-select>
						</v-col> 
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "mutationedit",
	props: ["id"],
	data() {
		return {
			user: localStorage.getItem("user"),
			snackbar: false,
			users:[],
			levels:[],
			form: {
				id: 0,
				sector_id: null,
				level_id: null,
				name: null,
				name_pap: null,
				registration_cost: null,
				study_cost: null,
				material_cost: null,
				publish: null,
				publish_event: null,
				publish_course: null,
				email_coordinator: null,
				user_id: null,
			}
		};
	},
	created: function() {
		this.getUsers();
		this.getSectors();
		this.getLevels();
		
		if (this.id > 0) {
				this.getStudy(this.id);
			}
	},
	watch: {
		
	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		getSectors: function() {
			var self = this;

			ax.get("/sector/all").then(function(response) {
				console.log(response.data);
				self.sectors = response.data;
			});
		},
				getLevels: function() {
			var self = this;

			ax.get("/level/all").then(function(response) {
				console.log(response.data);
				self.levels = response.data;
			});
		},

		getStudy: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/study/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					self.loading = false;
				});
			},

		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				console.log(response.data);
				self.users = response.data;
			});
		},
		submitForm() {
			var self = this;

			//this.$nextTick(function() {
			if (this.form.id > 0) {
				ax.post("/study/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Mutations update...");

					self.$router.push({
						path: "/schooladmin/study"
					});
				});
			} else {
				ax.post("/study/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/study"
					});
					console.log("Mutations created...");
				});
			}
			//});
		}
	}
};
</script>
