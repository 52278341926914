import StudentClass from "../views/schooladmin/StudentClass";
import StudentClassAddEdit from "../views/schooladmin/StudentClassAddEdit";

export default [{
    path: "/schooladmin/student_class",
    components: {
      content: StudentClass
    }
  },
  {
    path: "/schooladmin/student_class/new",
    components: {
      content: StudentClassAddEdit
    }
  },
  {
    path: "/schooladmin/student_class/update/:id",
    components: {
      content: StudentClassAddEdit
    },
    props: {
      content: true
    }
  }
];