import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Axios from 'axios';
import {
  store
} from './store';
import {
  API_ROOT
} from "./config";
import vuetify from './plugins/vuetify';

var selected_system = "osbod_salary";
window.active_system = selected_system;
window._ = require('lodash');
window.S = require('string');

window.ax = Axios.create({
  baseURL: API_ROOT["osbod_salary"],
  timeout: 60000
});


Vue.config.productionTip = false;

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.substr(position, searchString.length) === searchString;
  };
}
/* eslint-disable no-new */

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
