<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Student Finance Transaction</span>
				<span class="pl-2" v-else>Add Student Finance Transaction</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<v-col cols="12" sm="6">
						<!-- <p class="font-weight-black title" >School info</p> -->
						<v-select
							dense
							:items="students"
							item-text="display_name"
							item-value="id"
							v-model="form.student_id"
							filled
							label="Student"
							readonly
						></v-select>
						
						
						<v-select
							dense
							:items="student_classs"
							item-text="tbl_sys_schoolyear.name"
							item-value="tbl_sys_schoolyear.id"
							v-model="form.schoolyear_id"
							filled
							label="Schoolyear"
						></v-select>
						<v-select
							dense
							:items="student_class_years"
							item-text="tbl_sys_study.name"
							item-value="tbl_sys_study.id"
							v-model="form.study_id"
							filled
							label="Opleiding"
						></v-select>
						<v-select
							dense
							:items="student_class_years"
							item-text="tbl_sys_class.name"
							item-value="tbl_sys_class.id"
							v-model="form.class_id"
							filled
							label="Klas"
						></v-select>
						<v-menu ref="menu_reg_datum" v-model="menu_reg_datum" :close-on-content-click="false"
							:return-value.sync="form.registration_date" transition="scale-transition" offset-y
							min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="form.registration_date" label="Transactie datum"
									prepend-inner-icon="mdi-calendar" readonly v-on="on" filled></v-text-field>
							</template>
							<v-date-picker v-model="form.registration_date" no-title scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu_reg_datum = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.menu_reg_datum.save(form.registration_date)">OK</v-btn>
							</v-date-picker>
						</v-menu>
						<v-select
							dense
							:items="finance_tasks"
							item-text="name"
							item-value="id"
							v-model="form.finance_task_id"
							filled
							label="Kostenplaat"
						></v-select>
						<v-select
							dense
							:items="transaction_types"
							item-text="name"
							item-value="id"
							v-model="form.transaction_type_id"
							filled
							label="Transactie"
						></v-select>
						
						<v-text-field  dense v-model="form.amount" label="Bedrag" filled>
									</v-text-field>
						<v-checkbox color="red" v-model="form.deleted">
								<template v-slot:label>
									<div><strong class="error--text">Delete</strong></div>
								</template>
							</v-checkbox>
					</v-col> 
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "mutationedit",
	props: ["id"],
	data() {
		return {
			user: JSON.parse(localStorage.getItem("user")),
			student: JSON.parse(localStorage.getItem("student")),
			snackbar: false,
			menu_reg_datum: false,
			students:[],
			sectors:[],
			studys:[],
			studycosts:[],
			finance_tasks:[],
			transaction_types:[],
			schoolyears: [],
			student_classs: [],
			student_class_years: [],
			users:[],
			form: {
				id: 0,
				schoolyear_id: null,
				study_id: null,
				class_id: null,
				student_id: null,
				transacation_type_id: null,
				finance_task_id: null,
				registration_date: null,
				amount:"",
				deleted: null,
				deleted_by_id: null
				
				
			}
		};
	},
	created: function() {
		this.getUsers();
		this.getTransactionType();
		this.getSectors();
		this.getFinanceTask();
		this.getSchoolyears();
		if (this.id > 0) {
				this.getSchoolyearStudyCost(this.id);
				this.getStudents();
			}
		else {
				console.log("this.user")
				console.log(this.$route.query.my_selected_student_id);
				this.form.student_id = this.$route.query.my_selected_student_id;
				console.log(this.form.student_id);
				this.getStudentSingle();
			}
	},
	watch: {
		"form.schoolyear_id": function(value){
				this.getStudentClassYear(value);
				// this.getClass1(value);
				
			},
			"form.student_id": function(value){
				this.getStudentClass(value);
				// this.getClass1(value);
				
			},
			

	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		getStudents: function() {
			var self = this;

			ax.get("/student/all").then(function(response) {
				console.log("All students");
				console.log(response.data);
				self.students = response.data;
			});
		},
		getSectors: function() {
			var self = this;

			ax.get("/sector/all").then(function(response) {
				console.log(response.data);
				self.sectors = response.data;
			});
		},
		getStudy: function() {
			var self = this;

			ax.get("/study/selected_sector/", {
				params: {
					sector_id: self.form.sector_id,
				}
			}).then(function(response) {
				self.studys = response.data;
				console.log("After Fill");
	
			});
			
			
		},
		getDebitTransaction: function() {
			var self = this;

			ax.get("/schoolyear_study_cost/study_cost_transaction/", {
				params: {
					study_id: self.form.study_id,
					schoolyear_id: self.form.schoolyear_id,
					finance_task_id: self.form.finance_task_id,
				}
			}).then(function(response) {
				self.studycosts = response.data;
				console.log("After Fill");
	
			});
			
			
		},
				getStudentClass: function() {
			var self = this;

			ax.get("/student_class/all_finance_student/", {
				params: {
					student_id: self.form.student_id,
				}
			}).then(function(response) {
				self.student_classs = response.data;
				console.log("After Fill Student Class");
				console.log(response.data);
	
			});
			
			
		},
		getStudentClassYear: function() {
			var self = this;

			ax.get("/student_class/all_finance_student_year/", {
				params: {
					student_id: self.form.student_id,
					schoolyear_id: self.form.schoolyear_id,
				}
			}).then(function(response) {
				self.student_class_years = response.data;
				console.log("After Fill Student Class");
				console.log(response.data);
	
			});
			
			
		},
		getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/finance_all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
		getSchoolyearStudyCost: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/student_finance/single/" + id).then(function (response) {
					console.log("I do what i am told");
					self.form = response.data;
					self.loading = false;
					console.log(response.data);
				});
			},
		getStudentSingle: function() {
				var self = this;

				this.loading = true;
				ax.get("/student/single/" + this.form.student_id).then(function (response) {
					console.log("All students");
				console.log(response.data);
				self.students = response.data;
				});
			},
		getFinanceTask: function() {
			var self = this;

			ax.get("/finance_task/all").then(function(response) {
				console.log("FinanceTask Fill")
				console.log(response.data);
				self.finance_tasks = response.data;
			});
		},
		getTransactionType: function() {
			var self = this;

			ax.get("/transaction_type/all").then(function(response) {
				console.log("TransactionType Fill")
				console.log(response.data);
				self.transaction_types = response.data;
			});
		},
		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				// console.log(response.data);
				self.users = response.data;
			});
		},
		submitForm() {
			var self = this;
			var selected_student_id = this.form.student_id;
			this.form.deleted_by_id = this.user.id;

			//this.$nextTick(function() {
			if (this.form.id > 0) {
				ax.post("/student_finance/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Mutations update...");

					self.$router.push({
						path: "/schooladmin/financestudentview/update/"+ selected_student_id
					});
				});
			} else {
				ax.post("/student_finance/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/financestudentview/update/" + selected_student_id
					});
					console.log("Mutations created...");
				});
			}
			//});
		}
	}
};
</script>
