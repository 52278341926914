<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2">OSBOD Finance Plaatsing</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details>
			</v-text-field>
		</v-card-title>

		<v-data-table :headers="headers" :items="data" :search="search" :loading="loading">
			<template v-slot:top>
				<v-toolbar dark dense color="primary" flat class="mb-1">
					<!-- <v-btn depressed class="ml-2" @click="setStudent()" color="inverted">
						<v-icon class="pr-2">mdi-plus-circle</v-icon>Add Student Class
					</v-btn> -->
				</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
				<v-icon @click="handleClick(item.id)">mdi-account-edit</v-icon>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
	import Axios from "axios";
	import moment from 'moment';
	//import _ from "lodash";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "students",
		data() {
			return {
				//user: localStorage.getItem("user"),
				search: "",
				headers: [{
						text: "",
						align: "center",
						sortable: false,
						value: "action"
					},
					{ text: "Mutatie", value: "tbl_sys_registration_type.name" },
				{ text: "Schoolyear", value: "tbl_sys_schoolyear.name" },
				{ text: "Government ID", value: "tbl_student.government_id" },
				{ text: "Firstname", value: "tbl_student.firstname" },
				{ text: "Lastname", value: "tbl_student.surname" },
				{ text: "Study", value: "tbl_sys_study.name" },
				{ text: "Class", value: "tbl_sys_class.name" },
				{ text: "Mobiel", value: "tbl_student.tel_mobile" },
				{ text: "E-mail", value: "tbl_student.email" },
				{ text: "E-mail OSBOD", value: "tbl_student.osbod_email" },
					// { text: "Active", value: "tbl_student.student_active" }

				],
				// semesters: [],
				// studies: [],
				// subjects: [],
				// subjecttypes: [],
				// selected_subject: null,
				// selected_kwalificatie: null,
				// selected_deelkwalificatie: null,
				// selected_semester: null,
				// schoolyear: [],
				// selected_schoolyear: null,
				data: [],
				// banktypes: [],
				isEmpty: false,
				loading: false,
				isPaginated: true,
				isPaginationSimple: false,
				defaultSortDirection: "asc",
				currentPage: 1,
				perPage: 15
			};
		},
		created: function () {
			// this.getBanktypes();
			this.getStudents();
			this.format_date();
		},
		watch: {
			// selected_kwalificatie: function(value) {
			// 	this.getStudySubjecttype(value);
			// 	console.log(value);
			// }
		},
		computed: {


		},
		methods: {
			handleClick(id) {
				this.$router.push({
					path: "/schooladmin/financestudentplacement/update/" + id
				});
			},
			format_date(value) {
				if (value) {
					return moment(String(value)).format('YYYYMMDD')
				}
			},
			setStudent: function () {
				this.$router.push({
					path: "/schooladmin/financestudentplacement/new"
				});
			},
			getStudents: function () {
				var self = this;
				this.loading = true;
				ax.get("/student_class/all_finance").then(function (response) {
					// console.log(response.data);
					self.data = response.data;
					console.log("Students fetched...");
					self.loading = false;
				});
			},
			// getBanktypes: function() {
			// 	var self = this;
			// 	ax.get("/banktype/all").then(function(response) {
			// 		console.log(response.data);
			// 		self.banktypes = response.data;
			// 	});
			// }
		}
	};
</script>

<!-- Add "slot-scoped" attribute to limit CSS to this component only -->
<style slot-scoped>
</style>