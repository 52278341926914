import Vue from 'vue';
import Vuex from 'vuex';
import Login from '@/store/modules/login';
//import School from '@/store/modules/school';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {     
     login: Login
     //school: School
  }
});
