import StudentRegistrationAdmin from "../views/schooladmin/StudentRegistrationAdmin";
import StudentRegistrationAdminAddEdit from "../views/schooladmin/StudentRegistrationAdminAddEdit";

export default [{
    path: "/schooladmin/studentregistrationadmin",
    components: {
      content: StudentRegistrationAdmin
    }
  },
  {
    path: "/schooladmin/studentregistrationadmin/new",
    components: {
      content: StudentRegistrationAdminAddEdit
    }
  },
  {
    path: "/schooladmin/studentregistrationadmin/update/:id",
    components: {
      content: StudentRegistrationAdminAddEdit
    },
    props: {
      content: true
    }
  }
];