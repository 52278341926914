<template>
	<div id="level" class="columns">
		<div class="column">
			<div class="card">
				<header class="card-header">
					<section class="card-header-title">Master Data Rollen</section>
				</header>
				<header class="card-header" style="background-color:#eeeeee;padding: 12px;">
					<nav class="level">
						<b-field>
							<a class="button is-primary" @click="setRoles()">
								<b-icon icon="plus-circle-outline"></b-icon>
								<span>Rol Toevoegen</span>
							</a>
						</b-field>
					</nav>
				</header>
				<div class="section">
					<b-table
						:data="roles"
						:bordered="false"
						:narrowed="true"
						:striped="true"
						:loading="loading"
						:paginated="isPaginated"
						:per-page="perPage"
						:current-page.sync="currentPage"
						:pagination-simple="isPaginationSimple"
						:default-sort-direction="defaultSortDirection"
					>
						<template slot-scope="props">
							<b-table-column>
								<a class="button icon is-text" @click="handleClick(props.row.id)">
									<b-icon icon="lead-pencil"></b-icon>
								</a>
							</b-table-column>

							<b-table-column field="name" label="Rol">{{ props.row.name }}</b-table-column>
						</template>

						<template slot="empty">
							<section class="section">
								<div class="content has-text-grey has-text-centered">
									<p>
										<b-icon icon-pack="far" icon="frown" size="is-large"></b-icon>
									</p>
									<p>Nothing here.</p>
								</div>
							</section>
						</template>
					</b-table>
				</div>
			</div>
		</div>
	</div>
</template>
 
 <script>
import Axios from "axios";
import _ from "lodash";
import { API_ROOT } from "../../config";

console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "school_user",
	props: ["type"],
	data() {
		return {
			roles: [],
			user: JSON.parse(localStorage.getItem("user")),
			loading: false,
			isEmpty: false,
			isPaginated: true,
			isPaginationSimple: false,
			defaultSortDirection: "asc",
			currentPage: 1,
			perPage: 10
		};
	},
	created: function() {
		this.getRoles();
	},
	watch: {
		type: function(obj) {
			console.log(obj);
			this.getRoles();
		}
	},
	computed: {
		title() {
			return _.capitalize(this.type);
		},
		school() {
			return this.$store.getters.getSchool;
		},
		schooltype() {
			return this.$store.getters.getSchoolType;
		}
	},
	methods: {
		handleClick(id) {
			this.$router.push({
				path: "/roles/update/" + id
			});
		},
		getRoles() {
			var self = this;
			
			self.loading = true;
			ax.get("/roles").then(function(response) {
				self.roles = response.data;
				console.log("Centraal levels fetched...");

				self.loading = false;
			});
		},
		setRoles() {
			this.$router.push({
				path: "/roles/new"
			});
		}
	}
};
</script>