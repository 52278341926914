import Roles from "../views/masterdata/Roles";
import RolesAddEdit from "../views/masterdata/RolesAddEdit";

export default [{
        path: "/roles/update/:id",
        components: {
            content: RolesAddEdit
        },
        props: {
            content: true
        }
    },
    {
        path: "/roles/new/",
        components: {
            content: RolesAddEdit
        },
        props: {
            content: true
        }
    },
    {
        path: "/roles",
        components: {
            content: Roles
        }
    }
];