import EmployeeTimesheet from "../views/schooladmin/EmployeeTimesheet";
import EmployeeTimesheetAddEdit from "../views/schooladmin/EmployeeTimesheetAddEdit";

export default [{
    path: "/schooladmin/employeetimesheet",
    components: {
      content: EmployeeTimesheet
    }
  },
  {
    path: "/schooladmin/employeetimesheet/new",
    components: {
      content: EmployeeTimesheetAddEdit
    }
  },
  {
    path: "/schooladmin/employeetimesheet/update/:id",
    components: {
      content: EmployeeTimesheetAddEdit
    },
    props: {
      content: true
    }
  }
];