import StudentCohort from "../views/schooladmin/StudentCohort";
import StudentCohortAddEdit from "../views/schooladmin/StudentCohortAddEdit";

export default [{
    path: "/schooladmin/student_cohort",
    components: {
      content: StudentCohort
    }
  },
  {
    path: "/schooladmin/student_cohort/new",
    components: {
      content: StudentCohortAddEdit
    }
  },
  {
    path: "/schooladmin/student_cohort/update/:id",
    components: {
      content: StudentCohortAddEdit
    },
    props: {
      content: true
    }
  }
];