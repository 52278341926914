import StudentClassAudit from "../views/schooladmin/StudentClassAudit";
import StudentClassAuditAddEdit from "../views/schooladmin/StudentClassAuditAddEdit";

export default [{
    path: "/schooladmin/student_class_audit",
    components: {
      content: StudentClassAudit
    }
  },
  {
    path: "/schooladmin/student_class_audit/new",
    components: {
      content: StudentClassAuditAddEdit
    }
  },
  {
    path: "/schooladmin/student_class_audit/update/:id",
    components: {
      content: StudentClassAuditAddEdit
    },
    props: {
      content: true
    }
  }
];