import Exam from "../views/schooladmin/Exam";
import ExamAddEdit from "../views/schooladmin/ExamAddEdit";

export default [{
    path: "/schooladmin/exam",
    components: {
      content: Exam
    }
  },
  {
    path: "/schooladmin/exam/new",
    components: {
      content: ExamAddEdit
    }
  },
  {
    path: "/schooladmin/exam/update/:id",
    components: {
      content: ExamAddEdit
    },
    props: {
      content: true
    }
  }
];