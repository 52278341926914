import StudentClassCoordinator from "../views/schooladmin/StudentClassCoordinator";
import StudentClassCoordinatorAddEdit from "../views/schooladmin/StudentClassCoordinatorAddEdit";

export default [{
    path: "/schooladmin/student_class_coordinator",
    components: {
      content: StudentClassCoordinator
    }
  },
  {
    path: "/schooladmin/student_class_coordinator/new",
    components: {
      content: StudentClassCoordinatorAddEdit
    }
  },
  {
    path: "/schooladmin/student_class_coordinator/update/:id",
    components: {
      content: StudentClassCoordinatorAddEdit
    },
    props: {
      content: true
    }
  }
];