import AdminEmployeeSignUp from "../views/schooladmin/AdminEmployeeSignUp";
import AdminEmployeeSignUpAddEdit from "../views/schooladmin/AdminEmployeeSignUpAddEdit";

export default [
  {
    path: "/schooladmin/adminemployeesignup",
    components: {
      content: AdminEmployeeSignUp
    }
  },
  {
    path: "/schooladmin/adminemployeesignup/new",
    components: {
      content: AdminEmployeeSignUpAddEdit
    }
  },
  {
    path: "/schooladmin/adminemployeesignup/update/:id",
    components: {
      content: AdminEmployeeSignUpAddEdit
    },
    props: {
      content: true
    }
  }
];