import AdminTimesheetOvertake from "../views/schooladmin/AdminTimesheetOvertake";
import AdminTimesheetOvertakeAddEdit from "../views/schooladmin/AdminTimesheetOvertakeAddEdit";

export default [{
    path: "/schooladmin/admin_timesheet_overtake",
    components: {
      content: AdminTimesheetOvertake
    }
  },
  {
    path: "/schooladmin/admin_timesheet_overtake/new",
    components: {
      content: AdminTimesheetOvertakeAddEdit
    }
  },
  {
    path: "/schooladmin/admin_timesheet_overtake/update/:id",
    components: {
      content: AdminTimesheetOvertakeAddEdit
    },
    props: {
      content: true
    }
  }
];