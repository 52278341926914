import StudentRegistrationIntake from "../views/schooladmin/StudentRegistrationIntake";
import StudentRegistrationIntakeAddEdit from "../views/schooladmin/StudentRegistrationIntakeAddEdit";

export default [{
    path: "/schooladmin/studentregistrationintake",
    components: {
      content: StudentRegistrationIntake
    }
  },
  {
    path: "/schooladmin/studentregistrationintake/new",
    components: {
      content: StudentRegistrationIntakeAddEdit
    }
  },
  {
    path: "/schooladmin/studentregistrationintake/update/:id",
    components: {
      content: StudentRegistrationIntakeAddEdit
    },
    props: {
      content: true
    }
  }
];