import TeacherAbsentStudentView from "../views/schooladmin/TeacherAbsentStudentView";
// import TeacherAbsentStudentViewAddEdit from "../views/schooladmin/TeacherAbsentStudentViewAddEdit";

export default [{
    path: "/schooladmin/teacher_absent_student_view",
    components: {
      content: TeacherAbsentStudentView
    }
  },
  // {
  //   path: "/schooladmin/teacher_absent/new",
  //   components: {
  //     content: TeacherAbsentStudentViewAddEdit
  //   }
  // },

  // {
  //   path: "/schooladmin/teacher_absent/update/:id",
  //   components: {
  //     content: TeacherAbsentStudentViewAddEdit
  //   },
  //   props: {
  //     content: true
  //   }
  // }
];