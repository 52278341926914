import AdminTaskOvertake from "../views/schooladmin/AdminTaskOvertake";
import AdminTaskOvertakeAddEdit from "../views/schooladmin/AdminTaskOvertakeAddEdit";

export default [{
    path: "/schooladmin/admin_task_overtake",
    components: {
      content: AdminTaskOvertake
    }
  },
  {
    path: "/schooladmin/admin_task_overtake/new",
    components: {
      content: AdminTaskOvertakeAddEdit
    }
  },
  {
    path: "/schooladmin/admin_task_overtake/update/:id",
    components: {
      content: AdminTaskOvertakeAddEdit
    },
    props: {
      content: true
    }
  }
];