<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit Employee Cohort</span>
				<span class="pl-2" v-else>Add Employee Cohort</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					<v-col cols="12" sm="6">
						<p class="font-weight-black title">Instructies voor het toevoegen van een werknemer</p>
						<p class="font-weight-regular">1.Kies schooljaar.</p>
						<p class="font-weight-regular">2.Kies werknemer.</p>
						
					</v-col>
					<v-col cols="12" sm="6"></v-col>
					<v-col cols="12" sm="6">

						<p class="font-weight-black title">Inchrijving informatie</p>
						<v-select dense :items="schoolyears" item-text="name" item-value="id"
							v-model="form.schoolyear_id" filled label="Schoolyear"></v-select>
						<v-select dense :items="employeess" item-text="teacher_name" item-value="id"
							v-model="form.employee_id" filled label="Employee"></v-select>
						<v-checkbox color="red" v-model="form.deleted">
								<template v-slot:label>
									<div><strong class="error--text">Delete</strong></div>
								</template>
							</v-checkbox>
					</v-col>
					
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "mutationedit",
		props: ["id"],
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user")),
				snackbar: false,
				schoolyears: [],
				employeess: [],
				users: [],
				form: {
					id: 0,
					schoolyear_id: null,
					employee_id: null,
					deleted: null,
					deleted_by_id: null,
				},

			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			this.getEmployeesAll();
			if (this.id > 0) {
				this.getEmployeeCohort(this.id);
			} 
		},
		watch: {

		},
		methods: {
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/projection_all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},

			
			getEmployeesAll: function () {
				var self = this;

				ax.get("/employee/all_active", 
			).then(function (response) {
					console.log(response.data);
					self.employeess = response.data;
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getEmployeeCohort: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/employee_cohort/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("Employee Cohort fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				if (this.form.id > 0) {

					ax.post("/employee_cohort/update/" + this.form.id, this.form).then(function (
						response
					) {
						console.log(response.data);

						console.log("EmployeeCohorts update...");

						self.$router.push({
							path: "/schooladmin/employee_cohort"
						});
					});
				} else {
					ax.post("/employee_cohort/create", this.form).then(function (response) {
						console.log(response.data);
						self.$router.push({
							path: "/schooladmin/employee_cohort"
						});
						console.log("EmployeeCohorts created...");
					});
				}
				//});
			}
		}
	};
</script>