<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2">OSBOD Absentie Registratie Systeem</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-title>
		</v-card-title>
		<v-card-text>
			<p class="font-weight-bold">Bevestiging afmelding Docent</p>
			<p class="font-weight-regular">Beste heer/mevrouw,</p>
			<p class="font-weight-regular">
					
				Hierbij bevestigen wij de ontvangst van uw afmelding.
				De administratie zal binnenkort contact met u opnemen voor de afhandeling van uw afmelding.</p>
			
			<p class="font-weight-regular">
				Met vriendelijke groet,</p>
			<p></p>
			<p class="font-weight-regular">

				OSBOD Administratie
			</p>
			<a href="http://www.osbod.org">Terug naar website OSBOD</a>
		</v-card-text>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "userregistrations",
		data() {
			return {
				//user: localStorage.getItem("user"),
				search: "",
				headers: [{
						text: "",
						align: "center",
						sortable: false,
						value: "action"
					},
					{
						text: "Schoolyear",
						value: "tbl_sys_schoolyear.name"
					},
					{
						text: "Study",
						value: "tbl_sys_study.name"
					},
					{
						text: "Intake date",
						value: "appointment_date"
					},
					{
						text: "Intake time",
						value: "appointment_time"
					},
					{
						text: "Government ID",
						value: "government_id"
					},
					{
						text: "Student",
						value: "display_name"
					},
					// { text: "Active", value: "tbl_userregistration.userregistration_active" }

				],
				// semesters: [],
				// studies: [],
				// subjects: [],
				// subjecttypes: [],
				// selected_subject: null,
				// selected_kwalificatie: null,
				// selected_deelkwalificatie: null,
				// selected_semester: null,
				// schoolyear: [],
				// selected_schoolyear: null,
				data: [],
				// banktypes: [],
				isEmpty: false,
				loading: false,
				isPaginated: true,
				isPaginationSimple: false,
				defaultSortDirection: "asc",
				currentPage: 1,
				perPage: 15
			};
		},
		created: function () {
			// this.getBanktypes();
			this.getStudentIntakes();
		},
		watch: {
			// selected_kwalificatie: function(value) {
			// 	this.getStudySubjecttype(value);
			// 	console.log(value);
			// }
		},
		computed: {
			// school() {
			// 	return this.$store.getters.getSchool;
			// }
		},
		methods: {
			handleClick(id) {
				this.$router.push({
					path: "/schooladmin/studentregistration/update/" + id
				});
			},
			setStudentIntake: function () {
				this.$router.push({
					path: "/schooladmin/studentregistration/new"
				});
			},
			getStudentIntakes: function () {
				var self = this;
				this.loading = true;
				ax.get("/userregistration/all").then(function (response) {
					// console.log(response.data);
					self.data = response.data;
					console.log("StudentIntakes fetched...");
					console.log(self.data);
					self.loading = false;
				});
			},
			// getBanktypes: function() {
			// 	var self = this;
			// 	ax.get("/banktype/all").then(function(response) {
			// 		console.log(response.data);
			// 		self.banktypes = response.data;
			// 	});
			// }
		}
	};
</script>

<!-- Add "slot-scoped" attribute to limit CSS to this component only -->
<style slot-scoped>
</style>