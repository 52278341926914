<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2">OSBOD Plaatsingen Tracking</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details>
			</v-text-field>
		</v-card-title>

		<v-data-table :headers="headers" :items="data" :search="search" :loading="loading">
			<template v-slot:top>
				<v-toolbar dark dense color="primary" flat class="mb-1">
					<!-- <v-btn depressed class="ml-2" @click="setStudentClass()" color="inverted">
						<v-icon class="pr-2">mdi-plus-circle</v-icon>Add StudentClass
					</v-btn> -->
				</v-toolbar>
			</template>
			<!--<template v-slot:item.action="{ item }">
				<v-icon @click="handleClick(item.id)">mdi-account-edit</v-icon>
			</template> -->
		</v-data-table>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "student_class_audits",
		data() {
			return {
				//user: localStorage.getItem("user"),
				search: "",
				headers: [{
						text: "",
						align: "center",
						sortable: false,
						value: "action"
					},
					{
						text: "Mutatie",
						value: "tbl_sys_registration_type.name"
					},
					{
						text: "Schoolyear",
						value: "tbl_sys_schoolyear.name"
					},
					{
						text: "Studentnumber",
						value: "tbl_student.id"
					},
					{
						text: "Firstname",
						value: "tbl_student.firstname"
					},
					
					{
						text: "Lastname",
						value: "tbl_student.surname"
					},
					{
						text: "Class",
						value: "tbl_sys_class.name"
					},
					{
						text: "Mutation Date",
						value: "registration_date", 
						dataType: "Date"
					},
					{
						text: "User",
						value: "tbl_user.display_name"
					},
					//{ text: "E-mail OSBOD", value: "tbl_student.osbod_email" },
					// { text: "First Check", value: "firstcheckby"},
					// { text: "Second Check",	value: "secondcheckby"},
					// { text: "Active", value: "tbl_student_class_audit.student_class_audit_active" }

				],
				// semesters: [],
				// studies: [],
				// subjects: [],
				// subjecttypes: [],
				// selected_subject: null,
				// selected_kwalificatie: null,
				// selected_deelkwalificatie: null,
				// selected_semester: null,
				// schoolyear: [],
				// selected_schoolyear: null,
				data: [],
				// banktypes: [],
				isEmpty: false,
				loading: false,
				isPaginated: true,
				isPaginationSimple: false,
				defaultSortDirection: "asc",
				currentPage: 1,
				perPage: 15
			};
		},
		created: function () {
			// this.getBanktypes();
			this.getStudentClasss();
		},
		watch: {
			// selected_kwalificatie: function(value) {
			// 	this.getStudySubjecttype(value);
			// 	console.log(value);
			// }
		},
		computed: {
			// school() {
			// 	return this.$store.getters.getSchool;
			// }
		},
		methods: {
			handleClick(id) {
				this.$router.push({
					path: "/schooladmin/student_class_audit/update/" + id
				});
			},
			setStudentClass: function () {
				this.$router.push({
					path: "/schooladmin/student_class_audit/new"
				});
			},
			getStudentClasss: function () {
				var self = this;
				this.loading = true;
				ax.get("/student_class_audit/all").then(function (response) {
					// console.log(response.data);
					self.data = response.data;
					console.log("StudentClasss fetched...");
					console.log(response.data);
					self.loading = false;
				});
			},
			// getBanktypes: function() {
			// 	var self = this;
			// 	ax.get("/banktype/all").then(function(response) {
			// 		console.log(response.data);
			// 		self.banktypes = response.data;
			// 	});
			// }
		}
	};
</script>

<!-- Add "slot-scoped" attribute to limit CSS to this component only -->
<style slot-scoped>
</style>