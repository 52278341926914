import FinanceStudentView from "../views/schooladmin/FinanceStudentView";
import FinanceStudentViewAddEdit from "../views/schooladmin/FinanceStudentViewAddEdit";

export default [{
    path: "/schooladmin/financestudentview",
    components: {
      content: FinanceStudentView
    }
  },
  {
    path: "/schooladmin/financestudentview/new",
    components: {
      content: FinanceStudentViewAddEdit
    }
  },
  {
    path: "/schooladmin/financestudentview/update/:id",
    components: {
      content: FinanceStudentViewAddEdit
    },
    props: {
      content: true
    }
  }
];