<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit PARTTIME - PERSONEEL OSBOD Inschrijving</span>
				<span class="pl-2" v-else>Inschrijfformulier PARTTIME - PERSONEEL OSBOD</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-stepper v-model="e1">
			<v-stepper-header>
				<v-stepper-step :complete="e1 > 1" step="1">
					<p class="font-weight-black title">Persoonlijke gegevens</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 2" step="2">
					<p class="font-weight-black title">Werkzaam bij:</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 3" step="3">
					<p class="font-weight-black title">Betalingsgegevens</p>
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 4" step="4">
					<p class="font-weight-black title">Contact gegevens</p>
				</v-stepper-step>

			</v-stepper-header>
			<v-form lazy-validation>
				<v-stepper-items>
					<v-stepper-content step="1">
						<v-card-text>
							<v-row>
								<v-col cols="12" sm="6">
									<v-text-field dense v-model="form.maidenname" label="Meisjesnaam" filled>
									</v-text-field>
									<v-text-field dense v-model="form.surname" label="Achternaam*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.firstname" label="Voornamen (voluit)*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-select dense :items="genders" item-text="name" item-value="id"
										v-model="form.gender_id" filled label="Geslacht*" :rules="[rules.required]"
										:error-messages="errorMessages" required></v-select>
									<v-menu ref="menu_birthdate" v-model="menu_birthdate"
										:close-on-content-click="false" :return-value.sync="form.birthdate"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field v-model="form.birthdate" label="Geboortedatum*"
												prepend-inner-icon="mdi-calendar" readonly v-on="on" filled
												:rules="[rules.required]" :error-messages="errorMessages" required>
											</v-text-field>
										</template>
										<v-date-picker v-model="form.birthdate" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="menu_birthdate = false">Cancel</v-btn>
											<v-btn text color="primary"
												@click="$refs.menu_birthdate.save(form.birthdate)">
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
									<v-select dense :items="birthplaces" item-text="name" item-value="id"
										v-model="form.birthplace_id" filled label="Geboorteplaats*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-select dense :items="nationalitys" item-text="name" item-value="id"
										v-model="form.nationality_id" filled label="Nationaliteit*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>


								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field dense v-model="form.government_id" label="Geldige I.D. Nr.*" filled
										:counter=10 :rules="[rules.required, rules.governmentid, rules.number_only]"
										:error-messages="errorMessages" required>
									</v-text-field>
									<v-select dense :items="maritial_statuss" item-text="name" item-value="id"
										v-model="form.maritial_status_id" filled label="Burgerlijke staat*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-select dense :items="denominations" item-text="name" item-value="id"
										v-model="form.denomination_id" filled label="Geloofsovertuiging*"
										:rules="[rules.required]" :error-messages="errorMessages" required></v-select>
									<v-text-field dense v-model="form.address" label="Adres*" filled
										:rules="[rules.required]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-text-field dense v-model="form.email" label="Email*" filled
										:rules="[rules.required, rules.email]" :error-messages="errorMessages" required>
									</v-text-field>
									<v-row>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_mobile"
												prepend-inner-icon="mdi-cellphone" label="Mobiel*" filled :counter=7
												:rules="[rules.required, rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages" required>
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_home"
												prepend-inner-icon="mdi-phone-classic" label="Thuis" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages">
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="4">
											<v-text-field dense v-model="form.tel_work"
												prepend-inner-icon="mdi-deskphone" label="Werk" filled :counter=7
												:rules="[rules.number_only, rules.telephone_number]"
												:error-messages="errorMessages"></v-text-field>
										</v-col>
									</v-row>



								</v-col>
							</v-row>
						</v-card-text>


						<v-btn color="primary" @click="submitFormTransition()" depressed>
							Continue
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Cancel</v-btn>
					</v-stepper-content>
					<v-stepper-content step="2">
						<v-card>
							<v-col cols="12" sm="6">
								<v-radio-group v-model="form.employee_type_id" >
									<v-radio label="Bedrijf" value="Bedrijf"></v-radio>
									<v-radio label="School" value="School"></v-radio>
								</v-radio-group>
								<v-text-field dense v-model="form.employ_name" label="Naam Bedrijf / School" filled>
								</v-text-field>
								<v-text-field dense v-model="form.employ_address" label="Adres Bedrijf / School" filled>
								</v-text-field>
								<v-text-field dense v-model="form.employ_study" label="Bevoegdheid / Opleiding" filled>
								</v-text-field>
								<v-text-field dense v-model="form.employ_function" label="Functie" filled>
								</v-text-field>
								<v-text-field dense v-model="form.employ_telephone" label="Telefoon" filled>
								</v-text-field>
								<v-text-field dense v-model="form.employ_hours"
									label="Aantal lesuren p/wk (alleen voor school)" filled>
								</v-text-field>
							</v-col>
						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Back</v-btn>
						<v-btn color="primary" @click="e1=e1+1" depressed>
							Continue
						</v-btn>
						<v-btn color="error" @click="$router.go(-1);">Cancel</v-btn>
					</v-stepper-content>

					<v-stepper-content step="3">
						<v-card>
							<v-col cols="12" sm="6">
								<v-select dense :items="banktypes" item-text="name" item-value="id"
									v-model="form.bank_type_id" filled label="Bank"></v-select>
								<v-text-field dense v-model="form.acctbnk" label="Account number" filled></v-text-field>
							</v-col>
						</v-card>
						<v-btn color="secondary" @click="e1=e1-1">Back</v-btn>

						<v-btn color="primary" @click="submitFormTransition(id)" depressed>
							Continue
						</v-btn>

						<v-btn color="error" @click="$router.go(-1);">Cancel</v-btn>
					</v-stepper-content>
					<v-stepper-content step="4">
						<v-card>
							<v-col cols="12" sm="6">
								<p class="font-weight-black title">Contact persoon 1:</p>
								<v-text-field dense v-model="form.contact_name1" label="Naam*" filled>
								</v-text-field>
								<v-text-field dense v-model="form.contact_type1" label="Soort familielid*" filled>
								</v-text-field>
								<v-text-field dense v-model="form.contact_telephone1" label="Tel. nr. contactpersoon*"
									filled></v-text-field>
							</v-col>
							<v-col cols="12" sm="6">
								<p class="font-weight-black title">Contact persoon 2:</p>
								<v-text-field dense v-model="form.contact_name2" label="Naam" filled>
								</v-text-field>
								<v-text-field dense v-model="form.contact_type2" label="Soort familielid" filled>
								</v-text-field>
								<v-text-field dense v-model="form.contact_telephone2" label="Tel. nr. contactpersoon"
									filled></v-text-field>
							</v-col>
							<v-col cols="12" sm="12">
								<v-checkbox v-model="form.registration_complete"
									label="U verklaart alle inschrijvingsinformatie naar waarheid en volledig te hebben ingevuld*"
									required :rules="[rules.required]" :error-messages="errorMessages"></v-checkbox>
							</v-col>
						</v-card>
						<v-card-actions>
							<v-btn color="secondary" @click="e1=e1-1">Back</v-btn>
							<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
							<v-btn color="error" @click="$router.go(-1);" depressed>Cancel</v-btn>
						</v-card-actions>
					</v-stepper-content>
				</v-stepper-items>
			</v-form>
		</v-stepper>



		<v-card-text>

			<v-row>


			</v-row>

		</v-card-text>
		<!-- <v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions> -->

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			U dient alle verplichte velden in te vullen! Druk op de terug knop om de verplichte velden alsnog in te
			vullen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>

<script>
	import Axios from "axios";
	//import _ from "lodash";
	// import moment from "moment";
	import {
		API_ROOT
	} from "../../config";
	console.log(API_ROOT.osbod_salary);
	const ax = Axios.create({
		baseURL: API_ROOT["osbod_salary"],
		timeout: 60000
	});

	export default {
		name: "employeesignupadd",
		props: ["id"],
		data() {
			return {
				e1: 1,
				valid: true,
				errorMessages: '',
				formHasErrors: false,
				user: localStorage.getItem("user"),
				snackbar: false,
				schoolyears: [],
				studys: [],
				users: [],
				genders: [],
				birthplaces: [],
				nationalitys: [],
				maritial_statuss: [],
				denominations: [],
				selectedstudycost: [],
				banktypes: [],
				menu: false,
				menu_birthdate: false,
				rules: {
					required: value => !!value || 'Dit veld is verplicht!.',
					email: value => {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						return pattern.test(value) || 'Onvolledige e-mail.'
					},
					governmentid: value => (value && value.length == 10) ||
						'Identiteitsnummer dient 10 nummers te zijn.',
					telephone_number: value => (value && value.length == 7) ||
						'Telefoonnummer dient 7 nummers te zijn.',
					number_only: value => {
						const pattern = /^[0-9]+$/
						return pattern.test(value) || 'Alleen nummers is toegestaan'
					}
				},
				form: {
					id: 0,
					schoolyear_id: 11,
					firstname: "",
					surname: "",
					maidenname: "",
					government_id: "",
					birthdate: "",
					birthplace_id: null,
					gender_id: null,
					nationality_id: null,
					maritial_status_id: null,
					denomination_id: null,
					address: "",
					email: "",
					tel_home: "",
					tel_mobile: "",
					tel_work: "",
					registration_complete: "",
					display_name: "",
					bank_type_id: null,
					acctbnk:"",
					employ_type_id:"",
					employ_name:"",
					employ_telephone:"",
					employ_study:"",
					employ_function:"",
					employ_hours:"",
					contact_name1:"",
					contact_type1:"",
					contact_telephone1:"",
					contact_name2:"",
					contact_type2:"",
					contact_telephone2:""
				}
			};
		},
		created: function () {
			this.getUsers();
			this.getSchoolyears();
			this.getStudys();
			this.getGenders();
			this.getBirthplaces();
			this.getNationalitys();
			this.getMaritialstatus();
			this.getDenomination();
			this.getBanktypes();
			if (this.id > 0) {
				this.getUserRegistration(this.id);
			}
		},
		watch: {
			name() {
				this.errorMessages = '';
			}
			// "form.study_id": function (value) {
			// 	console.log("Get selected cost");
			// 	//console.log(this.cost_center_id_obj);
			// 	console.log(value);

			// 	this.getSelectedStudy(value);

			// },
		},
		methods: {
			validate() {
				this.$refs.form.validate()
			},
			Formatter: function (test) {
				console.log(test);

				return test;
			},
			getSchoolyears: function () {
				var self = this;

				ax.get("/schoolyear/all").then(function (response) {
					console.log(response.data);
					self.schoolyears = response.data;
				});
			},
			getBirthplaces: function () {
				var self = this;

				ax.get("/birthplace/all").then(function (response) {
					console.log(response.data);
					self.birthplaces = response.data;
				});
			},
			getNationalitys: function () {
				var self = this;

				ax.get("/nationality/all").then(function (response) {
					console.log(response.data);
					self.nationalitys = response.data;
				});
			},
			getMaritialstatus: function () {
				var self = this;

				ax.get("/maritial_status/all").then(function (response) {
					console.log(response.data);
					self.maritial_statuss = response.data;
				});
			},
			getDenomination: function () {
				var self = this;

				ax.get("/denomination/all").then(function (response) {
					console.log(response.data);
					self.denominations = response.data;
				});
			},
			getStudys: function () {
				var self = this;

				ax.get("/study/all").then(function (response) {
					console.log(response.data);
					self.studys = response.data;
				});
			},
			getSelectedStudy: function () {
				var self = this;
				ax.get("/study/single/" + this.form.study_id).then(function (response) {
					console.log("getStudy Costs")
					self.selectedstudycost = response.data;
					console.log(response.data);
				});
			},
			getBanktypes: function () {
				var self = this;

				ax.get("/banktype/all").then(function (response) {
					console.log(response.data);
					self.banktypes = response.data;
				});
			},
			getUsers: function () {
				var self = this;

				ax.get("/users/all").then(function (response) {
					console.log(response.data);
					self.users = response.data;
				});
			},
			getGenders: function () {
				var self = this;

				ax.get("/gender/all").then(function (response) {
					console.log(response.data);
					self.genders = response.data;
				});
			},
			getUserRegistration: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/employee/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					console.log("UserRegistrations fetched...");
					self.loading = false;
				});
			},
			submitForm() {
				var self = this;
				this.formHasErrors = false;
				if (this.form.registration_complete == null || this.form.registration_complete == "" || this.form
					.registration_agree == 0) {
					this.formHasErrors = true;
					this.form.registration_complete = null;
				}
				//this.$nextTick(function() {
				console.log(this.form);
				if (this.form.maritial_status_id.value == 1) { if(this.form.gender_id == 2){
					this.form.display_name =  this.form.surname + " e/v " +  this.form.maidenname +  ", "+  this.form.firstname ;}
					else {
						this.form.display_name = this.form.surname + ", " + this.form.firstname;
					}
				} else {
					this.form.display_name = this.form.surname + ", " + this.form.firstname;
				}
				if (this.formHasErrors == false) {
					if (this.form.id > 0) {
						ax.post("/employee/update/" + this.form.id, this.form).then(function (
							response
						) {
							console.log(response.data);

							console.log("UserRegistrations update...");

							self.$router.push({
								path: "/schooladmin/studentregistration"
							});
						});
					} else {
						console.log("before post");
						ax.post("/employee/create", this.form).then(function (response) {
							console.log("almost there");
							console.log(response.data);
							self.$router.push({
								path: "/bedanktdocent"
							});
							console.log("UserRegistrations created...");
						});


					}
				} else {
					this.form
				}
				//});
			},
			submitFormTransition() {
				var self = this;
				this.formHasErrors = false;

				if (this.form.surname == null || this.form.surname == "") {
					this.formHasErrors = true;
					this.form.surname = null;
				}
				if (this.form.firstname == null || this.form.firstname == "") {
					this.formHasErrors = true;
					this.form.firstname = null;
				}
				if (this.form.birthdate == null || this.form.birthdate == "") {
					this.formHasErrors = true;
					this.form.birthdate = null;
				}
				if (this.form.birthplace_id == null || this.form.birthplace_id == "") {
					this.formHasErrors = true;
					this.form.birthplace_id = "";
				}
				if (this.form.government_id == null || this.form.government_id == "") {
					this.formHasErrors = true;
					this.form.government_id = null;
				}
				if (this.form.tel_mobile == null || this.form.tel_mobile == "") {
					this.formHasErrors = true;
					this.form.tel_mobile = null;
				}
				if (this.form.email == null || this.form.email == "") {
					this.formHasErrors = true;
					this.form.email = null;
				}
				if (this.form.address == null || this.form.address == "") {
					this.formHasErrors = true;
					this.form.address = null;
				}
				if (this.form.gender_id == null || this.form.gender_id == "") {
					this.formHasErrors = true;
					this.form.gender_id = "";
				}
				if (this.form.denomination_id == null || this.form.denomination_id == "") {
					this.formHasErrors = true;
					this.form.denomination_id = "";
				}
				if (this.form.nationality_id == null || this.form.nationality_id == "") {
					this.formHasErrors = true;
					this.form.nationality_id = "";
				}
				if (this.form.maritial_status_id == null || this.form.maritial_status_id == "") {
					this.formHasErrors = true;
					this.form.maritial_status_id = "";
				}
				//geboortedatum, geboorteplaats, id nummer, mobiel, email


				if (this.formHasErrors == false) {
					self.e1 = self.e1 + 1;
				}
			}
		}
	};
</script>